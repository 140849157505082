import { takeUntil } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { coerceBoolean, ToCoerce } from '../../../decorators/coerce-property/coercions';
import { TimepickerService } from './timepicker.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'wchfs-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
    TimepickerService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() placeholder = 'HH:MM';
  @Input() customErrors: { [key: string]: string };

  @Input() @coerceBoolean disabled = false;
  @Input() @coerceBoolean required: ToCoerce = false;

  @Input()
  @coerceBoolean
  set readonly(value: boolean) {
    value = coerceBooleanProperty(value);
    this.disabled = value;
    this._readonly = value;
    this.cdr.markForCheck();
  }

  get readonly(): boolean {
    return this._readonly;
  }

  @ViewChild('inputReference', { static: true, read: NgControl })
  input: NgControl;

  @HostBinding('attr.is-required')
  public get isAttrRequired() {
    return this.detectRequiredField;
  }

  set inputValue(value: string) {
    this.timePickerService.upadteTimeObjectFromString(value);

    this._inputValue = value;

    this.cdr.markForCheck();
  }

  get inputValue(): string {
    return this._inputValue;
  }

  unsubscribe$: Subject<void> = new Subject();
  detectRequiredField = false;
  private _inputValue = '';
  private _readonly = false;

  constructor(
    private timePickerService: TimepickerService,
    private cdr: ChangeDetectorRef,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.timePickerService.parsedTime$.pipe(takeUntil(this.unsubscribe$.asObservable())).subscribe((time) => {
      this.writeValue(time);
    });
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    setTimeout(() => {
      if (!!ngControl && !!ngControl?.control) {
        this.input.control.setValidators(ngControl.control.validator);
        this.detectRequiredField = hasRequiredControl(ngControl.control);
      }
    });

    this.timePickerService.backdropClick$
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  openModal(element: HTMLElement) {
    this.timePickerService.openModal(element);
  }

  inputValueChange(value: string) {
    this.writeValue(value);
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onChange = (value: string) => {};

  onTouch: () => void = () => {};

  writeValue(value: string): void {
    this.inputValue = value;
    this.onChange(value);
    this.cdr.markForCheck();
  }
}

const hasRequiredControl = (control: AbstractControl): boolean => {
  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    return validator && validator.required && true;
  }
  return false;
};
