import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { EditReservationDateModalService } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-modal.service';
import { SignalBoxComponent } from '@pso-ui/signal-box/signal-box.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { CalendarDateTimePickerModule } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.module';
import { ModalModule } from '@pso-ui/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { PsoButtonModule } from '@pso-ui/button-pso/pso-button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-reservation-date-step-two',
  templateUrl: './edit-reservation-date-step-two.component.html',
  styleUrls: ['./edit-reservation-date-step-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SignalBoxComponent,
    NgIf,
    CalendarDateTimePickerModule,
    ModalModule,
    TranslateModule,
    PsoButtonModule,
    ReactiveFormsModule,
    UpperCasePipe,
  ],
})
export class EditReservationDateStepTwoComponent implements OnInit, OnDestroy {
  @Output() confirmButtonClicked = new EventEmitter<void>();
  @Output() closeButtonClicked = new EventEmitter<void>();
  private subscription = new Subscription();

  constructor(
    public readonly editReservationDateModalService: EditReservationDateModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.listenMarkForCheck());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private listenMarkForCheck(): Subscription {
    return this.editReservationDateModalService.markForCheck$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
