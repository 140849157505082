import {
  CompletionTypeSlug,
  TyreAdditionalStateValue,
  TyreLogInDeposit,
  TyreLogOnCar,
  TyreLogResource,
  TyreLogWithMeta,
  TyrePositionSlug,
  TyreStateResource,
} from '@data/tyres/tyres.model';
import { ServiceResource } from '@data/services/services.interface';
import {
  CarAndDriver,
  CarAndDriverData,
  CarAndWheels,
  CarAndWheelsData,
  RimTypeValue,
  Service,
  ServiceMeta,
  TyreStateValue,
} from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import {
  ServiceMatchAction,
  ServiceMatchActionData,
} from '@data/disposition-add-edit/service-matches/service-matches.model';
import { StatusResource } from '@data/status/status.interfaces';
import { DispositionResource } from '@data/dispositions/dispositions.model';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { FleetResource } from '@data/fleets/fleets.model';

export interface DispositionAddEditStateModel {
  steps: string[];
  vehicle: VehicleResource;
  tyres_on_car: TyreLogOnCar[];
  tyres_in_deposit: TyreLogInDeposit[];
  minimum_thread_depth: {
    summer: number;
    winter: number;
    yearly: number;
  };
  services: Service[];
  processing: boolean;
  pristine: boolean;
  remarks: string;
  // driver: {
  //   name: string;
  //   phone_number: string;
  // };
  // mileage: number;
  // rim: string;
  // spare: string;
  // tpms: string;
  car_and_driver: CarAndDriver;
  car_and_wheels: CarAndWheels;
  _snapshot: DispositionAddEditStateModel;
  states: TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[];
  workshopId: number;
  logKey: string;
  fleet: FleetResource;
  disposition: DispositionResource;
}

export class SetVehicle {
  static readonly type = '[Disposition Add Edit] Set Vehicle';

  constructor(public vehicle: VehicleResource) {}
}

// export class SetTyresOnCar {
//   static readonly type = '[Disposition Add Edit] Set Tyres On Car';
//
//   constructor(public tyres: TyreResource[]) {}
// }

export class SetTyreOnCar {
  static readonly type = '[Disposition Add Edit] Set Tyre On Car';

  constructor(public tyre: TyreLogResource, public position: TyrePositionSlug, public touched: boolean) {}
}

export class SetTyreTread {
  static readonly type = '[Disposition Add Edit] Set Tyre Tread';

  constructor(public tyre: TyreLogOnCar, public depth: number) {}
}

export class SetTyreHighlight {
  static readonly type = '[Disposition Add Edit] Set Tyre Highlight';

  constructor(public tyre: TyreLogOnCar) {}
}

export class ResetTyreHighlight {
  static readonly type = '[Disposition Add Edit] Reset Tyre Highlight';

  constructor(public tyre: TyreLogOnCar) {}
}

// export class SetTyresInDeposit {
//   static readonly type = '[Disposition Add Edit] Set Tyres On Deposit';
//
//   constructor(public tyres: TyresInDeposit) {}
// }

export class SetTyreInDeposit {
  static readonly type = '[Disposition Add Edit] Set Tyre On Deposit';

  constructor(public tyre: TyreLogResource, public position: TyrePositionSlug, public touched: boolean) {}
}

export class SetMinimumTreadDepth {
  static readonly type = '[Disposition Add Edit] Set Minimum Tread Depth';

  constructor(public season: string, public depth: number) {}
}

export class SetSteps {
  static readonly type = '[Disposition Add Edit] Set Steps';

  constructor(public steps: string[]) {}
}

export class AddService {
  static readonly type = '[Disposition Add Edit] Add Service';

  constructor(public service: ServiceResource, public partialMeta?: Partial<ServiceMeta>) {}
}

export class AddServices {
  static readonly type = '[Disposition Add Edit] Add Services';

  constructor(public services: Service[]) {}
}

export class RemoveService {
  static readonly type = '[Disposition Add Edit] Remove Service';

  constructor(public service: ServiceResource, public count?: number) {}
}

export class RemoveAllServices {
  static readonly type = '[Disposition Add Edit] Remove All Services';
}

export class SetServiceExecutionDate {
  static readonly type = '[Disposition Add Edit] Set Service Execution Date';

  constructor(public service: ServiceResource, public value: string, public deferred: boolean) {}
}

export class SetServiceWorkshopPrice {
  static readonly type = '[Disposition Add Edit] Set Service Workshop Price';

  constructor(public service: ServiceResource, public price: number) {}
}

export class SetServiceFleetPrice {
  static readonly type = '[Disposition Add Edit] Set Service Fleet Price';

  constructor(public service: ServiceResource, public price: number) {}
}

export class AddToDeposit {
  static readonly type = '[Disposition Add Edit] Add To Deposit';

  constructor(public tyre: TyreLogOnCar) {}
}

export class AddToCar {
  static readonly type = '[Disposition Add Edit] Add To Car';

  constructor(public tyre: TyreLogInDeposit) {}
}

export class RemoveFromCar {
  static readonly type = '[Disposition Add Edit] Remove From Car';

  constructor(public tyre: TyreLogOnCar) {}
}

export class RemoveFromDeposit {
  static readonly type = '[Disposition Add Edit] Remove From Deposit';

  constructor(public tyre: TyreLogInDeposit) {}
}

export class AddStatus {
  static readonly type = '[Disposition Add Edit] Add Status';

  constructor(public tyre: TyreLogOnCar | TyreLogInDeposit, public status: TyreStateValue) {}
}

export class AddAdditionalStatus {
  static readonly type = '[Disposition Add Edit] Add Additional Status';

  constructor(
    public tyre: TyreLogOnCar | TyreLogInDeposit,
    public status: TyreStateResource<TyreAdditionalStateValue>
  ) {}
}

export class ClearDispositionAdditionalStatuses {
  static readonly type = '[Disposition Add Edit] Clear Disposition Additional Statuses';

  constructor(public tyre: TyreLogOnCar | TyreLogInDeposit) {}
}

export class ClearDepositAdditionalStatuses {
  static readonly type = '[Disposition Add Edit] Clear Deposit Additional Statuses';

  constructor(public tyre: TyreLogOnCar | TyreLogInDeposit) {}
}

export class StartProcessing {
  static readonly type = '[Disposition Add Edit] Start Processing';
}

export class StopProcessing {
  static readonly type = '[Disposition Add Edit] Stop Processing';
}

export class SetEditingTire {
  static readonly type = '[Disposition Add Edit] Set Editing Tyre';

  constructor(public tyre: TyreLogOnCar) {}
}

export class SetPristine {
  static readonly type = '[Disposition Add Edit] Set Pristine';

  constructor(public pristine: boolean) {}
}

export class ResetRequestAddEditState {
  static readonly type = '[Disposition Add Edit] Reset';
}

export class SetMileage {
  static readonly type = '[Disposition Add Edit] Set Mileage';

  constructor(public mileage: number) {}
}

export class SetRim {
  static readonly type = '[Disposition Add Edit] Set Rim';

  constructor(public rim: string) {}
}

export class SetSpare {
  static readonly type = '[Disposition Add Edit] Set Spare';

  constructor(public spare: string) {}
}

export class SetTpms {
  static readonly type = '[Disposition Add Edit] Set TPMS';

  constructor(public tpms: string) {}
}

export class SetCarAndWheelsValues {
  static readonly type = '[Disposition Add Edit] Set Car And Wheels Values';

  constructor(public values: CarAndWheelsData, public valid: boolean) {}
}

export class ResetCarAndDriverValues {
  static readonly type = '[Disposition Add Edit] Reset Car And Driver Values';
}

export class SetCarAndDriverValues {
  static readonly type = '[Disposition Add Edit] Set Car And Driver Values';

  constructor(public values: CarAndDriverData, public valid: boolean) {}
}

export class HighlightLastTransferredTyresInDeposit {
  static readonly type = '[Disposition Add Edit] Highlight Last Transferred Tyres In Deposit';

  constructor(public tyres: TyreLogOnCar[]) {}
}

export class HighlightLastTransferredTyresOnCar {
  static readonly type = '[Disposition Add Edit] Highlight Last Transferred Tyres On Car';

  constructor(public tyres: TyreLogInDeposit[]) {}
}

export class ClearHighlightedLastTransferredTyresInDeposit {
  static readonly type = '[Disposition Add Edit] Clear Highlighted Last Transferred Tyres In Deposit';
}

export class SaveStateSnapshot {
  static readonly type = '[Disposition Add Edit] Save State Snapshot';
}

export class Undo {
  static readonly type = '[Disposition Add Edit] Undo';
}

export class SetDepositCode {
  static readonly type = '[Disposition Add Edit] Set Deposit Code';

  constructor(public tyre: TyreLogInDeposit, public code: string) {}
}

export class AddRequestRemarks {
  static readonly type = '[Disposition Add Edit] Add Request Remarks';

  constructor(public remarks: string) {}
}

export class SetupTyreStates {
  static readonly type = '[Disposition Add Edit] Setup Tyre States';
}

export class ChangeTirePosition {
  static readonly type = '[Disposition Add Edit] Change Tyre Position';

  constructor(public tire: TyreLogWithMeta, public position: TyrePositionSlug, public touched: boolean) {}
}

export class SetServiceAuthorizationStatus {
  static readonly type = '[Disposition Add Edit] Set Service Authorization Status';

  constructor(public serviceId: string, public authorizationStatus: StatusResource) {}
}

export class SetServicesAuthorizationStatus {
  static readonly type = '[Disposition Add Edit] Set Services Authorization Status';

  constructor(public authorizationStatus: StatusResource) {}
}

export class SetWorkshopId {
  static readonly type = '[Disposition Add Edit] Set Workshop Id';

  constructor(public workshopId: number) {}
}

export class SetFleet {
  static readonly type = '[Disposition Add Edit] Set Fleet';

  constructor(public fleet: FleetResource) {}
}

export class SetDisposition {
  static readonly type = '[Disposition Add Edit] Set Disposition';

  constructor(public disposition: DispositionResource) {}
}

export class AddDescriptionToTyre {
  static readonly type = '[Disposition Add Edit] Add Description To Tyre';

  constructor(public tyre: TyreLogOnCar, public description: string) {}
}

export class SetRimTypeToTyre {
  static readonly type = '[Disposition Add Edit] Set Rim Type To Tyre';

  constructor(public tyre: TyreLogOnCar, public rimType: RimTypeValue) {}
}

export class SetCompletionType {
  static readonly type = '[Disposition Add Edit] Set Completion Type';

  constructor(public tyre: TyreLogOnCar, public completionType: CompletionTypeSlug) {}
}

export class SetRimType {
  static readonly type = '[Disposition Add Edit] Set Rim Type';

  constructor(public rimType: RimTypeValue) {}
}

export class SetRimToRemove {
  static readonly type = '[Disposition Add Edit] Set Rim To Remove';

  constructor(public rim: TyreLogInDeposit, public originTyre: TyreLogInDeposit) {}
}
