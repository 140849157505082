import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Output()
  readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() @coerceBoolean disabled = false;
  @Input() @coerceBoolean checked: boolean;

  constructor() {}

  @Input()
  public set value(checked: boolean) {
    this.checked = checked;
    this.onChange(checked);
  }

  private _indeterminate: boolean;

  get indeterminate() {
    return this._indeterminate;
  }

  @Input() set indeterminate(value: any) {
    value = coerceBooleanProperty(value);
    this._indeterminate = value;
    if (value) {
      this.checked = false;
    }
  }

  @HostListener('click', ['$event'])
  public toggleChecked(): void {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.indeterminate = false;
      this.onChange(this.checked);
      this.change.emit(this.checked);
    }
  }

  ngOnInit(): void {}

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  public onChange: any = () => {};

  public onTouch: any = () => {};
}
