import { DatePipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { CalendarDateFormat } from '../../../datepicker/calendar.constants';
import { isValidDate } from '../../../datepicker/date-utils';
import {
  DATE_PLACEHOLDER_CHAR_WIDTH,
  DATE_PLACEHOLDER_INITIAL_MARGIN_LEFT,
} from '../../../form/form-field/form-field.constants';

export const DATA_INPUT_ALLOWED_CHARS = ['0', '1', '3', '2', '4', '5', '6', '7', '8', '9', '/'];

@Directive({
  selector: '[wchfsFilterDate]',
  providers: [DatePipe],
})
export class FilterDateDirective implements OnInit {
  private placeholderWrapper: HTMLElement;
  private initialFormat: string;
  private isInitialDateFormating = true;

  @Input() dateFormat: string;
  @Input() isPlaceholder = true;
  @ViewChild('infix') input: any;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private datePipe: DatePipe
  ) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = event as KeyboardEvent;

    if (
      ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home'].indexOf(
        e.key
      ) !== -1 ||
      (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
      (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
      (e.key === 'v' && (e.ctrlKey || e.metaKey)) ||
      (e.key === 'x' && (e.ctrlKey || e.metaKey))
    ) {
      return;
    }
    if (
      e.shiftKey ||
      DATA_INPUT_ALLOWED_CHARS.indexOf(e.key) === -1 ||
      (this.ngControl.value &&
        DATA_INPUT_ALLOWED_CHARS.indexOf(e.key) !== -1 &&
        this.ngControl.value.length === this.dateFormat.length)
    ) {
      e.preventDefault();
    }
  }

  ngOnInit() {
    this.listenOnControlValueChanges();
    this.initPlaceholder();
  }

  private listenOnControlValueChanges(): void {
    this.ngControl.valueChanges.subscribe((controlValue) => {
      const displayingValue = this.getDateInCorrectFormat(this.initialFormat, controlValue || '');
      this.refreshPlaceholder(displayingValue);
      this.ngControl.control.patchValue(displayingValue, { emitEvent: false });
    });
  }

  private refreshPlaceholder(displayingValue: string): void {
    const placeholderValue = this.initialFormat.substring(displayingValue.length, this.initialFormat.length);
    if (this.isPlaceholder) {
      this.renderer.setProperty(this.placeholderWrapper, 'textContent', placeholderValue);
    }
    const marginLeft = displayingValue.length * DATE_PLACEHOLDER_CHAR_WIDTH + DATE_PLACEHOLDER_INITIAL_MARGIN_LEFT;
    this.renderer.setStyle(this.placeholderWrapper, 'margin-left', `${marginLeft}px`);
  }

  private initPlaceholder(): void {
    this.initialFormat = this.dateFormat.toUpperCase();
    this.placeholderWrapper = this.renderer.createElement('span');
    this.renderer.addClass(this.placeholderWrapper, 'placeholder-date');
    if (this.isPlaceholder) {
      this.renderer.setProperty(this.placeholderWrapper, 'textContent', this.initialFormat);
    }
    this.renderer.appendChild(this.elementRef.nativeElement.parentElement, this.placeholderWrapper);

    if (this.ngControl.control.value) {
      this.ngControl.control.updateValueAndValidity();
      this.isInitialDateFormating = false;
    }
  }

  private getDateInCorrectFormat(format: string, controlValue: string): string {
    if (!this.isInitialDateFormating && this.dayOrMonthFirstFormat(format, controlValue)) {
      return this.dayOrMonthFirstFormat(format, controlValue);
    } else {
      return this.defaulDatePlaceholderFotmatter(format, controlValue);
    }
  }

  private dayOrMonthFirstFormat(format: string, controlValue: string): string {
    //Case when format is 'DD/MM/YYYY'
    //Enable to remove digit in center of string -> turn of formating with "/" and hide placeholder
    if (
      this.dateFormat === CalendarDateFormat.ddMMyyyy &&
      controlValue.match(/\d\d\//gm)?.length < 2 &&
      controlValue.length > 7
    ) {
      this.isPlaceholder = false;
      return controlValue;
    }
    return null;
  }
  private defaulDatePlaceholderFotmatter(format: string, controlValue: string): string {
    this.isPlaceholder = true;
    if (isValidDate(controlValue)) {
      controlValue = this.datePipe.transform(controlValue, this.dateFormat);
    }
    const valueWithoutSlash = controlValue.replace(/\//g, '');
    return valueWithoutSlash.split('').reduce((acc: string, current: string, index: number) => {
      const slashesInAcc = (acc.match(new RegExp('/', 'g')) || []).length;
      if (format[index + slashesInAcc] === '/') {
        acc += '/';
      }
      acc += current;
      return acc;
    }, '');
  }
}
