import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig, ModalType, ModalWithAction } from '@pso-ui/modal';
import { ReservationsNotTakenModalAction } from '@pages-and-modals/reservations-not-taken-modal/reservations-not-taken-modal.model';
import { ReservationResource, ReservationStatus } from '@data/reservations/reservations.model';
import { Id } from '@core/http/crud-model';
import { merge, Observable, Subject } from 'rxjs';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { pluck, switchMap } from 'rxjs/operators';
import { ReservationsService } from '@data/reservations/reservations.service';
import { NotificationService } from '@shared/services/notification.service';
import { GenericTableColumn } from '@pso-ui/generic-table/generic-table.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reservations-not-taken-modal',
  templateUrl: './reservations-not-taken-modal.component.html',
  styleUrls: ['./reservations-not-taken-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationsNotTakenModalComponent
  implements
    ModalWithAction<{
      action: ReservationsNotTakenModalAction;
      data: Id;
    }>,
    OnInit {
  @ViewChild('columnCarTemplate', { static: true }) columnCarTemplate: TemplateRef<any>;
  @ViewChild('columnFleetTemplate', { static: true }) columnFleetTemplate: TemplateRef<any>;
  @ViewChild('columnPhoneNumberTemplate', { static: true }) columnPhoneNumberTemplate: TemplateRef<any>;
  @ViewChild('columnTypeTemplate', { static: true }) columnTypeTemplate: TemplateRef<any>;
  @ViewChild('columnStartDateTemplate', { static: true }) columnStartDateTemplate: TemplateRef<any>;
  @ViewChild('columnActionTemplate', { static: true }) columnActionTemplate: TemplateRef<any>;
  protected readonly ReservationsNotTakenModalAction = ReservationsNotTakenModalAction;
  type = ModalType.INFO;
  reservations$: Observable<ReservationResource[]>;
  refresh$ = new Subject<void>();
  columns: GenericTableColumn[];
  static CONFIG: ModalConfig = {
    minWidth: 807,
    maxWidth: 807,
    minHeight: 531,
    maxHeight: 531,
    disableClose: true,
  };

  constructor(
    private dialog: MatDialogRef<ReservationsNotTakenModalComponent>,
    private reservationsService: ReservationsService,
    private notificationService: NotificationService
  ) {}

  confirm(): void {
    throw new Error('Method not implemented.');
  }

  close(action: { action: ReservationsNotTakenModalAction; data: unknown }): void {
    this.dialog.close(action);
  }

  editButtonClicked(reservation: ReservationResource): void {
    this.dialog.close({
      action: ReservationsNotTakenModalAction.EDIT,
      data: reservation.id,
    });
  }

  acceptButtonClicked(reservation: ReservationResource): void {
    this.reservationsService
      .update(reservation.id, {
        status: ReservationStatus.APPROVED,
      })
      .subscribe(() => {
        this.notificationService.notifySuccess('Zmieniono status');
        this.refresh$.next();
      });
  }

  ngOnInit(): void {
    this.reservations$ = merge(
      this.getReservations(),
      this.refresh$.asObservable().pipe(switchMap(() => this.getReservations()))
    );
    this.columns = [
      new GenericTableColumn('vehicle').setTemplate(this.columnCarTemplate),
      new GenericTableColumn('fleet').setTemplate(this.columnFleetTemplate).setWidth('200px'),
      new GenericTableColumn('phone_number').setTemplate(this.columnPhoneNumberTemplate),
      new GenericTableColumn('reservation_type').setTemplate(this.columnTypeTemplate),
      new GenericTableColumn('start_date').setHeader('Termin').setTemplate(this.columnStartDateTemplate),
      new GenericTableColumn('action').setHeader(' ').setTemplate(this.columnActionTemplate),
    ];
  }

  private getReservations(): Observable<ReservationResource[]> {
    return this.reservationsService
      .get({
        paginate: (0 as unknown) as LaravelBoolean,
        per_page: 1,
        page: 1,
        tab: 'not_taken',
        sort: 'created_at.asc',
      })
      .pipe(pluck('data'));
  }
}
