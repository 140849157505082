import { Directive, ElementRef, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[wchfsTimeMask]',
})
export class TimeMaskDirective implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject();
  constructor(@Optional() @Self() public ngControl: NgControl, private elementRef: ElementRef) {}

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'keyup')
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe((event: Event) => {
        event.preventDefault();
        let val = this.ngControl.value;
        let lastLength;
        do {
          lastLength = val.length;
          val = this.replaceBadChars(val);
        } while (val.length > 0 && lastLength !== val.length);
        this.ngControl.control.setValue(val);
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private replaceBadChars(val: string) {
    val = val.replace(/[^\dh:]/, '');
    val = val.replace(/^[^0-2]/, '');
    val = val.replace(/^([2-9])[4-9]/, '$1');
    val = val.replace(/^\d[:h]/, '');
    val = val.replace(/^([01][0-9])[^:h]/, '$1');
    val = val.replace(/^(2[0-3])[^:h]/, '$1');
    val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1');
    val = val.replace(/^(\d{2}h)./, '$1');
    val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
    val = val.replace(/^(\d{2}:\d[0-9])./, '$1');
    return val;
  }
}
