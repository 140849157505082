import { Id } from '@app/@core/http/crud-model';
import { MetaResource } from '@shared/interfaces/paged-resource';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export interface RelocationsStateModel {
  activeTab: ListTabWithColumns;
  visibleColumns: string[];
  loading: boolean;
  tab_slug: string;
}

export class SetActiveRelocation {
  static readonly type = '[Relocations] Set Active Relocation';

  constructor(public id: Id) {}
}

export class UnsetActiveRelocation {
  static readonly type = '[Relocations] Unset Active Relocation';
}

export class SetVisibleColumns {
  static readonly type = '[Relocations] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[Relocations] Set Active Tab';

  constructor(public tab: ListTabWithColumns) {}
}

export class SetLoading {
  static readonly type = '[Relocations] Set Loading';

  constructor(public loading: boolean) {}
}

export class SetMeta {
  static readonly type = '[Relocations] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class SetTabSlug {
  static readonly type = '[Relocations] Set Tab Slug';

  constructor(public slug: string) {}
}
