<div
  [class]="{
    'wchfs-select-selected': selected,
    'wchfs-select-empty': !!selected,
    'wchfs-select-multi': multiselect,
    'wchfs-select-single': !multiselect
  }"
  class="wchfs-select-wrapper"
>
  <div #dropreference [class.required]="required" class="input">
    <input
      #input
      (blur)="onTouched()"
      (click)="showDropdown()"
      (keyup)="inputKeyUpHandler($event)"
      [disabled]="disabled"
      [readonly]="!typeahead"
      [value]="displayText"
      autocomplete="off"
      class="wchfs-select"
      placeholder="{{ placeholder }}"
    />

    <wchfs-dropdown [maxHeight]="dropdownMaxHeight" [reference]="dropreference">
      <div class="wchfs-select-dropdown-content">
        <div [class]="{ 'with-hint': hint, multiselect: multiselect }" class="wchfs-select-dropdown">
          <div *ngIf="hint" class="hint-wrapper">
            <input
              #hintInput
              (keyup)="hintInputKeyUpHandler($event)"
              autocomplete="off"
              class="wchfs-hint"
              [placeholder]="hintPlaceholder"
              wchfsInput
            />
          </div>

          <div
            *ngIf="
              withChips &&
              ((chipListService.groups$ | async).length > 0 || (chipListService.options$ | async).length > 0)
            "
            class="chip-list-wrapper"
          >
            <wchfs-chip-list>
              <wchfs-chip
                (remove)="removeChip($event, group)"
                *ngFor="let group of chipListService.groups$ | async"
                [level]="2"
                removable="true"
                >{{ group.header.getLabel() }}</wchfs-chip
              >

              <wchfs-chip
                (remove)="removeChip($event, option)"
                *ngFor="let option of chipListService.options$ | async"
                [key]="option.key"
                [level]="1"
                removable="true"
                >{{ option.getLabel() }}</wchfs-chip
              >
            </wchfs-chip-list>
          </div>

          <div class="options-wrapper">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </wchfs-dropdown>
  </div>

  <div
    *ngIf="!!selected && multiselect && selectionModel.getUniqueSelected().length > 1"
    class="wchfs-select-badge-wrapper"
    [ngClass]="disabled ? 'wchfs-select-badge-wrapper--disabled' : null"
  >
    <wchfs-badge color="grey-5" textColor="grey-2" type="standalone"
      >+{{ selectionModel.getUniqueSelected().length - 1 }}</wchfs-badge
    >
  </div>

  <div
    (click)="clearSelected()"
    *ngIf="!!selectedOption && clearOption"
    class="wchfs-select-x-wrapper"
    [ngClass]="disabled ? 'wchfs-select-x-wrapper--disabled' : null"
  >
    <wchfs-icon svgIcon="wchfs:chip-cross"></wchfs-icon>
  </div>

  <div class="wchfs-select-arrow-wrapper" [ngClass]="disabled ? 'wchfs-select-arrow-wrapper--disabled' : null">
    <wchfs-icon svgIcon="wchfs:arrow-down"></wchfs-icon>
  </div>
</div>
