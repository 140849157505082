import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UiStorageService {
  constructor() {}

  getTableColumnCasheList(tableName: string): string[] | null {
    const list = JSON.parse(localStorage.getItem(tableName));
    return list;
  }

  setTableColumnCasheList(tableName: string, columns: string[]): void {
    const columnsData = JSON.stringify(columns);
    localStorage.setItem(tableName, columnsData);
  }
}
