<div
  [ngClass]="{
    'tooltip-container': true,
    'tooltip-container--white': style === tooltipStyle.WHITE,
    'tooltip-container--blue': style === tooltipStyle.BLUE
  }"
>
  <wchfs-icon *ngIf="icon" [fill]="style === tooltipStyle.WHITE ? '#fff' : '#000'" svgIcon="wchfs:info"> </wchfs-icon>
  <p *ngIf="text" class="tooltip-text">{{ text }}</p>
  <ng-container *ngIf="htmlContent?.length > 0; else template">
    <span [innerHTML]="htmlContent"></span>
  </ng-container>
</div>

<ng-template #template>
  <ng-container *ngTemplateOutlet="htmlContent"></ng-container>
</ng-template>
