import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingHandlerService {
  private loadingSub$ = new ReplaySubject<boolean>(1);

  loading$ = this.loadingSub$.pipe(distinctUntilChanged());

  set loading(val: boolean) {
    this.loadingSub$.next(val);
  }
}
