import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { PsoColor } from '@app/@wchfs-ui/lib/colors/colors.model';

@Component({
  selector: 'app-dot',
  templateUrl: './dot.component.html',
  styleUrls: ['./dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotComponent {
  @Input() color: PsoColor;
  @HostBinding('style.backgroundColor')
  get bgColor(): string {
    return this.color ? `var(--${this.color})` : `var(--primary-b-blue)`;
  }
}
