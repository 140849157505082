import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetSidebarState, ToggleSidebarState } from './sidebar.actions';
import { SidebarStateModel } from './sidebar.interface';

@State<SidebarStateModel>({
  name: 'sidebar',
  defaults: {
    sidebar_hidden: false, // INFO: sidebar state 3 (hidden)
    sidebar_state: true, // INFO: open/close
  },
})
@Injectable()
export class SidebarState {
  @Selector()
  static isSidebarOpened(state: SidebarStateModel): boolean {
    return state.sidebar_state;
  }

  @Selector()
  static isSidebarHidden(state: SidebarStateModel): boolean {
    return state.sidebar_hidden;
  }

  @Action(SetSidebarState)
  setSidebarState(ctx: StateContext<SidebarStateModel>, action: SetSidebarState): void {
    ctx.patchState({
      sidebar_hidden: action.state.sidebar_hidden,
      sidebar_state: action.state.sidebar_state,
    });
  }

  @Action(ToggleSidebarState)
  toggleSidebarState(ctx: StateContext<SidebarStateModel>): void {
    const toggle = ctx.getState().sidebar_state;
    ctx.patchState({ sidebar_state: !toggle });
  }
}
