import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DispositionItem } from '@data/disposition-item/disposition-item.model';
import { DispositionAuth } from '@data/disposition-auth/disposition-auth.model';
import { DispositionDetailsStateModel, DispositionItemPatchData } from '@data/dispositions/disposition-details.model';
import {
  DispositionDetailsGetDisposition,
  DispositionDetailsReset,
  DispositionDetailsSetDisposition,
  DispositionDetailsSetDispositionItemPatchData,
  DispositionDetailsSetEditMode,
  DispositionDetailsSetLoading,
} from '@data/dispositions/disposition-details.actions';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { DispositionResource } from '@data/dispositions/dispositions.model';
import { DispositionsService } from '@data/dispositions/dispositions.service';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import { tap } from 'rxjs/operators';

const DEFAULTS: DispositionDetailsStateModel = {
  disposition: null,
  patchData: null,
  editMode: false,
  loading: false,
};

@State<DispositionDetailsStateModel>({
  name: 'dispositionDetails',
  defaults: DEFAULTS,
})
@Injectable()
export class DispositionDetailsState {
  @Selector()
  static disposition(state: DispositionDetailsStateModel): DispositionResource {
    return state.disposition;
  }

  @Selector()
  static itemsWithoutOrders(state: DispositionDetailsStateModel): DispositionItem[] {
    return state.disposition.disposition_items
      .filter((i) => !i.service.is_sale)
      .filter((i) => !i.service.is_product)
      .map((i) => new DispositionItem(i));
  }

  @Selector()
  static itemsWithoutOrdersQuantity(state: DispositionDetailsStateModel): number {
    return this.itemsWithoutOrders(state).reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getQuantity(),
      0
    );
  }

  @Selector()
  static itemsWithoutOrdersPurchaseSumPrice(state: DispositionDetailsStateModel): number {
    return this.itemsWithoutOrders(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getPurchaseSumPrice(),
      0
    );
  }

  @Selector()
  static itemsWithoutOrdersSaleSumPrice(state: DispositionDetailsStateModel): number {
    return this.itemsWithoutOrders(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getSaleSumPrice(),
      0
    );
  }

  @Selector()
  static dispositionItemsWithOrders(state: DispositionDetailsStateModel): DispositionItem[] {
    return (
      state?.disposition?.disposition_items.filter((i) => i.service?.is_sale).map((i) => new DispositionItem(i)) || []
    );
  }

  @Selector()
  static dispositionItemsWithOrdersQuantity(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsWithOrders(state).reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getQuantity(),
      0
    );
  }

  @Selector()
  static dispositionItemsWithOrdersPurchaseSumPrice(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsWithOrders(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getPurchaseSumPrice(),
      0
    );
  }

  @Selector()
  static dispositionItemsWithOrdersSaleSumPrice(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsWithOrders(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getSaleSumPrice(),
      0
    );
  }

  @Selector()
  static dispositionItemsAsProducts(state: DispositionDetailsStateModel): DispositionItem[] {
    return (
      state?.disposition?.disposition_items.filter((i) => i.service.is_product).map((i) => new DispositionItem(i)) || []
    );
  }

  @Selector()
  static dispositionItemsAsProductsQuantity(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsAsProducts(state).reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getQuantity(),
      0
    );
  }

  @Selector()
  static dispositionItemsAsProductsPurchaseSumPrice(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsAsProducts(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getPurchaseSumPrice(),
      0
    );
  }

  @Selector()
  static dispositionItemsAsProductsSaleSumPrice(state: DispositionDetailsStateModel): number {
    return this.dispositionItemsAsProducts(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getSaleSumPrice(),
      0
    );
  }

  @Selector()
  static products(state: DispositionDetailsStateModel): DispositionItem[] {
    return [...this.dispositionItemsWithOrders(state), ...this.dispositionItemsAsProducts(state)];
  }

  @Selector()
  static productsQuantity(state: DispositionDetailsStateModel): number {
    return this.products(state).reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getQuantity(),
      0
    );
  }

  @Selector()
  static productsPurchaseSumPrice(state: DispositionDetailsStateModel): number {
    return this.products(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getPurchaseSumPrice(),
      0
    );
  }

  @Selector()
  static productsSaleSumPrice(state: DispositionDetailsStateModel): number {
    return this.products(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getSaleSumPrice(),
      0
    );
  }

  @Selector()
  static dispositionAuth(state: DispositionDetailsStateModel): DispositionAuth {
    return new DispositionAuth(state.disposition.auths);
  }

  @Selector()
  static hasConfirmedVariant(state: DispositionDetailsStateModel): boolean {
    return !!this.dispositionItemsWithOrders(state).find((i) => i.confirmedVariant());
  }

  @Selector()
  static hasSpare(state: DispositionDetailsStateModel): boolean {
    return typeof state.disposition?.spare_wheel === 'string';
  }

  @Selector()
  static purchaseSumPrice(state: DispositionDetailsStateModel): number {
    return this.products(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getPurchaseSumPrice(),
      0
    );
  }

  @Selector()
  static saleSumPrice(state: DispositionDetailsStateModel): number {
    return this.products(state)?.reduce(
      (accumulator, dispositionItem) => +accumulator + dispositionItem.getSaleSumPrice(),
      0
    );
  }

  @Selector()
  static editMode(state: DispositionDetailsStateModel): boolean {
    return state.editMode;
  }

  @Selector()
  static loading(state: DispositionDetailsStateModel): boolean {
    return state.loading;
  }

  @Action(DispositionDetailsGetDisposition)
  getDisposition(
    ctx: StateContext<DispositionDetailsStateModel>,
    action: DispositionDetailsGetDisposition
  ): Observable<DataResource<DispositionResource>> {
    return this.dispositionsService.getById(action.id, { extract_by_auth_status: 'declined' }).pipe(
      tap((res) => {
        ctx.patchState({
          disposition: res.data,
        });
      })
    );
  }

  @Action(DispositionDetailsSetDisposition)
  setDisposition(ctx: StateContext<DispositionDetailsStateModel>, action: DispositionDetailsSetDisposition): void {
    ctx.patchState({
      disposition: action.disposition,
    });
  }

  @Action(DispositionDetailsReset)
  reset(ctx: StateContext<DispositionDetailsStateModel>, action: DispositionDetailsSetDisposition): void {
    ctx.setState(DEFAULTS);
  }

  @Action(DispositionDetailsSetDispositionItemPatchData)
  setDispositionItemPatchData(
    ctx: StateContext<DispositionDetailsStateModel>,
    action: DispositionDetailsSetDispositionItemPatchData
  ): void | undefined {
    const notExist =
      ctx.getState().patchData === null ||
      !ctx
        .getState()
        .patchData.find((i) => i.disposition_item_id === action.dispositionItemPatchData.disposition_item_id);
    ctx.setState(
      patch({
        patchData: iif(
          notExist,
          insertItem(action.dispositionItemPatchData),
          updateItem(
            (item: DispositionItemPatchData) =>
              item.disposition_item_id === action.dispositionItemPatchData.disposition_item_id,
            action.dispositionItemPatchData
          )
        ),
      })
    );
  }

  @Action(DispositionDetailsSetEditMode)
  setEditMode(ctx: StateContext<DispositionDetailsStateModel>, action: DispositionDetailsSetEditMode): void {
    ctx.patchState({
      editMode: action.mode,
    });
  }

  @Action(DispositionDetailsSetLoading)
  setLoading(ctx: StateContext<DispositionDetailsStateModel>, action: DispositionDetailsSetLoading): void {
    ctx.patchState({
      loading: action.loading,
    });
  }

  constructor(private dispositionsService: DispositionsService) {}
}
