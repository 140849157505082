import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { HeaderAndBoldDataSize } from '@pso-ui/header-and-bold-data/header-and-bold-data.model';

@Component({
  selector: 'app-header-and-bold-data',
  templateUrl: './header-and-bold-data.component.html',
  styleUrls: ['./header-and-bold-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderAndBoldDataComponent {
  @Input() header: string;
  @Input() text: string;
  @Input() bold: string;
  @Input() size: HeaderAndBoldDataSize = 'm';

  @HostBinding('style.fontSize.px') get styleFontSize(): number {
    return new Map<HeaderAndBoldDataSize, number>([
      ['m', 12],
      ['l', 14],
    ]).get(this.size);
  }

  @HostBinding('style.lineHeight.px') get styleLineHeight(): number {
    return new Map<HeaderAndBoldDataSize, number>([
      ['m', 14],
      ['l', 16],
    ]).get(this.size);
  }
}
