import { skip } from 'rxjs/operators';
import { Component, Input, OnInit, Output } from '@angular/core';
import { uniq } from 'lodash';
import { MetaResource } from '../../interfaces/paged-resources';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wchfs-per-page',
  templateUrl: './per-page.component.html',
  styleUrls: ['./per-page.component.scss'],
})
export class PerPageComponent implements OnInit {
  @Input() meta: MetaResource;
  @Input() labels: {
    ofLabel: string;
    showLabel: string;
  } = { ofLabel: 'z', showLabel: 'Show' };
  @Input() options = [15, 30, 50, 100];
  pageForm = new UntypedFormGroup({
    per_page: new UntypedFormControl(),
  });
  @Output() perPage = this.pageForm.get('per_page').valueChanges.pipe(skip(1));

  constructor() {}

  ngOnInit(): void {
    this.options = uniq([this.meta.per_page, ...this.options]).sort((a, b) => a - b);
    this.pageForm.get('per_page').patchValue(this.meta.per_page, { emitEvent: false, onlySelf: true });
  }
}
