import { Component, Input } from '@angular/core';
import { TooltipStyle } from './tooltip.constants';
import { ToCoerce } from '../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text: string;
  @Input() htmlContent: string;

  tooltipStyle = TooltipStyle;
  style: TooltipStyle;
  icon: ToCoerce;
}
