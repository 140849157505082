import { SidebarStateModel } from './sidebar.interface';

export class SetSidebarState {
  static readonly type = '[Sidebar] State';

  constructor(public state: SidebarStateModel) {}
}

export class ToggleSidebarState {
  static readonly type = '[Sidebar] Toggle';
}
