import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  constructor() {}

  getColorHex(color: Color = Colors.PRIMARY): string {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${color}`).trim();
  }

  getColorRgba(color: Color = Colors.PRIMARY, opacity: number = 1): string {
    return this.hexToRgba(this.getColorHex(color), opacity);
  }

  hexToRgba(hexCode: string, opacity: number) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
}

export enum Colors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARN = 'warn',
  GREY1 = 'grey-1',
  GREY2 = 'grey-2',
  GREY3 = 'grey-3',
  GREY4 = 'grey-4',
  GREY5 = 'grey-5',
  GREY6 = 'grey-6',
  WHITE = 'white',
}

export type Color =
  | Colors.PRIMARY
  | Colors.SECONDARY
  | Colors.WARN
  | Colors.GREY1
  | Colors.GREY2
  | Colors.GREY3
  | Colors.GREY4
  | Colors.GREY5
  | Colors.GREY6
  | Colors.WHITE;
