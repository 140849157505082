import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule, SvgIconModule],
  exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
