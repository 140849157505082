<div class="dots">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
<div class="content text-body-2">
  <div class="content-container">
    <span>
      <ng-content></ng-content>
    </span>
  </div>
  <div class="suffix-container">
    <ng-content class="icon-action" select="wchfs-draggable-chip-suffix"></ng-content>
  </div>
</div>
