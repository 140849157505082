import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { isSameMonth, setMonth, startOfDay } from '../date-utils';
import { CalendarTypes } from '../calendar.constants';

@Component({
  selector: 'wchfs-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {
  months!: readonly string[];
  currentDate = startOfDay(new Date());
  @Input() selectedDate?: Date;
  @Input() dateInStepper?: Date = this.currentDate;
  @Output() selectedMonthChange = new EventEmitter<Date>();
  @Output() changeCalendarType = new EventEmitter<CalendarTypes>();
  private currentLocale?: string;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  @Input()
  get locale() {
    return this.currentLocale;
  }

  set locale(locale: string | undefined) {
    this.currentLocale = locale || this.localeId;
    this.months = this.getMonths();
  }

  ngOnInit(): void {
    if (!this.locale) {
      this.locale = this.localeId;
    }
  }

  selectMonth(indexMonth: number) {
    const stepperDate = this.dateInStepper || this.currentDate;

    const newSelectedDate = setMonth(this.selectedDate || this.currentDate, indexMonth);
    newSelectedDate.setFullYear(stepperDate.getFullYear());

    this.selectedMonthChange.emit(newSelectedDate);
    this.changeCalendarType.emit(CalendarTypes.days);
  }

  isSelectedMonth(month: string) {
    return !!this.selectedDate ? isSameMonth(this.locale, month, this.selectedDate) : false;
  }

  isCurrentMonth(month: string) {
    return !!this.currentDate ? isSameMonth(this.locale, month, this.currentDate) : false;
  }

  private getMonths() {
    return getLocaleMonthNames(this.locale, FormStyle.Format, TranslationWidth.Abbreviated);
  }
}
