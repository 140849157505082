import { Pipe, PipeTransform } from '@angular/core';
import { ColumnData } from '../draggable-table.component';

@Pipe({
  name: 'chipLabel',
})
export class ChipLabelPipe implements PipeTransform {
  transform(column: ColumnData, customKeyValue: string = null): string {
    if (customKeyValue) {
      return column[customKeyValue];
    } else {
      return column.label || column.value || 'Name not found...';
    }
  }
}
