import { Highlightable } from '@angular/cdk/a11y';
import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { DropdownService } from '../select/dropdown.service';
import { SelectComponent } from '../select/select.component';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

let instances = 0;

@Component({
  selector: 'wchfs-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent implements OnInit, Highlightable {
  @HostBinding('class.active')
  public active = false;
  public padding = false;
  @ViewChild('label') label: ElementRef;
  public select: SelectComponent;

  constructor(private dropdownService: DropdownService, private elementRef: ElementRef) {
    this.select = this.dropdownService.getSelect();
    this.key = (instances++).toString();
  }

  private _hidden: any; // TODO: ToCoerce type check

  get hidden(): any {
    return this._hidden;
  }

  @coerceBoolean
  set hidden(value: any) {
    this._hidden = value;
    this.elementRef.nativeElement.style.display = this._hidden ? 'none' : 'block';
  }

  private _key: any;

  get key() {
    return this._key;
  }

  @Input()
  set key(value: any) {
    this._key = value;
  }

  private _value: any;

  get value() {
    return this._value;
  }

  @Input()
  set value(value: any) {
    this.select.setCompareStrategy(value);
    this._value = value;
  }

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.select.multiselect ? this.select.isSelected(this) : this.select.selectedOption === this;
  }

  ngOnInit(): void {}

  getLabel(): string {
    return this.label.nativeElement.textContent.trim();
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.select.toggleOption(this);
  }
}
