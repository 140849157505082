import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TIME_PICKER_HOURS, TIME_PICKER_MINUTES, TimePickerType, TimeType, TimeTypes } from '../../timpicker.constans';

@Component({
  selector: 'wchfs-time-list',
  templateUrl: './time-list.component.html',
  styleUrls: ['./time-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeListComponent implements OnInit {
  @Input() timeType: TimeType;
  @Input() value = '';
  @Output() pickerValueChange = new EventEmitter<TimePickerType>();

  // @ts-ignore
  timeArray = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    let timeTypeNumber = 0;
    switch (this.timeType) {
      case TimeTypes.HH:
        timeTypeNumber = TIME_PICKER_HOURS;
        break;
      case TimeTypes.MM:
        timeTypeNumber = TIME_PICKER_MINUTES;
        break;
      default:
        console.warn(`Unknown time type ${this.timeType}.`);
    }
    this.timeArray = [...new Array(timeTypeNumber)].map((item, index) => {
      if (this.timeType === TimeTypes.HH) {
        return index.toString().padStart(2, '0');
      }
      return index.toString().padStart(2, '0');
    });
  }

  clickHandler(value: string) {
    this.value = value;
    this.pickerValueChange.emit({
      type: this.timeType,
      value,
    });
    this.cdr.markForCheck();
  }
}
