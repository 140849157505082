export const INITIAL_AUTO_MASK_POSITION = 15;
export const PADDING_LEFT_FOR_AUTO_MASK = 45;
export const PADDING_RIGHT_FOR_AUTO_MASK = 10;

export const PADDING_RIGHT_WITH_MASK_END = 30;

// hint placeholder
export const PADDING_RIGHT_SPACE_WITH_HINT = 10;
export const PADDING_LEFT_FOR_HINT = 30;
export const INITIAL_PADDING_VALUE_TO_ADD = 5;

export const DATE_PLACEHOLDER_CHAR_WIDTH = 8;
export const DATE_PLACEHOLDER_INITIAL_MARGIN_LEFT = 15;
