import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DynamicBreadcrumbsComponent } from './dynamic-breadcrumbs/dynamic-breadcrumbs.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BreadcrumbsComponent, DynamicBreadcrumbsComponent],
  imports: [CommonModule, TranslateModule],
  exports: [BreadcrumbsComponent, DynamicBreadcrumbsComponent],
})
export class BreadcrumbModule {}
