<div>
  <div class="snackbar-message">
    <wchfs-icon [svgIcon]="snackbarIconName" class="snackbar-icon-type" fill="primary" size="x-small"></wchfs-icon>
    <p class="message">{{ data.message }}</p>
    <span
      (click)="dismiss()"
      *ngIf="data.withDismissButton; else crossButton"
      [ngClass]="'snackbar-action-button-' + data.type"
      class="snackbar-action-button"
    >
      Dismiss
    </span>
    <ng-template #crossButton>
      <wchfs-icon
        class="close-icon"
        (click)="dismiss()"
        class="cross-icon"
        fill="grey2"
        size="x-small"
        svgIcon="wchfs:cross"
      ></wchfs-icon>
    </ng-template>
  </div>
</div>
