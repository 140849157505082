import { MetaResource } from '@app/@shared/interfaces/paged-resource';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export class SetVisibleColumns {
  static readonly type = '[Authorization] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[Authorization] Set Active Tab';

  constructor(public tab: ListTabWithColumns) {}
}

export class SetTabSlug {
  static readonly type = '[Authorization Params] Set Tab Slug';

  constructor(public slug: string) {}
}

export class SetMeta {
  static readonly type = '[Authorization] Set Meta';

  constructor(public meta: MetaResource) {}
}
