import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../form/form.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [TableComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, SvgIconModule, FormModule],
  exports: [TableComponent],
})
export class TableModule {}
