export interface SnackBarActions {
  actionMessage: string;
  handler: () => void;
}

export interface SnackBarData {
  message: string;
  type: SnackBarType;
  actions: SnackBarActions[];
  withDismissButton?: boolean;
}

export enum SnackBarType {
  error = 'error',
  success = 'success',
  warning = 'warning',
}
