import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserImpersonableSelectBaseComponent } from '../user-impersonable-select-base.component';

@Component({
  selector: 'app-user-impersonable-single-select',
  templateUrl: './user-impersonable-single-select.component.html',
  styleUrls: ['./user-impersonable-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserImpersonableSingleSelectComponent extends UserImpersonableSelectBaseComponent {}
