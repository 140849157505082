<form [formGroup]="tableColumn" class="draggable-column-wrapper">
  <div class="column">
    <span class="column-title">{{ title }}</span>
    <wchfs-form-field>
      <input wchfsInput formControlName="column" [placeholder]="placeholder" />
      <div wchfsSuffix class="suffix-with-magnifier">
        <wchfs-icon
          *ngIf="tableColumn.controls['column'].value"
          style="cursor: pointer"
          (click)="clearInputSearch('column')"
          [svgIcon]="'wchfs:chip-cross'"
        ></wchfs-icon>
        <wchfs-icon *ngIf="!tableColumn.controls['column'].value" [svgIcon]="'wchfs:search'"></wchfs-icon>
      </div>
    </wchfs-form-field>
    <div
      class="chips-list"
      (cdkDropListDropped)="moveOrTransfer($event)"
      [cdkDropListData]="columns"
      cdkDropList
      class="chips-list"
    >
      <wchfs-draggable-chip
        *ngFor="
          let column of columns | columnSearch: tableColumn.controls['column'].value:columns.length:customKeyValue;
          let i = index
        "
        cdkDrag
        [cdkDragData]="column"
        showFilterIcon="false"
        [active]="column.active"
      >
        {{ column | chipLabel: customKeyValue }}
        <wchfs-draggable-chip-suffix>
          <!--          <wchfs-switcher (changed)="switcherValueChanged($event, column)" [value]="column.active"></wchfs-switcher>-->
          <!--          TODO -->
          [Switcher]
        </wchfs-draggable-chip-suffix>
      </wchfs-draggable-chip>
    </div>
  </div>
</form>
