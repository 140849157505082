import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  RelocationsStateModel,
  SetActiveTab,
  SetLoading,
  SetTabSlug,
  SetVisibleColumns,
} from '@data/relocations/relocations.actions';
import { Injectable } from '@angular/core';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

const DEFAULTS: RelocationsStateModel = {
  activeTab: null,
  visibleColumns: [],
  loading: false,
  tab_slug: '',
};

@State<RelocationsStateModel>({
  name: 'relocations',
  defaults: DEFAULTS,
})
@Injectable()
export class RelocationsState {
  @Selector()
  static activeTab(state: RelocationsStateModel): ListTabWithColumns {
    return state.activeTab;
  }

  @Selector()
  static visibleColumns(state: RelocationsStateModel): string[] {
    return state.visibleColumns;
  }

  @Selector()
  static loading(state: RelocationsStateModel): boolean {
    return state?.loading;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<RelocationsStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<RelocationsStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<RelocationsStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<RelocationsStateModel>, action: SetLoading): void {
    ctx.patchState({
      loading: action.loading,
    });
  }
}
