import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetActiveTab, SetTabSlug, SetVisibleColumns, VehiclesStateModel } from '@data/vehicles/vehicles.actions';
import { Injectable } from '@angular/core';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

@State<VehiclesStateModel>({
  name: 'vehicles',
  defaults: {
    visibleColumns: [],
    activeTab: null,
    tab_slug: '',
  },
})
@Injectable()
export class VehiclesState {
  @Selector()
  static visibleColumns(state: VehiclesStateModel): string[] {
    return state.visibleColumns;
  }

  @Selector()
  static activeTab(state: VehiclesStateModel): ListTabWithColumns {
    return state.activeTab;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<VehiclesStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<VehiclesStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<VehiclesStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }
}
