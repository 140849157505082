import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { coerceBoolean } from '../../decorators/coerce-property/coercions';
import { Color, Colors, ColorsService } from '../shared/colors/colors.service';
import { WchfsRippleDirective } from '../shared/ripple/ripple';

const BUTTON_HOST_ATTRIBUTES = ['wchfs-button', 'wchfs-flat-button', 'wchfs-icon-button'];
const BUTTON_RIPPLE_DEFAULT_COLOR = 'rgba(255, 255, 255, 0.3)';
const BUTTON_DEFAULT_TEXT_COLOR_CLASS = 'wchfs-button-default-text-color';

@Component({
  selector:
    'button[wchfs-button], button[wchfs-flat-button],' +
    'a[wchfs-button], a[wchfs-flat-button],' +
    'button[wchfs-icon-button], a[wchfs-icon-button]',
  exportAs: 'wchfsButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @HostBinding('class.wchfs-button-disabled')
  @coerceBoolean
  @Input()
  disabled = false;
  @Input() color: Color;
  @Input() size: ButtonSize;
  @Input() textColor: Color;
  @HostBinding('class.wchfs-button-underline-on-hover')
  @coerceBoolean
  @Input()
  underlineOnHover = false;
  ButtonSizes = ButtonSizes;
  Colors = Colors;
  public wchfsRippleCentered: false;

  rippleColor: string;
  hostElement: HTMLElement;
  @ViewChild(WchfsRippleDirective) ripple: WchfsRippleDirective;

  constructor(public elementRef: ElementRef, private colorsService: ColorsService) {}

  @HostBinding('attr.disabled') get isDisabled() {
    return this.disabled || null;
  }

  @HostBinding('class.wchfs-button-size-regular') get isRegular() {
    return this.size === ButtonSizes.REGULAR || (!this.size && !this.hasHostAttributes('wchfs-icon-button'));
  }

  @HostBinding('class.wchfs-button-size-small') get isSmall() {
    return this.size === ButtonSizes.SMALL || null;
  }

  @HostBinding('class.wchfs-button-color-white') get isWhite() {
    // @ts-ignore
    return this.color === Colors.WHITE || null;
  }

  @HostBinding('class') get textColorClass(): string {
    return this.textColor ? `text-${this.textColor}` : BUTTON_DEFAULT_TEXT_COLOR_CLASS;
  }

  private setRippleColor(): void {
    this.rippleColor = this.hostElement.hasAttribute('wchfs-button')
      ? this.colorsService.getColorRgba(this.textColor || Colors.GREY3, 20)
      : BUTTON_RIPPLE_DEFAULT_COLOR;
  }

  ngOnInit(): void {
    this.getHostElement();
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        this.hostElement.classList.add(attr);
      }
    }
    this.setRippleColor();
  }

  hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some((attribute) => this.hostElement.hasAttribute(attribute));
  }

  getHostElement(): void {
    this.hostElement = this.elementRef.nativeElement;
  }
}

export type ButtonSize = ButtonSizes.REGULAR | ButtonSizes.SMALL;

export enum ButtonSizes {
  REGULAR = 'regular',
  SMALL = 'small',
}

@Component({
  selector:
    'button[wchfs-button-dp], button[wchfs-flat-button-dp],' +
    'a[wchfs-button-dp], a[wchfs-flat-button-dp],' +
    'button[wchfs-icon-button-dp], a[wchfs-icon-button-dp]',
  exportAs: 'wchfsButtonDp',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class DatepickerButtonComponent extends ButtonComponent {}
