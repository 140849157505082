<ng-container *ngIf="service.days; else loader">
  <app-month-selector
    (nextArrowClicked)="service.nextMonth()"
    (previousArrowClicked)="service.previousMonth()"
    [text]="service.selectedMonth + ' ' + service.selectedYear"
  ></app-month-selector>

  <div class="day-select-wrapper">
    <button (click)="service.previousDays()" class="arrow left">
      <wchfs-icon size="24" svgIcon="pso:arrow_left_gray"></wchfs-icon>
    </button>

    <div class="days-wrapper">
      <app-day-card
        #dayCard
        (click)="!dayCard.disabled ? selectDay(day) : $event.preventDefault()"
        *ngFor="let day of service.days; let i = index"
        [bigText]="day.getWeekdayNumber()"
        [disabled]="day.isDisabled()"
        [selected]="service.isSelected(day)"
        [smallText]="day.getWeekdayName() | uppercase"
      ></app-day-card>
    </div>

    <button (click)="service.nextDays()" class="arrow">
      <wchfs-icon size="24" svgIcon="pso:arrow_right_gray"></wchfs-icon>
    </button>
  </div>

  <app-dot-loader *ngIf="service.loading"></app-dot-loader>

  <div *ngIf="service.selectedDay" class="hours-select-wrapper">
    <div class="before-noon">
      {{ 'before-noon' | translate }}
      <div class="hours-wrapper">
        <ng-container *ngIf="service.selectedDay?.getTermsBeforeNoon().length; else noTermTemplate">
          <ng-container
            *ngFor="let term of service.selectedDay?.getTermsBeforeNoon()"
            [ngTemplateOutletContext]="{ term: term }"
            [ngTemplateOutlet]="dayBadgeTemplate"
          ></ng-container>
        </ng-container>
      </div>
    </div>

    <div class="afternoon">
      {{ 'afternoon' | translate }}
      <div class="hours-wrapper">
        <ng-container *ngIf="service.selectedDay?.getTermsAfterNoon().length > 0; else noTermTemplate">
          <ng-container
            *ngFor="let term of service.selectedDay?.getTermsAfterNoon()"
            [ngTemplateOutletContext]="{ term: term }"
            [ngTemplateOutlet]="dayBadgeTemplate"
          ></ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #dayBadgeTemplate let-term="term">
      <app-blue-gradient-badge
        (click)="selectTerm(term)"
        [selected]="service.selectedTerm?.getHour() === term.getHour()"
        [text]="term.getHour()"
      ></app-blue-gradient-badge>
    </ng-template>

    <ng-template #noTermTemplate>
      <div class="empty-state">
        <wchfs-icon svgIcon="pso:info_m" size="24"></wchfs-icon>

        <div>Brak dostępnych terminów</div>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
