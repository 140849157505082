import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MetaResource } from '@shared/interfaces/paged-resource';
import { SetActiveTab, SetMeta, SetTabSlug } from './authorization.actions';
import { AuthorizationStateModel } from './authorization.model';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

@State<AuthorizationStateModel>({
  name: 'authorization',
  defaults: {
    activeTab: null,
    visibleColumns: [],
    tab_slug: '',
    meta: null,
  },
})
@Injectable()
export class AuthorizationState {
  @Selector()
  static activeTab(state: AuthorizationStateModel): ListTabWithColumns {
    return state.activeTab;
  }

  @Selector()
  static meta(state: AuthorizationStateModel): MetaResource {
    return state?.meta;
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<AuthorizationStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<AuthorizationStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetMeta)
  setMeta(ctx: StateContext<AuthorizationStateModel>, action: SetMeta): void {
    ctx.patchState({
      meta: action.meta,
    });
  }
}
