import { AuthorizationDetailsStateModel } from '@data/authorization/authorization-details.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthorizationDetailsSetDisposition } from '@data/authorization/authorization-details.actions';
import { DispositionItem } from '@data/disposition-item/disposition-item.model';
import { DispositionAuth } from '@data/disposition-auth/disposition-auth.model';

@State<AuthorizationDetailsStateModel>({
  name: 'authorizationDetails',
  defaults: {
    disposition: null,
  },
})
@Injectable()
export class AuthorizationDetailsState {
  @Selector()
  static itemsWithoutOrders(state: AuthorizationDetailsStateModel): DispositionItem[] {
    return state.disposition.disposition_items
      .filter((i) => i.suborder === null)
      .filter((i) => !i.service.is_product)
      .map((i) => new DispositionItem(i));
  }

  @Selector()
  static dispositionItemsWithOrders(state: AuthorizationDetailsStateModel): DispositionItem[] {
    return state?.disposition?.disposition_items.filter((i) => i.suborder).map((i) => new DispositionItem(i)) || [];
  }

  @Selector()
  static dispositionItemsAsProducts(state: AuthorizationDetailsStateModel): DispositionItem[] {
    return (
      state?.disposition?.disposition_items.filter((i) => i.service.is_product).map((i) => new DispositionItem(i)) || []
    );
  }

  @Selector()
  static products(state: AuthorizationDetailsStateModel): DispositionItem[] {
    return [...this.dispositionItemsWithOrders(state), ...this.dispositionItemsAsProducts(state)];
  }

  @Selector()
  static dispositionAuth(state: AuthorizationDetailsStateModel): DispositionAuth {
    return new DispositionAuth(state.disposition.auths);
  }

  @Selector()
  static hasConfirmedVariant(state: AuthorizationDetailsStateModel): boolean {
    return !!this.dispositionItemsWithOrders(state).find((i) => i.confirmedVariant());
  }

  @Action(AuthorizationDetailsSetDisposition)
  setDisposition(ctx: StateContext<AuthorizationDetailsStateModel>, action: AuthorizationDetailsSetDisposition): void {
    ctx.patchState({
      disposition: action.disposition,
    });
  }
}
