import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { NotificationService } from './notification.service';
import { LocalStorageService } from '@app/@shared/services/localstorage.service';

@Injectable()
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {}

  checkForUpdates(): void {
    console.log('checkForUpdates');
    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      console.log(event);
      if (event.type === 'VERSION_READY') {
        this.notificationService.actionConfirmation('new-software-version', 'update', () => {
          this.localStorageService.clearLocalStorage();
          window.location.reload();
        });
      }
    });
  }
}
