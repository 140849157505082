import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { FormModule } from '../form/form.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NewPaginatorComponent } from './new-paginator/new-paginator.component';
import { PerPageComponent } from './per-page/per-page.component';

@NgModule({
  declarations: [NewPaginatorComponent, PerPageComponent],
  imports: [CommonModule, ReactiveFormsModule, FormModule, SvgIconModule, ButtonModule, FlexLayoutModule],
  exports: [NewPaginatorComponent, PerPageComponent],
})
export class PaginationModule {}
