import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '@app/@pso-ui/modal/modal-header/modal-header.component';
import { ModalSubheaderComponent } from '@app/@pso-ui/modal/modal-subheader/modal-subheader.component';
import { ModalIconComponent } from '@app/@pso-ui/modal/modal-icon/modal-icon.component';
import { ModalButtonsGroupComponent } from '@app/@pso-ui/modal/modal-buttons-group/modal-buttons-group.component';
import { SvgIconModule } from '@app/@wchfs-ui';
import { ModalHeaderOnlyComponent } from './modal-header-only/modal-header-only.component';
import { CrossCloseComponent } from './cross-close/cross-close.component';

@NgModule({
  declarations: [
    ModalHeaderComponent,
    ModalSubheaderComponent,
    ModalIconComponent,
    ModalButtonsGroupComponent,
    ModalHeaderOnlyComponent,
    CrossCloseComponent,
  ],
  imports: [CommonModule, SvgIconModule],
  exports: [
    ModalHeaderComponent,
    ModalSubheaderComponent,
    ModalIconComponent,
    ModalButtonsGroupComponent,
    ModalHeaderOnlyComponent,
    CrossCloseComponent,
  ],
})
export class ModalModule {}
