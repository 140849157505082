import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ReleaseActionsStateModel,
  ResetDepositState,
  SetActiveDeposit,
  SetTabSlug,
  SetVisibleColumns,
  UnsetActiveDeposit,
} from '@data/release-actions/release-actions.actions';
import { Injectable } from '@angular/core';
import { TyreResource } from '@data/tyres/tyres.model';
import { TyresService } from '@data/tyres/tyres.service';

const DEFAULTS: ReleaseActionsStateModel = {
  activeDeposit: null,
  visibleColumns: [],
  tab_slug: '',
};

@State<ReleaseActionsStateModel>({
  name: 'releaseActions',
  defaults: DEFAULTS,
})
@Injectable()
export class ReleaseActionsState {
  @Selector()
  static activeDeposit(state: ReleaseActionsStateModel): TyreResource {
    return state.activeDeposit;
  }

  @Selector()
  static visibleColumns(state: ReleaseActionsStateModel): string[] {
    return state.visibleColumns;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<ReleaseActionsStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetActiveDeposit)
  setActiveDeposit(ctx: StateContext<ReleaseActionsStateModel>, action: SetActiveDeposit): void {
    this.tyresService.getById(action.id).subscribe((res) => {
      ctx.patchState({
        activeDeposit: res.data,
      });
    });
  }

  @Action(UnsetActiveDeposit)
  unsetActiveDeposit(ctx: StateContext<ReleaseActionsStateModel>): void {
    ctx.patchState({
      activeDeposit: null,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<ReleaseActionsStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(ResetDepositState)
  resetDepositState(ctx: StateContext<ReleaseActionsStateModel>, action: ResetDepositState): void {
    ctx.setState(DEFAULTS);
  }

  constructor(private tyresService: TyresService) {}
}
