import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullWidthSeparatorComponent } from '@pso-ui/full-width-separator/full-width-separator.component';

@NgModule({
  declarations: [FullWidthSeparatorComponent],
  imports: [CommonModule],
  exports: [FullWidthSeparatorComponent],
})
export class FullWidthSeparatorModule {}
