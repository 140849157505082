import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserTypeValue } from '@app/@data/users/users.model';
import { FeedInputService, InputOptionResource } from '@app/@feed-inputs';
import { PsoFilter } from '@data/filtering/filtering.interface';
import { SelectData } from '@pso-ui/select/select.interfaces';
import { merge, Observable } from 'rxjs';
import { map, pluck, reduce } from 'rxjs/operators';
import { UserImpersonableOptionData } from '@app/@feed-inputs/user-impersonable/user-impersonable-select-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: '',
})
export class UserImpersonableSelectBaseComponent implements PsoFilter<SelectData<number>[]>, OnInit {
  @Input() control: FormControl;
  @Input() type: UserTypeValue;
  @Input() label = 'users';
  data$: Observable<SelectData<number>[]>;

  constructor(protected feedInputService: FeedInputService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.data$ = this.getData();
  }

  getData(): Observable<SelectData<number>[]> {
    const workshops: Observable<InputOptionResource<UserImpersonableOptionData>[]> = this.feedInputService
      .getList('users', {
        for: 'workshop',
        impersonation: 1,
      })
      .pipe(pluck('data'));
    const fleets: Observable<InputOptionResource<UserImpersonableOptionData>[]> = this.feedInputService
      .getList('users', {
        for: 'fleet',
        impersonation: 1,
      })
      .pipe(pluck('data'));

    const call = merge(workshops, fleets).pipe(
      reduce((res, item: InputOptionResource<UserImpersonableOptionData>[]) => res.concat(item), [])
    );

    return call.pipe(
      map((data: InputOptionResource<UserImpersonableOptionData>[]) => {
        return data.map((item: InputOptionResource<UserImpersonableOptionData>) => ({
          value: item?.id,
          label: this.getLabel(item),
        }));
      })
    );
  }

  getLabel(item: InputOptionResource<UserImpersonableOptionData>): string {
    let label = `${item?.display_name} (${this.translateService.instant(item?.data?.source?.type)}: ${
      item?.data?.source?.name
    }`;

    if (item.data?.source?.code) {
      label += ` - ${item?.data?.source?.code}`;
    }

    label += ')';

    return label;
  }
}
