import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  RequestsParamsStateModel,
  SetPage,
  SetPerPage,
  SetTabSlug,
} from '@data/dispositions/dispositions-params.actions';

@State<RequestsParamsStateModel>({
  name: 'requestsParams',
  defaults: {
    page: 1,
    per_page: 15,
    tab_slug: '',
  },
})
@Injectable()
export class DispositionsParamsState {
  @Action(SetPerPage)
  setPerPage(ctx: StateContext<RequestsParamsStateModel>, action: SetPerPage): void {
    ctx.patchState({
      per_page: action.perPage,
    });
  }

  @Action(SetPage)
  setPage(ctx: StateContext<RequestsParamsStateModel>, action: SetPage): void {
    ctx.patchState({
      page: action.page,
    });
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<RequestsParamsStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }
}
