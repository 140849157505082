/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryEnvironment) {
  Sentry.init({
    environment: `${environment.sentryEnvironment}`,
    dsn: 'https://435aa1abb669b32d89bf86c70fe6f7d1@o4505793418100736.ingest.sentry.io/4505793447526400',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', environment.serverUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: environment.showSentryDialog,
  });
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err) => console.error(err));
}
