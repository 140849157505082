<div
  [class]="{
    'wchfs-checkbox-disabled': disabled,
    'wchfs-checkbox-checked': checked,
    'wchfs-checkbox-indeterminate': indeterminate
  }"
  class="wchfs-checkbox-wrapper"
>
  <div class="wchfs-checkbox"></div>

  <label [class.text-grey-1]="!disabled" [class.text-grey-4]="disabled" class="wchfs-checkbox-label text-input-1">
    <ng-content></ng-content>
  </label>
</div>
