import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { EMPTY, merge, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TooltipComponent } from '../../../tooltip/tooltip.component';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { FORM_ERRORS } from '../form-errors/form-errors';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ERROR_FROM_SERVER } from './control-errors.contants';
import { FormSubmitDirective } from './form-submit.directive';
import { CustomErrors } from './control-errors.interfaces';

@Directive({
  selector: '[wchfsInput], [wchfsFormErrorMessage]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  submit$: Observable<Event>;
  container: ViewContainerRef;
  ref: ComponentRef<ControlErrorComponent>;
  errorText: string;
  @Input() customErrors: CustomErrors = { error0: '' };
  @Input() showErrorMessage = true;
  @Input() showErrorMessageInTooltip = false;
  private onDestroy$ = new Subject<void>();
  private overlayRef: OverlayRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private vcr: ViewContainerRef,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Optional() @Host() private form: FormSubmitDirective,
    @Inject(FORM_ERRORS) private errors: InjectionToken<any>,
    private controlDir: NgControl,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
  }

  get control() {
    return this.controlDir.control;
  }

  ngOnInit() {
    merge(this.submit$, this.control.valueChanges, this.control.statusChanges)
      .pipe(takeUntil(this.onDestroy$), debounceTime(100))
      .subscribe(() => {
        if (!!this.overlayRef) {
          this.initErrorOverlay();
        }
        this.getErrors();
        this.createErrorText();
      });
    this.initErrorOverlay();
  }

  @HostListener('mouseenter')
  show() {
    if (this.showErrorMessageInTooltip && this.control.errors && this.errorText) {
      const tooltipPortal = new ComponentPortal(TooltipComponent);
      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);
      tooltipRef.instance.text = this.errorText;
    }
  }

  @HostListener('mouseleave')
  hide() {
    if (this.showErrorMessageInTooltip) {
      this.overlayRef.detach();
    }
  }

  initErrorOverlay() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elRef)
      .withLockedPosition(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    const config = new OverlayConfig({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ scrollThrottle: 10 }),
    });

    this.overlayRef = this.overlay.create(config);
    if (this.showErrorMessageInTooltip) {
      this.showErrorMessage = false;
    }
  }

  createErrorText() {
    const hintElement = this.elRef.nativeElement.parentElement.parentElement.querySelector('wchfs-hint');

    if (!!hintElement) {
      hintElement.style.display = 'none';
    }

    if (this.control.errors && this.showErrorMessage) {
      this.renderer.addClass(this.elRef.nativeElement.parentElement.parentElement, 'form-field-error');
      this.setError(this.errorText);
    } else if (this.ref) {
      this.renderer.removeClass(this.elRef.nativeElement.parentElement.parentElement, 'form-field-error');
      this.setError(null);
    }
  }

  getErrors(): void {
    if (this.control.errors !== null && Object.keys(this.control.errors)[0]) {
      const firstKey = Object.keys(this.control?.errors)[0];
      if (firstKey === ERROR_FROM_SERVER) {
        this.setError(this.control.errors[firstKey]);
        return;
      }
      const getError = this.errors[firstKey];
      this.errorText = this.customErrors[firstKey] || getError(this.control?.errors[firstKey]);
    }
  }

  setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  @HostListener('blur') handleBlurEvent() {
    this.createErrorText();
  }
}
