import { MetaResource } from '@shared/interfaces/paged-resource';

export interface EstimatesGroupStateModel {
  visibleColumns: string[];
  loading: boolean;
  tab_slug: string;
}

export class SetLoading {
  static readonly type = '[EstimatesGroupStateModel] Set Loading';

  constructor(public loading: boolean) {}
}

export class SetMeta {
  static readonly type = '[EstimatesGroupStateModel] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class SetTabSlug {
  static readonly type = '[EstimaEstimatesGroupStateModeltes] Set Tab Slug';

  constructor(public slug: string) {}
}

export class ResetEstimatesState {
  static readonly type = '[EstimatesGroupStateModel] Reset Estimates State';
}
