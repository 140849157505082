import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingWithButtonComponent } from '@pso-ui/heading/heading-with-button.component';
import { PsoButtonModule } from '@pso-ui/button-pso/pso-button.module';
import { SvgIconModule } from '@app/@wchfs-ui';
import { HeadingComponent } from '@pso-ui/heading/heading.component';

@NgModule({
  declarations: [HeadingComponent, HeadingWithButtonComponent],
  imports: [CommonModule, PsoButtonModule, SvgIconModule],
  exports: [HeadingComponent, HeadingWithButtonComponent],
})
export class HeadingModule {}
