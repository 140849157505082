import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  EstimatesGroupStateModel,
  ResetEstimatesState,
  SetLoading,
  SetTabSlug,
} from '@data/estimates-group/estimates-group.actions';
import { Injectable } from '@angular/core';

const DEFAULTS: EstimatesGroupStateModel = {
  visibleColumns: [],
  loading: false,
  tab_slug: '',
};

@State<EstimatesGroupStateModel>({
  name: 'estimate_groups',
  defaults: DEFAULTS,
})
@Injectable()
export class EstimatesGroupState {
  @Selector()
  static loading(state: EstimatesGroupStateModel): boolean {
    return state?.loading;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<EstimatesGroupStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<EstimatesGroupStateModel>, action: SetLoading): void {
    ctx.patchState({
      loading: action.loading,
    });
  }

  @Action(ResetEstimatesState)
  resetEstimatesState(ctx: StateContext<EstimatesGroupStateModel>, action: ResetEstimatesState): void {
    ctx.setState(DEFAULTS);
  }
}
