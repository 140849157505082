<div class="month-header">
  <button
    (click)="stepMonth(-1)"
    *ngIf="showMonthStepper"
    aria-label="Previous month"
    class="month-header__stepper month-header__stepper--previous"
    type="button"
  >
    <i class="arrow-left">
      <wchfs-icon-dp [svgIcon]="'wchfs:arrow-left'"></wchfs-icon-dp>
    </i>
  </button>
  <div class="month-header__caption">
    <span (click)="openMonthCalendar()" class="month">{{ dateInStepper | date: 'MMMM' }}</span>
    <span (click)="openYearCalendar()" class="year">{{ dateInStepper | date: 'y' }}</span>
  </div>
  <button
    (click)="stepMonth(1)"
    *ngIf="showMonthStepper"
    aria-label="Next month"
    class="month-header__stepper month-header__stepper--next"
    type="button"
  >
    <i class="arrow-right">
      <wchfs-icon-dp [svgIcon]="'wchfs:arrow-right'"></wchfs-icon-dp>
    </i>
  </button>
</div>
