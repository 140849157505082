<div class="icon">
  <div class="icon-wrapper">
    <wchfs-icon [svgIcon]="SignalBoxType.getIcon(type)" size="30"></wchfs-icon>
  </div>
</div>
<div class="wrapper">
  <div class="heading">{{ heading }}</div>
  <div class="content">
    <p *ngIf="textLine1">{{ textLine1 }}</p>
    <p *ngIf="textLine2">{{ textLine2 }}</p>
  </div>
</div>
