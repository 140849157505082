import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { CalendarDateTimePickerService } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.service';
import { DateTime } from 'luxon';
import { CalendarDateTimePickerDataProvider } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.model';
import { Observable, Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReservationDay, ReservationDayResource, ReservationDayTerm } from '@data/reservations/reservations.model';

@Component({
  selector: 'app-calendar-date-time-picker',
  templateUrl: './calendar-date-time-picker.component.html',
  styleUrls: ['./calendar-date-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarDateTimePickerComponent),
      multi: true,
    },
    CalendarDateTimePickerService,
  ],
})
export class CalendarDateTimePickerComponent implements OnInit, ControlValueAccessor {
  value: DateTime;
  disabled: boolean;
  private subscription = new Subscription();
  private pristine = true;
  private idSetterInitialStream$: Observable<ReservationDayResource[]>;
  private writeValueInitialStream$: Observable<ReservationDayResource[]>;
  private _id: number;
  private _datesService: CalendarDateTimePickerDataProvider;

  constructor(public readonly service: CalendarDateTimePickerService, private cdr: ChangeDetectorRef) {}

  @HostBinding('class.loading') get classLoading(): boolean {
    return this.service.loading;
  }

  get id(): number {
    return this._id;
  }

  @Input()
  set id(value: number) {
    this.service.setId(value);
    if (!this.idSetterInitialStream$) {
      this.idSetterInitialStream$ = this.service.refresh(value, this.value || DateTime.now());
    } else {
      this.service.refresh(value, this.value || DateTime.now()).subscribe();
    }
    this._id = value;
  }

  get datesService(): CalendarDateTimePickerDataProvider {
    return this._datesService;
  }

  @Input()
  set datesService(value: CalendarDateTimePickerDataProvider) {
    this.service.setDatesService(value);
    this._datesService = value;
  }

  writeValue(value: DateTime): void {
    this.value = value;
    if (this.pristine && value && this.id) {
      if (!this.writeValueInitialStream$) {
        this.writeValueInitialStream$ = this.service.refresh(this.id, value); // INFO: initial value
      } else {
        this.service.refresh(this.id, value).subscribe(); // INFO: initial value
      }
    }
    this.pristine = false;
    this.onChangeFn(value);
  }

  onChangeFn = (_: unknown): void => {
    //
  };
  onTouchedFn = (_: unknown): void => {
    //
  };

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  ngOnInit(): void {
    this.subscription.add(this.listenMarkForCheck());
    setTimeout(() => {
      if (this.writeValueInitialStream$) {
        this.writeValueInitialStream$.subscribe();
      } else if (this.idSetterInitialStream$) {
        this.idSetterInitialStream$.subscribe();
      } else {
        // INFO: do nothing
      }
    });
  }

  selectDay(day: ReservationDay): void {
    this.service.selectDay(day);
    this.writeValue(null);
  }

  selectTerm(term: ReservationDayTerm): void {
    this.service.selectTerm(term);
    this.writeValue(term.getValue());
  }

  private listenMarkForCheck() {
    return this.service.markForCheck$.asObservable().subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
