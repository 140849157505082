import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@data/auth/auth-state';
import { addOauthHeader } from './oauth';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      const { token_type, access_token } = this.store.selectSnapshot(AuthState);
      request = addOauthHeader(request, token_type, access_token);
    }

    return next.handle(request);
  }
}
