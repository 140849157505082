<div
  class="snackbar"
  [class.exception]="!data.title && data.message.length < 30 && data.actions?.length === 1"
  [class.message-only]="!data?.title && !data?.actions"
>
  <div class="icon-wrap" [ngSwitch]="data.type">
    <wchfs-icon *ngSwitchCase="'error'" class="snackbar-type-icon" size="24" svgIcon="pso:warning_m"></wchfs-icon>
    <wchfs-icon *ngSwitchCase="'success'" class="snackbar-type-icon" size="24" svgIcon="pso:success_m"></wchfs-icon>
    <wchfs-icon *ngSwitchCase="'warning'" class="snackbar-type-icon" size="24" svgIcon="pso:alert_m"></wchfs-icon>
    <wchfs-icon *ngSwitchCase="'info'" class="snackbar-type-icon" size="24" svgIcon="pso:info_m"></wchfs-icon>
  </div>

  <div class="snackbar-content">
    <div class="title" *ngIf="data?.title">{{ data?.title }}</div>
    <div class="message" *ngIf="data?.message">{{ data?.message }}</div>
    <div class="actions" [ngClass]="data.type" *ngIf="data?.actions">
      <button
        class="snackbar-button"
        *ngFor="let action of data?.actions; let i = index"
        (click)="action?.handler(); close()"
        [ngStyle]="{ color: color }"
        [dataCy]="'snackbar-action-button-' + i"
      >
        {{ action?.actionMessage }}
      </button>
    </div>
  </div>
  <wchfs-icon
    [dataCy]="'snackbar-close-button'"
    class="close"
    size="24"
    svgIcon="pso:close_m"
    (click)="close()"
  ></wchfs-icon>
</div>
