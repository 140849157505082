import { Injectable } from '@angular/core';
import { Id } from '@core/http/crud-model';
import { Observable, Subject } from 'rxjs';
import { ReservationsService } from '@data/reservations/reservations.service';
import { pluck } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { CalendarDateTimePickerDataProvider } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.model';
import {
  GetFreeDatesGetRequest,
  RESERVATION_BE_DATE_FORMAT,
  ReservationDayResource,
  ReservationPatchRequest,
  ReservationResource,
  ReservationStatus,
} from '@data/reservations/reservations.model';
import { DateTimeString } from '@shared/interfaces/date-string';
import { DataResource } from '@shared/interfaces/data-resource';
import { toBeFormat } from '@shared/utils/luxon/to-be-format';

@Injectable()
export class EditReservationDateModalService implements CalendarDateTimePickerDataProvider {
  loading = false;
  term: FormControl<DateTime>;
  reservation: ReservationResource;
  markForCheck$ = new Subject<void>();

  constructor(private reservationsService: ReservationsService) {}

  getData(id: Id): void {
    if (!id) {
      console.error(this.constructor.name + ': No ID');
      return;
    }
    this.reservationsService
      .getById(id)
      .pipe(pluck('data'))
      .subscribe((reservation) => {
        this.reservation = reservation;
        this.setTerm(reservation.start_date);
        this.markForCheck$.next();
      });
  }

  getFreeDates(id: number, startDate: DateTime): Observable<ReservationDayResource[]> {
    return this.reservationsService.getFreeDates(this.prepareGetFreeDatesRequest(id, startDate)).pipe(pluck('data'));
  }

  prepareGetFreeDatesRequest(workshopId: number, startDate: DateTime): GetFreeDatesGetRequest {
    return {
      workshop_id: workshopId,
      start_date: startDate.toFormat(RESERVATION_BE_DATE_FORMAT),
      end_date: startDate.plus({ day: 6 }).toFormat(RESERVATION_BE_DATE_FORMAT),
      paginate: '1',
      page: 1,
      per_page: 7,
      tab: '',
      sort: '',
    };
  }

  save(): Observable<DataResource<ReservationResource>> {
    const request = this.prepareRequest();
    return this.reservationsService.update(this.reservation.id, request);
  }

  private setTerm(startDateTime: DateTimeString) {
    this.term = new FormControl<DateTime>(DateTime.fromISO(startDateTime));
  }

  private prepareRequest(): ReservationPatchRequest {
    const diffInMinutes: number = DateTime.fromISO(this.reservation.end_date)
      .diff(DateTime.fromISO(this.reservation.start_date))
      .as('minutes');
    return {
      start_date: toBeFormat(this.term.getRawValue()),
      end_date: toBeFormat(this.term.getRawValue().plus({ minute: diffInMinutes })),
      status: ReservationStatus.APPROVED,
    };
  }
}
