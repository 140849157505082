import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DotLoaderComponent } from './dot-loader.component';
import { DotComponent } from './dot/dot.component';
import { AflDotLoaderComponent } from './afl-dot-loader/afl-dot-loader.component';
import { PsoaDotLoaderComponent } from './psoa-dot-loader/psoa-dot-loader.component';
import { WorkshopDotLoaderComponent } from './workshop-dot-loader/workshop-dot-loader.component';
@NgModule({
  declarations: [
    DotLoaderComponent,
    DotComponent,
    AflDotLoaderComponent,
    PsoaDotLoaderComponent,
    WorkshopDotLoaderComponent,
  ],
  imports: [CommonModule],
  exports: [
    DotLoaderComponent,
    PsoaDotLoaderComponent,
    AflDotLoaderComponent,
    WorkshopDotLoaderComponent,
    DotComponent,
  ],
  providers: [],
})
export class DotLoaderModule {}
