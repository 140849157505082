import { MetaResource } from '@shared/interfaces/paged-resource';

export interface OrdersWithReservationStateModel {
  visibleColumns: string[];
}

export class SetVisibleColumns {
  static readonly type = '[OrdersWithReservation] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetMeta {
  static readonly type = '[OrdersWithReservation] Set Meta';

  constructor(public meta: MetaResource) {}
}
