import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WeekDay } from '@angular/common';
import { DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';

import {
  addDays,
  areDatesInSameMonth,
  getDaysOfMonth,
  isDateAfter,
  isSameDate,
  isValidDate,
  setMonth,
  startOfDay,
} from '../date-utils';
import { DayStepDelta } from './day-step-delta.model';
import { CalendarDateFormat, NUMBER_OF_DAYS_ON_WEEK } from '../calendar.constants';
import { DayPickerService } from './day-picker.service';
import { RangeTime } from '../calendar.interface';

export const keyCodesToDaySteps = new Map<number, DayStepDelta>([
  [RIGHT_ARROW, 1],
  [LEFT_ARROW, -1],
  [DOWN_ARROW, 7],
  [UP_ARROW, -7],
]);

@Component({
  selector: 'wchfs-day-picker',
  templateUrl: './day-picker.component.html',
  styleUrls: ['./day-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPickerComponent implements AfterViewInit, OnChanges {
  daysOfMonth!: readonly Date[];
  firstDayOfMonth!: string;
  currentDate = startOfDay(new Date());
  @Input() dateFormat: CalendarDateFormat = CalendarDateFormat.yyyyMMdd;
  @Input() selectedDate?: Date;
  @Input() min?: Date | null;
  @Input() locale?: string;
  @Input() activeDate!: Date;
  @Input() firstDayOfWeek: string;
  @Input() showSelectButton: boolean;
  @Input() isRangePicker: boolean;
  @Output() selectedDateChange = new EventEmitter<Date>();
  @Output() activeDateChange = new EventEmitter<Date>();
  @Output() startDateInRangeChange = new EventEmitter<Date>();
  @Output() endDateInRangeChange = new EventEmitter<Date>();
  private dayOnHover: Date;
  private readonly dateSelector = 'time.month__date';
  private currentMonth!: Date;
  private rangeValue: any = {
    start: undefined,
    end: undefined,
  };

  constructor(public changeDetectorRef: ChangeDetectorRef, private dayPickerService: DayPickerService) {}

  @Input()
  get month() {
    return this.currentMonth;
  }

  set month(month: Date) {
    if (!!month && !isValidDate(month)) {
      console.error('Incorrect date format. Please use the supported format for the datepicker: yyyy/MM/dd');
      return;
    }

    if (!this.currentMonth || !areDatesInSameMonth(this.currentMonth, month)) {
      this.currentMonth = month;
      this.daysOfMonth = getDaysOfMonth(this.currentMonth);
      this.firstDayOfMonth = WeekDay[this.daysOfMonth[0].getDay()].toLowerCase();

      this.addDayFromOtherMonths();
    }
  }

  @Input()
  get rangeValueStart() {
    return this.rangeValue.start;
  }

  set rangeValueStart(control: string) {
    this.rangeValue.start = control;

    if (!!control && !isValidDate(this.convertDateFormatHOT_FIX(control))) {
      console.error('Incorrect date format. Please use the supported format for the datepicker: yyyy/MM/dd');
    }
  }

  @Input()
  get rangeValueEnd() {
    return this.rangeValue.end;
  }

  set rangeValueEnd(control: RangeTime) {
    this.rangeValue.end = control;
  }

  @Input() set selectedLastDays(range: RangeTime) {
    if (!!range) {
      this.rangeValue = range;
      this.dayPickerService.changeSelectedRange(this.rangeValue);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit() {
    if (this.isRangePicker && this.showSelectButton) {
      this.dayPickerService.changeSelectedRange(this.rangeValue);
    }
    this.changeDetectorRef.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.entries(changes).some(([input, change]) => input !== 'month' && !change.firstChange)) {
      this.changeDetectorRef.detectChanges();
    }
  }

  isSelected(dayOfMonth: Date) {
    return (
      (!!this.selectedDate && isSameDate(dayOfMonth, this.selectedDate)) ||
      (this.isRangePicker && this.isStartOrEndRange(dayOfMonth))
    );
  }

  isDisabled(dayOfMonth: Date) {
    return !!this.min && isDateAfter(this.min, dayOfMonth);
  }

  isActive(dayOfMonth: Date) {
    return !!this.activeDate && isSameDate(dayOfMonth, this.activeDate);
  }

  isCurrent(dayOfMonth: Date) {
    return !!this.currentDate && isSameDate(dayOfMonth, this.currentDate);
  }

  isOtherMonth(dayOfMonth: Date) {
    return this.currentMonth.getMonth() !== dayOfMonth.getMonth();
  }

  isInRange(dayOfMonth: Date) {
    if (!this.rangeValue.start || !this.rangeValue.end) {
      return false;
    }
    return (
      dayOfMonth >= this.convertDateFormatHOT_FIX(this.rangeValue.start) &&
      dayOfMonth <= this.convertDateFormatHOT_FIX(this.rangeValue.end)
    );
  }

  private convertDateFormatHOT_FIX(date: string): Date {
    // dd/MM/yyyy case
    if (this.dateFormat === CalendarDateFormat.ddMMyyyy) {
      let day, month, year;
      day = date?.slice(0, 2);
      month = date?.slice(3, 5);
      year = date?.slice(6, 10);
      return new Date(`${month}/${day}/${year}`);
    }
    return new Date(date);
  }

  isInPreRange(dayOfMonth: Date) {
    if (isValidDate(this.rangeValue.end) || !this.rangeValue.start) {
      return false;
    }

    return dayOfMonth >= this.convertDateFormatHOT_FIX(this.rangeValue.start) && dayOfMonth <= this.dayOnHover;
  }

  isStartOrEndRange(dayOfMonth: Date) {
    return (
      isSameDate(dayOfMonth, this.convertDateFormatHOT_FIX(this.rangeValue.start)) ||
      isSameDate(dayOfMonth, this.convertDateFormatHOT_FIX(this.rangeValue.end))
    );
  }

  onKeydown(event: KeyboardEvent) {
    const dayStepDelta = keyCodesToDaySteps.get(event.keyCode);

    if (dayStepDelta) {
      event.preventDefault();
      const activeDate = addDays(this.activeDate, dayStepDelta);
      this.activeDateChange.emit(activeDate);
    }
  }

  onMonthClick(event: MouseEvent | Event) {
    event.stopPropagation();
    // should be MouseEvent | KeyboardEvent, but $event type for keyup.enter is not inferred correctly
    const target = event.target as HTMLElement;

    if (this.isTimeElement(target)) {
      this.onDateClick(this.convertDateFormatHOT_FIX(target.dateTime));
    }
  }

  addDayFromOtherMonths() {
    this.daysOfMonth = [...this.addDaysFromPreviousMonth(), ...this.daysOfMonth, ...this.addDaysFromNextMonth()];
  }

  setDayOnHover(day: Date) {
    if (!this.isRangePicker) {
      return;
    }

    this.dayOnHover = day;
    this.changeDetectorRef.detectChanges();
  }

  private onDateClick(selectedDate: Date) {
    if (isValidDate(selectedDate)) {
      this.selectDate(selectedDate);
    }
  }

  private selectDate(date: Date) {
    if (!this.isSelected(date) && !this.isDisabled(date)) {
      if (this.isRangePicker) {
        this.showSelectButton
          ? this.selectDateInRangePickerWithoutUpdateForm(date)
          : this.selectDateInRangePicker(date);
      } else {
        this.selectedDateChange.emit(date);
      }
    }
  }

  private selectDateInRangePickerWithoutUpdateForm(date: Date) {
    if (
      !this.rangeValue.start ||
      (!!this.rangeValue.start && !!this.rangeValue.end) ||
      this.isSelectedEndRangeBeforeStartRange(date)
    ) {
      this.rangeValue.start = date;
      this.rangeValue.end = undefined;
    } else if (!this.rangeValue.end) {
      this.rangeValue.end = date;
    } else {
      this.rangeValue.start = date;
    }

    this.changeDetectorRef.detectChanges();
    this.dayPickerService.changeSelectedRange(this.rangeValue);
  }

  private selectDateInRangePicker(date: Date) {
    if (
      !this.rangeValue.start ||
      (!!this.rangeValue.start && !!this.rangeValue.end) ||
      this.isSelectedEndRangeBeforeStartRange(date)
    ) {
      this.startDateInRangeChange.emit(date);
      this.rangeValue.end = undefined;
    } else if (!this.rangeValue.end) {
      this.endDateInRangeChange.emit(date);
    } else {
      this.startDateInRangeChange.emit(date);
    }
  }

  private isSelectedEndRangeBeforeStartRange(date: Date) {
    return date <= this.convertDateFormatHOT_FIX(this.rangeValue.start);
  }

  private isTimeElement(element: HTMLElement): element is HTMLTimeElement {
    return !!element && element.matches(this.dateSelector);
  }

  private addDaysFromNextMonth(): Date[] {
    const currentLastDayIndex = this.daysOfMonth[this.daysOfMonth.length - 1].getDay();
    const nextMonth = setMonth(this.currentMonth, this.currentMonth.getMonth() + 1);
    const nextMonthDays = getDaysOfMonth(nextMonth);
    const daysFromNextMonth = [];

    let indexOfLastDayWeek =
      WeekDay[this.firstDayOfWeek] - 1 === -1 ? NUMBER_OF_DAYS_ON_WEEK : WeekDay[this.firstDayOfWeek] - 1;

    while (indexOfLastDayWeek !== currentLastDayIndex) {
      daysFromNextMonth.push(nextMonthDays.shift());
      if (indexOfLastDayWeek === 0) {
        indexOfLastDayWeek = NUMBER_OF_DAYS_ON_WEEK;
      } else {
        --indexOfLastDayWeek;
      }
    }

    return daysFromNextMonth;
  }

  private addDaysFromPreviousMonth(): Date[] {
    const currentFirstDayIndex = this.daysOfMonth[0].getDay();
    const previousMonth = setMonth(this.currentMonth, this.currentMonth.getMonth() - 1);
    const previousDaysOfMonth = getDaysOfMonth(previousMonth);
    const daysFromPreviousMonth = [];

    let indexOfFirstDayWeek = WeekDay[this.firstDayOfWeek];

    while (indexOfFirstDayWeek !== currentFirstDayIndex) {
      daysFromPreviousMonth.unshift(previousDaysOfMonth.pop());

      if (indexOfFirstDayWeek === NUMBER_OF_DAYS_ON_WEEK) {
        indexOfFirstDayWeek = 0;
      } else {
        ++indexOfFirstDayWeek;
      }
    }

    return daysFromPreviousMonth;
  }
}
