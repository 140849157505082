import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfileState } from '@app/@data/profile/profile-state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RolePrefixService } from '@shared/services/role-prefix.service';
import { Survey } from '@data/survey/survey.model';

@Component({
  selector: 'app-survey-widget',
  templateUrl: './survey-widget.component.html',
  styleUrls: ['./survey-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyWidgetComponent {
  @Select(ProfileState.impersonatedBy) impersonatedBy$: Observable<string>;
  @Input() survey: Survey;
  constructor(private router: Router, private rolePrefixService: RolePrefixService) {}

  onActionButtonClick(): void {
    void this.router.navigate([this.rolePrefixService.transform(this.survey.getUrl())]);
  }
}
