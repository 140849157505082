import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RangeTime } from '../calendar.interface';

@Injectable()
export class DayPickerService {
  private selectedRangeSource = new Subject<RangeTime>();
  selectedRange$ = this.selectedRangeSource.asObservable();
  private selectedRangeSnapshot: any;

  constructor() {}

  changeSelectedRange(range: RangeTime) {
    this.selectedRangeSnapshot = range;
    this.selectedRangeSource.next(range);
  }

  getSelectedRangeSnapshot() {
    return this.selectedRangeSnapshot;
  }
}
