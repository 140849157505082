import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export interface FleetInvoicesStateModel {
  activeTab: ListTabWithColumns | null;
  visibleColumns: string[];
}

export class SetVisibleColumns {
  static readonly type = '[FleetInvoices] Set Visible Columns';
  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[FleetInvoices] Set Active Tab';
  constructor(public tab: ListTabWithColumns) {}
}
