<div class="calendar-only-years">
  <div
    *ngFor="let year of years; let index = index"
    class="year-element"
    [class.year-element--selected]="isSelectedYear(year)"
    [class.year-element--current]="isCurrentYear(year)"
    (click)="selectYear(year, $event)"
  >
    <span class="year-caption"> {{ year }}</span>
  </div>
</div>
