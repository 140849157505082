import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common';

@Component({
  selector: 'wchfs-days-of-week',
  templateUrl: './days-of-week.component.html',
  styleUrls: ['./days-of-week.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaysOfWeekComponent implements OnInit {
  daysOfWeek!: readonly string[];
  narrowDaysOfWeek!: readonly string[];

  private currentLocale?: string;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  @Input()
  get locale() {
    return this.currentLocale;
  }

  set locale(locale: string | undefined) {
    this.currentLocale = locale || this.localeId;
    this.daysOfWeek = this.getDaysOfWeek();
    this.narrowDaysOfWeek = this.getNarrowDaysOfWeek();
  }

  ngOnInit(): void {
    if (!this.locale) {
      this.locale = this.localeId;
    }
  }

  private getDaysOfWeek() {
    return getLocaleDayNames(this.locale, FormStyle.Format, TranslationWidth.Wide);
  }

  private getNarrowDaysOfWeek() {
    return getLocaleDayNames(this.locale, FormStyle.Format, TranslationWidth.Abbreviated);
  }
}
