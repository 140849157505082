import { Id, ReadById } from '@core/http/crud-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import { TyreLogsHistoryResource } from '@data/tyres/tyres.model';

const ENDPOINT = '/logs-history';

export class LogsHistoryService {
  constructor(private httpClient: HttpClient, private endpoint: string, private id: Id) {}

  get(): Observable<DataResource<TyreLogsHistoryResource[]>> {
    return this.httpClient.get<DataResource<TyreLogsHistoryResource[]>>(`${this.endpoint}/${this.id}${ENDPOINT}`);
  }
}
