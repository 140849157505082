import { MetaResource } from '@shared/interfaces/paged-resource';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export interface DispositionsStateModel {
  visibleColumns: string[];
  activeTab: ListTabWithColumns<{ full: string[]; limited: string[] }>;
  tab_slug: string;
}

export class SetActiveRequest {
  static readonly type = '[Dispositions] Set Active Request';

  constructor(public id: number) {}
}

export class UnsetActiveRequest {
  static readonly type = '[Dispositions] Unset Active Request';
}

export class SetVisibleColumns {
  static readonly type = '[Dispositions] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[Dispositions] Set Active Tab';

  constructor(public tab: ListTabWithColumns<{ full: string[]; limited: string[] }>) {}
}

export class SetMeta {
  static readonly type = '[Dispositions] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class ResetDispositionState {
  static readonly type = '[Dispositions] Reset Dispositions State';
}
