import { Id } from '@app/@core/http/crud-model';
import { MetaResource } from '@shared/interfaces/paged-resource';
import { ProductTyreResource } from '@data/product-tyres/product-tyres.model';

export interface ProductTyresStateModel {
  activeProductTyre: ProductTyreResource;
  visibleColumns: string[];
  tab_slug: string;
}

export class SetActiveProductTyre {
  static readonly type = '[Product Tyres] Set Active Product Tyre';

  constructor(public id: Id) {}
}

export class UnsetActiveProductTyre {
  static readonly type = '[Product Tyres] Unset Active Product Tyre';
}

export class SetVisibleColumns {
  static readonly type = '[Product Tyres] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetMeta {
  static readonly type = '[Product Tyres] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class SetTabSlug {
  static readonly type = '[Product Tyres] Set Tab Slug';

  constructor(public slug: string) {}
}

export class ResetProductTyreState {
  static readonly type = '[Product Tyres] Reset ProductTyre State';
}
