import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DotLoaderComponent } from '@pso-ui/dot-loader/dot-loader.component';

@Component({
  selector: 'app-psoa-dot-loader',
  templateUrl: './psoa-dot-loader.component.html',
  styleUrls: ['../dot-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoaDotLoaderComponent extends DotLoaderComponent {}
