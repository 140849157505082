import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { WorkshopGuard } from '@core/guards/workshop.guard';
import { AflGuard } from '@core/guards/afl.guard';
import { PsoaGuard } from '@core/guards/psoa.guard';
import { Roles } from '@core/Roles';
import { HomeRoutingComponent } from '@core/home-routing/home-routing.component';
import { AccountVerificationGuard } from '@core/guards/account-verification.guard';
import { LibraryPagesVisibilityGuard } from '@core/guards/library-pages-visibility.guard';

const routes: Routes = [
  { path: '', component: HomeRoutingComponent, canActivate: [AuthenticationGuard, AccountVerificationGuard] },
  {
    path: Roles.PREFIX_PSOA,
    loadChildren: () => import('./@core/shell/psoa-shell/psoa-shell.module').then((m) => m.PsoaShellModule),
    canActivate: [AuthenticationGuard, AccountVerificationGuard, PsoaGuard],
  },
  {
    path: Roles.PREFIX_AFL,
    loadChildren: () => import('./@core/shell/afl-shell/afl-shell.module').then((m) => m.AflShellModule),
    canActivate: [AuthenticationGuard, AccountVerificationGuard, AflGuard],
  },
  {
    path: Roles.PREFIX_WORKSHOP,
    loadChildren: () => import('./@core/shell/workshop-shell/workshop-shell.module').then((m) => m.WorkshopShellModule),
    canActivate: [AuthenticationGuard, AccountVerificationGuard, WorkshopGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./@pages-and-modals/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: 'library',
    loadChildren: () => import('./@pages-and-modals/library/library.module').then((m) => m.LibraryModule),
    canActivate: [LibraryPagesVisibilityGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
