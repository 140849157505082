import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// TODO: DRY IT OUT
export interface MetaResource {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

@Component({
  selector: 'wchfs-new-paginator',
  templateUrl: './new-paginator.component.html',
  styleUrls: ['./new-paginator.component.scss'],
})
export class NewPaginatorComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  pageForm = new UntypedFormGroup({
    page_number: new UntypedFormControl(1),
  });
  @Input() meta: MetaResource;
  @Input() inputField = false;
  @Input() debounce = 250;
  @Output() page$ = this.pageForm.get('page_number').valueChanges.pipe(
    map((p) => {
      return p < 1 ? 1 : p >= this.maxPages ? this.maxPages : p;
    })
  );
  @ViewChild('nextPage') nextPage: ElementRef;
  @ViewChild('previousPage') previousPage: ElementRef;
  clicker$ = new Subscription();
  clickerCount = 1;
  splitLimit = 10;
  pages: number[];
  maxPages: number;

  constructor() {}

  ngOnInit(): void {
    this.setPages();
  }

  ngAfterViewInit() {
    this.clicker$ = merge(
      fromEvent(this.nextPage.nativeElement, 'click').pipe(
        map(() => (this.clickerCount + 1 <= this.maxPages ? (this.clickerCount += 1) : this.maxPages))
      ),
      fromEvent(this.previousPage.nativeElement, 'click').pipe(
        map(() => (this.clickerCount - 1 > 0 ? (this.clickerCount -= 1) : 1))
      )
    )
      .pipe(
        tap((page_number: number) => {
          this.pageForm.patchValue({ page_number }, { emitEvent: false, onlySelf: true });
          if (this.inputField) return;
          // this.meta.current_page = page_number; // INFO: this breaks store pagination
        }),
        debounceTime(this.debounce),
        untilDestroyed(this)
      )
      .subscribe((page_number) => {
        this.pageForm.patchValue({ page_number });
      });
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    this.meta = changes.meta.currentValue;
    this.setPages();
  }

  setPages() {
    const calculatedMaxPage = Math.ceil(this.meta.total / this.meta.per_page);

    this.maxPages = calculatedMaxPage > 1 ? calculatedMaxPage : 1;
    this.pages = Array.from({ length: this.maxPages }, (_, i) => i + 1);
    this.pageForm.get('page_number').patchValue(this.meta.current_page, { emitEvent: false });
    this.clickerCount = this.meta.current_page;
  }

  setPage(page_number: number) {
    this.pageForm.patchValue({ page_number });
  }

  firstMagicSplit() {
    if (this.meta.current_page < 6) {
      return 0;
    }
    if (this.meta.current_page > this.maxPages - 5) {
      return this.maxPages - 6;
    }
    return this.meta.current_page < 6 ? 0 : this.meta.current_page - 2;
  }

  lastMagicSplit() {
    if (this.meta.current_page < 6) {
      return 6;
    }
    if (this.meta.current_page > this.maxPages - 5) {
      return this.maxPages;
    }
    return this.meta.current_page > this.maxPages - 5 ? this.maxPages - 4 : this.meta.current_page + 1;
  }
}
