import { Injectable } from '@angular/core';
import { Create, Delete, Export, Id, Read } from '@core/http/crud-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ProductTyreRequestPost,
  ProductTyreResource,
  ProductTyresFilterParams,
} from '@data/product-tyres/product-tyres.model';
import { Observable } from 'rxjs';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { DataResource } from '@shared/interfaces/data-resource';
import { SquareBracketsHttpUrlEncodingCodec } from '@core/http/square-brackets-http-url-encoding-codec';
import { MediaResource } from '../media/media.model';
import { DeleteBatch } from '@app/@core/http/crud-model/delete-batch';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { FilterParams } from '../filtering/filtering.interface';
import { prepareGetRequestParams } from '@app/@shared/utils/prepare-get-request-params';

const ENDPOINT = '/product-tyres';
const DEFAULT_WITH = 'notes,producer,product_tyre,current_log,draft_log';

@Injectable({
  providedIn: 'root',
})
export class ProductTyresService
  implements
    Read<ProductTyresFilterParams, ProductTyreResource>,
    ReadById<ProductTyreResource>,
    Create<ProductTyreRequestPost, ProductTyreResource>,
    Delete<ProductTyresFilterParams>,
    DeleteBatch<Id[]>,
    Export<ProductTyresFilterParams, MediaResource> {
  constructor(private httpClient: HttpClient) {}

  get(params: FilterParams): Observable<PagedResource<ProductTyreResource>> {
    return this.httpClient.get<PagedResource<ProductTyreResource>>(`${ENDPOINT}`, {
      params: new HttpParams({
        fromObject: {
          with: DEFAULT_WITH,
          ...params,
        },
        encoder: new SquareBracketsHttpUrlEncodingCodec(),
      }),
    });
  }

  getById(id: Id): Observable<DataResource<ProductTyreResource>> {
    return this.httpClient.get<DataResource<ProductTyreResource>>(`${ENDPOINT}/${id}`, {
      params: {
        with: DEFAULT_WITH,
      },
    });
  }

  create(params: ProductTyreRequestPost): Observable<DataResource<ProductTyreResource>> {
    return this.httpClient.post<DataResource<ProductTyreResource>>(ENDPOINT, params);
  }

  prepareRequest(params: ProductTyresFilterParams): FilterParams {
    // TODO: check with prepareSearchAllRequest()
    const requestParams = prepareGetRequestParams(params);
    delete requestParams['producer'];
    if (params.producer) {
      requestParams['producers[]'] = (params.producer as string).split(',');
    }

    return requestParams;
  }

  prepareSearchAllRequest(
    data: Partial<ProductTyresFilterParams> & { producer: string[] },
    withArchived = false
  ): FilterParams {
    const requestParams = prepareGetRequestParams(data);
    delete requestParams['producer'];
    if (data.producer) {
      requestParams['producers[]'] = data.producer;
    }

    if (withArchived) {
      Object.assign(requestParams, { tab: 'all' });
    }

    return {
      paginate: '0',
      ...requestParams,
    };
  }

  delete(id: Id): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(`${ENDPOINT}/${id}`);
  }

  deleteBatch(ids?: Id[]): Observable<DataResource<null>> {
    return this.httpClient.post<DataResource<null>>(`${ENDPOINT}/delete`, { ids: ids });
  }

  export(params?: ProductTyresFilterParams): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/actions/export`, {
      params: new HttpParams({ fromObject: params }),
    });
  }
}
