import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';

@Injectable({
  providedIn: 'root',
})
export class RolePrefixService {
  constructor(private store: Store) {}

  transform(url: string): string {
    return this.store.selectSnapshot(ProfileState.routeRolePrefixWithSlashes) + url;
  }
}
