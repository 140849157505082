import { FormControl, FormGroup } from '@angular/forms';
import { PaginationParams, SortingParams, TabParams } from '@app/@data/filtering/filtering.interface';
import { InputOptionResource } from '@app/@feed-inputs';

export interface FleetInvoicesFilterParams extends PaginationParams, SortingParams, TabParams {
  vehicles: InputOptionResource;
  dispositions: InputOptionResource;
  fleets: string;
  invoices: string;
  cost_groups: string;
  statuses: string;
  issue_date_from: string;
  issue_date_to: string;
  paid_from: string;
  paid_to: string;
  sale_from: string;
  sale_to: string;
  invoice_number: string;
  registration_number: string;
}

export type FleetInvoiceFilterParamsFormGroup = FormGroup<{
  vehicles: FormControl<InputOptionResource>;
  dispositions: FormControl<InputOptionResource>;
  fleets: FormControl<string[]>;
  invoices: FormControl<string>;
  cost_groups: FormControl<string[]>;
  statuses: FormControl<string>;
  issue_date_from: FormControl<string>;
  issue_date_to: FormControl<string>;
  paid_from: FormControl<string>;
  paid_to: FormControl<string>;
  sale_from: FormControl<string>;
  sale_to: FormControl<string>;
  invoice_number: FormControl<string>;
  registration_number: FormControl<string>;
}>;

export type FleetInvoiceSortSlug = 'asc' | 'desc';

export class FleetInvoiceSort {
  static readonly ASC: FleetInvoiceSortSlug = 'asc';
  static readonly DESC: FleetInvoiceSortSlug = 'desc';
}

export type FleetInvoiceTabSlug = 'f0_registry' | 'f0_corrections' | 'f1_registry';

export class FleetInvoiceTab {
  static F0_REGISTRY: FleetInvoiceTabSlug = 'f0_registry';
  static F0_CORRECTION: FleetInvoiceTabSlug = 'f0_corrections';
  static F1_REGISTRY: FleetInvoiceTabSlug = 'f1_registry';
}

export type FleetInvoicesFilterKey = 'fleet_invoices' | 'fleet_invoices_afl';
