import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, HostListener, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ButtonSize } from '../../button/button.component';
import { Color, Colors, ColorsService } from '../../shared/colors/colors.service';

@Component({
  selector: 'wchfs-expand-button',
  templateUrl: './expand-button.component.html',
  styleUrls: ['./expand-button.component.scss'],
})
export class ExpandButtonComponent {
  private buttonMargin = 16;
  private overlayRef: OverlayRef;
  private positionStrategy: any;
  @Input() text: string;
  @Input() arrowIconColor = 'black';
  @Input() color: Color;
  @Input() size: ButtonSize;
  @Input() textColor: Color = Colors.WHITE;
  @Input() disabled = false;

  @ViewChild('extraButtons', { read: TemplateRef, static: true }) extraButtons: any;
  constructor(
    public elementRef: ElementRef,
    private readonly colorService: ColorsService,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}
  @HostListener('click') openExtraButtons(): void {
    this.initExpandButtonOverlay();
    const portal = new TemplatePortal(this.extraButtons, this._viewContainerRef);
    this.overlayRef.attach(portal);
  }

  initExpandButtonOverlay() {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withLockedPosition(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    const config = new OverlayConfig({
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({ scrollThrottle: 10 }),
      panelClass: 'extra-buttons-box',
      hasBackdrop: true,
      backdropClass: 'extra-buttons-backdrop',
      width: this.elementRef.nativeElement.clientWidth - this.buttonMargin - 3,
    });

    this.overlayRef = this.overlay.create(config);
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
  }
}
