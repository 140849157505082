import { Injectable } from '@angular/core';
import { Delete, Id } from '@core/http/crud-model';
import { DispositionItemResource } from '@data/authorization/authorization.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import {
  DispositionItemBatchPostRequest,
  DispositionItemPatchRequest,
} from '@data/disposition-item/disposition-item.model';
import { SuborderItemService } from '@data/suborder-item/suborder-item.service';

export class DispositionItemService {
  constructor(private httpClient: HttpClient, private endpoint: string, private dispositionId: Id) {}

  patch(dispositionItemId: Id, params: DispositionItemPatchRequest): Observable<DataResource<DispositionItemResource>> {
    return this.httpClient.patch<DataResource<DispositionItemResource>>(
      `${this.endpoint}/${this.dispositionId}/items/${dispositionItemId}`,
      params
    );
  }

  createBatch(params: DispositionItemBatchPostRequest): Observable<DataResource<DispositionItemResource>> {
    return this.httpClient.post<DataResource<DispositionItemResource>>(
      `${this.endpoint}/${this.dispositionId}/items/batch`,
      params
    );
  }

  delete(dispositionItemId: Id): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(
      `${this.endpoint}/${this.dispositionId}/items/${dispositionItemId}`
    );
  }

  suborderItemServiceFactory(dispositionItemId: Id): SuborderItemService {
    return new SuborderItemService(
      this.httpClient,
      `${this.endpoint}/${this.dispositionId}/items/${dispositionItemId}`
    );
  }
}
