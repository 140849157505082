import { TyreLogResource } from '@data/tyres/tyres.model';
import { RimType } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

export class Rim {
  constructor(private tyreLog: TyreLogResource) {}

  getName(): string {
    const name = 'Felga';

    switch (this.tyreLog.rim_type) {
      case RimType.ALUMINIUM: {
        return name + ' Aluminiowa';
      }
      case RimType.STEEL: {
        return name + ' Stalowa';
      }
      default: {
        return name;
      }
    }
  }
}
