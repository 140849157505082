export interface DispositionAddEditMetaStateModel {
  fill_data_overlay: {
    visible: boolean;
    dont_show_in_future: boolean;
    checked: boolean;
  };
}

export class SetFillDataOverlayVisibility {
  static readonly type = '[Disposition Add Edit Meta] Set Fill Data Overlay Visibility';

  constructor(public visible: boolean) {}
}

export class SetFillDataOverlayDontShowInFuture {
  static readonly type = '[Disposition Add Edit Meta] Set Fill Data Overlay Dont Show In Future';

  constructor(public dont_show_in_future: boolean) {}
}

export class SetFillDataOverlayChecked {
  static readonly type = '[Disposition Add Edit Meta] Set Fill Data Overlay Checked';

  constructor(public checked: boolean) {}
}

export class ResetDispositionAddEditMetaState {
  static readonly type = '[Disposition Add Edit Meta] Reset';
}
