import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditReservationDateModalService } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-modal.service';
import { Modal, ModalConfig, ModalType } from '@pso-ui/modal';
import { ModalModule } from '@pso-ui/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { PsoButtonModule } from '@pso-ui/button-pso/pso-button.module';
import { CalendarDateTimePickerModule } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Id } from '@core/http/crud-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignalBoxComponent } from '@pso-ui/signal-box/signal-box.component';
import { EditReservationDateStepOneComponent } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-step-one/edit-reservation-date-step-one.component';
import { EditReservationDateStepTwoComponent } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-step-two/edit-reservation-date-step-two.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-reservation-date-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
    PsoButtonModule,
    CalendarDateTimePickerModule,
    ReactiveFormsModule,
    SignalBoxComponent,
    EditReservationDateStepOneComponent,
    EditReservationDateStepTwoComponent,
  ],
  templateUrl: './edit-reservation-date-modal.component.html',
  styleUrls: ['./edit-reservation-date-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EditReservationDateModalService],
})
export class EditReservationDateComponent implements Modal, OnInit, OnDestroy {
  static CONFIG: ModalConfig = {
    minWidth: '747px',
    maxWidth: '747px',
    maxHeight: '90vh',
  };
  type = ModalType.INFO;
  step = 1;
  subscription = new Subscription();

  constructor(
    public readonly editReservationDateModalService: EditReservationDateModalService,
    private dialog: MatDialogRef<EditReservationDateComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public readonly id: Id
  ) {}

  ngOnInit(): void {
    const id = this?.id;
    if (id) {
      this.editReservationDateModalService.getData(id);
    }
    this.listenMarkForCheck();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  close(shouldRefresh: boolean): void {
    this.dialog.close(shouldRefresh);
  }

  confirm(): void {
    this.editReservationDateModalService.save().subscribe(() => {
      this.dialog.close(true);
    });
  }

  private listenMarkForCheck() {
    return this.editReservationDateModalService.markForCheck$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
