import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { PsoUiModule } from '@app/@pso-ui/pso-ui.module';
import { SwitcherModule } from '@app/@wchfs-ui/lib/form/switcher/switcher.module';
import { WchfsUiModule } from '@app/@wchfs-ui/lib/wchfs-ui.module';
import { RolePrefixPipe } from '@core/role-prefix.pipe';
import { AuthorizationHandlingCellComponent } from '@shared/components/authorization-handling-cell/authorization-handling-cell.component';
import { AuthorizationHandllingCellDateComponent } from '@shared/components/authorization-handling-cell/authorization-handlling-cell-date/authorization-handlling-cell-date.component';
import { AuthorizationHandllingCellRoleComponent } from '@shared/components/authorization-handling-cell/authorization-handlling-cell-role/authorization-handlling-cell-role.component';
import { AuthorizationHandllingCellUserComponent } from '@shared/components/authorization-handling-cell/authorization-handlling-cell-user/authorization-handlling-cell-user.component';
import { DepositsOperationsTableComponent } from '@shared/components/deposits-operations-table/deposits-operations-table.component';
import { FileInputComponent } from '@shared/components/file-input/file-input.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DispositionMultiSelectComponent } from './components/disposition/disposition-multi-select/disposition-multi-select.component';
import { ImpersonatingAsWidgetComponent } from './components/impersonating-as-widget/impersonating-as-widget.component';
import { InvoiceSingleSelectComponent } from './components/invoice/invoice-single-select/invoice-single-select.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserInitialsComponent } from './components/user-initials/user-initials.component';
import { VehicleMultiSelectComponent } from './components/vehicle/vehicle-multi-select/vehicle-multi-select.component';
import { AuthForGroupPipe } from './utils/auth-for-group.pipe';
import { CountingHoursLeftPipe } from './utils/counting-hours-left.pipe';
import { DateToDaysNameConvertionPipe } from './utils/date-to-days-name-convertion.pipe';
import { DaysFromTodayPipe } from './utils/days-from-today.pipe';
import { HoursBetweenNowAndDatePipe } from './utils/hours-between-now-and-date.pipe';
import { CoutingTimeFromDate } from './utils/time-between-two-dates.pipe';
import { TyreStatePipe } from './utils/tyre-state.pipe';
import { VehicleRegistrationNumberAndVinComponent } from './components/vehicle-registration-number-and-vin/vehicle-registration-number-and-vin.component';
import { SurveyWidgetComponent } from '@shared/components/survey-widget/survey-widget.component';
import { NotesComponent } from '@shared/components/notes/notes.component';
import { ReportsFiltersSectionComponent } from '@pages-and-modals/reports/shared/reports-filters-section/reports-filters-section.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, WchfsUiModule, FlexModule, RouterModule, MatDialogModule, SwitcherModule, PsoUiModule],
  declarations: [
    LoaderComponent,
    FileInputComponent,
    ConfirmDialogComponent,
    RolePrefixPipe,
    DaysFromTodayPipe,
    DateToDaysNameConvertionPipe,
    CountingHoursLeftPipe,
    AuthForGroupPipe,
    TyreStatePipe,
    DepositsOperationsTableComponent,
    HoursBetweenNowAndDatePipe,
    CoutingTimeFromDate,
    UserInitialsComponent,
    AuthorizationHandlingCellComponent,
    AuthorizationHandllingCellDateComponent,
    AuthorizationHandllingCellRoleComponent,
    AuthorizationHandllingCellUserComponent,
    VehicleMultiSelectComponent,
    DispositionMultiSelectComponent,
    ImpersonatingAsWidgetComponent,
    InvoiceSingleSelectComponent,
    VehicleRegistrationNumberAndVinComponent,
    SurveyWidgetComponent,
    NotesComponent,
    ReportsFiltersSectionComponent,
  ],
  exports: [
    CommonModule,
    WchfsUiModule,
    PsoUiModule,
    LoaderComponent,
    LoaderComponent,
    FileInputComponent,
    ConfirmDialogComponent,
    RolePrefixPipe,
    DaysFromTodayPipe,
    DateToDaysNameConvertionPipe,
    CountingHoursLeftPipe,
    AuthForGroupPipe,
    TyreStatePipe,
    DepositsOperationsTableComponent,
    HoursBetweenNowAndDatePipe,
    CoutingTimeFromDate,
    UserInitialsComponent,
    AuthorizationHandlingCellComponent,
    AuthorizationHandllingCellDateComponent,
    AuthorizationHandllingCellRoleComponent,
    AuthorizationHandllingCellUserComponent,
    VehicleMultiSelectComponent,
    DispositionMultiSelectComponent,
    ImpersonatingAsWidgetComponent,
    InvoiceSingleSelectComponent,
    VehicleRegistrationNumberAndVinComponent,
    SurveyWidgetComponent,
    NotesComponent,
    ReportsFiltersSectionComponent,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class SharedModule {}
