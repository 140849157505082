import { Injectable } from '@angular/core';
import Echo, { Channel } from 'laravel-echo';
import { environment } from '@env/environment';
import { SocketIoPresenceChannel } from 'laravel-echo/dist/channel';
import { Model, ModelFactory } from '@angular-extensions/model';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';
import { ChannelType, EventToListen, OnlineUser } from '@core/websockets/echo.model';

@Injectable({
  providedIn: 'root',
})
export class EchoService {
  static EVENTS_TO_LISTEN: EventToListen[] = [];

  echo: Echo;

  private onlineUsersModel: Model<OnlineUser[]>;
  public onlineUsers$: Observable<OnlineUser[]>;

  constructor(private onlineUsersModelFactory: ModelFactory<OnlineUser[]>, private store: Store) {
    this.onlineUsersModel = onlineUsersModelFactory.create(null);
    this.onlineUsers$ = this.onlineUsersModel.data$;
  }

  connect(): void | undefined {
    this.store.selectSnapshot((state) => {
      const accessToken = state?.auth?.access_token;
      const profileId = this.store.selectSnapshot(ProfileState.profileId);
      const impersonatingAs = state?.auth?.impersonating_as;

      if (!accessToken || !profileId) {
        return;
      }

      if (this.echo) {
        this.disconnect();
      }

      const { pusher } = environment;

      let headers = { Authorization: `Bearer ${accessToken}` };
      if (impersonatingAs) {
        headers = {
          ...headers,
          ...{
            'X-Impersonating-As': impersonatingAs,
          },
        };
      }

      this.echo = new Echo({
        wsHost: pusher.host,
        wssHost: pusher.host,
        wsPort: pusher.port,
        wssPort: pusher.port,
        cluster: pusher.cluster,
        forceTLS: true,
        enabledTransports: ['ws', 'wss'],
        auth: accessToken ? { headers: headers } : null,
        key: pusher.key,
        broadcaster: 'pusher', // INFO: remember about adding "./node_modules/pusher-js/dist/web/pusher.min.js" in angular.json file
        encrypted: true,
        authEndpoint: pusher.authEndpoint,
      });

      this.echo.connect();
    });
    this.listenEvents();
    this.joinOnlineUsers();
  }

  listen(eventToListen: EventToListen, callback: (element: any) => void): void {
    this.addEventToListen(eventToListen, callback);

    const channel: any = this.joinChannel(eventToListen.channel.name, eventToListen.channel.type);

    channel.listen('.' + eventToListen.event, callback);
  }

  joinChannel(channel: string, type: ChannelType): Channel {
    switch (type) {
      case ChannelType.PRIVATE:
        return this.echo.private(channel);
      case ChannelType.PUBLIC:
        return this.echo.channel(channel);
      case ChannelType.PRESENCE:
        return this.echo.join(channel);
    }
  }

  disconnect(): void {
    this.echo?.disconnect();
  }

  private addEventToListen(eventToListen: EventToListen, callback: (element: any) => void) {
    const find: EventToListen = EchoService.EVENTS_TO_LISTEN.find(
      (el) => el.event === eventToListen.event && el.channel === eventToListen.channel
    );
    if (find) {
      return;
    }

    eventToListen.callback = callback;
    EchoService.EVENTS_TO_LISTEN.push({ ...eventToListen });
  }

  private listenEvents() {
    for (const eventToListen of EchoService.EVENTS_TO_LISTEN) {
      this.listen(eventToListen, eventToListen.callback);
    }
  }

  private joinOnlineUsers() {
    const channel = this.joinChannel('online', ChannelType.PRESENCE) as SocketIoPresenceChannel;

    const closure = (user: OnlineUser, isJoining: boolean) => {
      let users = this.onlineUsersModel.get();

      users = users.filter((modelUser: OnlineUser) => modelUser.id !== user.id);

      if (isJoining) {
        users.push(user);
      }

      this.onlineUsersModel.set(users);
    };

    channel
      .here((users: OnlineUser[]) => {
        this.onlineUsersModel.set(users);
      })
      .joining((user: OnlineUser) => {
        closure(user, true);
      })
      .leaving((user: OnlineUser) => {
        closure(user, false);
      });
  }

  private log(type: any, data: any) {
    if (environment.logWebsocketEvents) {
      console.log(`%c${type}`, 'background:#9012fe;color:#fff;', data);
    }
  }
}
