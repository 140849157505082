<div class="calendar-actions">
  <ng-container *ngIf="!isRangePicker">
    <button (click)="selectToday()" color="white" size="small" wchfs-flat-button-dp>Today</button>
    <button
      (click)="selectDate()"
      [disabled]="!value"
      class="select-button"
      color="primary"
      size="small"
      wchfs-flat-button-dp
    >
      Select
    </button>
  </ng-container>
  <ng-container *ngIf="isRangePicker">
    <button (click)="cancelRange()" color="white" size="small" wchfs-flat-button-dp>Cancel</button>
    <button
      (click)="selectRange()"
      [disabled]="!(selectedRange$ | async)?.end"
      class="select-range-button"
      color="primary"
      size="small"
      wchfs-flat-button-dp
    >
      Select
    </button>
  </ng-container>
</div>
