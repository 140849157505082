import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { BadgeComponent } from './badge/badge.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { ChipComponent } from './chip/chip.component';
import { ControlErrorComponent } from './form-error-messages/control-error/control-error.component';
import { ControlErrorContainerDirective } from './form-error-messages/directives/control-error-container.directive';
import { ControlErrorsDirective } from './form-error-messages/directives/control-errors.directive';
import { FormSubmitDirective } from './form-error-messages/directives/form-submit.directive';
import { FormFieldComponent } from './form-field/form-field.component';
import { Hint } from './form-field/hint';
import { Label } from './form-field/label';
import { ValidationError } from './form-field/validation-error';
import { FilterDateDirective } from './input/filter-date-directive/filter-date.directive';
import { WchfsInputHintDirective } from './input/hint-directive/input-hint.directive';
import { WchfsInputDirective } from './input/input.directive';
import { WchfsPasswordIconComponent } from './input/wchfs-password-icon/wchfs-password-icon.component';
import { OptionGroupHeaderComponent } from './option-group/option-group-header.component';
import { OptionGroupComponent } from './option-group/option-group.component';
import { OptionPrefixComponent } from './option/option-prefix.component';
import { OptionSuffixComponent } from './option/option-suffix.component';
import { OptionComponent } from './option/option.component';
import { RadioComponent } from './radio/radio.component';
import { RangeComponent } from './range/range.component';
import { SearchFormMoreComponent } from './search-form-more/search-form-more.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { DropdownComponent } from './select/dropdown.component';
import { SelectComponent } from './select/select.component';
import { SwitcherModule } from '@app/@wchfs-ui/lib/form/switcher/switcher.module';

const components = [
  FormFieldComponent,
  SearchInputComponent,
  SearchFormComponent,
  CheckboxComponent,
  BadgeComponent,
  ChipComponent,
  ChipListComponent,
  ControlErrorComponent,
  RadioComponent,
  SelectComponent,
  DropdownComponent,
  RangeComponent,
  OptionComponent,
  OptionPrefixComponent,
  OptionSuffixComponent,
  OptionGroupHeaderComponent,
  OptionGroupComponent,
  SearchFormMoreComponent,
  WchfsPasswordIconComponent,
];

const directives = [
  ValidationError,
  Label,
  Hint,
  FormSubmitDirective,
  ControlErrorsDirective,
  ControlErrorContainerDirective,
  WchfsInputDirective,
  WchfsInputHintDirective,
  FilterDateDirective,
];

@NgModule({
  declarations: [...components, ...directives],
  imports: [CommonModule, SvgIconModule, PortalModule, SwitcherModule],
  exports: [...components, ...directives, SwitcherModule],
})
export class FormModule {}
