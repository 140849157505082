import { Pipe, PipeTransform } from '@angular/core';
import { ColumnData } from '../draggable-table.component';

@Pipe({
  name: 'columnSearch',
})
export class ColumnSearchPipe implements PipeTransform {
  transform(
    data: ColumnData[],
    searchTerm: string,
    orginalDataLength: number,
    customSearchKeyValue: string = null
  ): ColumnData[] {
    if (searchTerm == null) {
      return data;
    }
    if (searchTerm.length === 0) {
      return data;
    }
    let results = [];
    if (customSearchKeyValue) {
      results = data.filter((item) => {
        return item[customSearchKeyValue].toLowerCase().includes(searchTerm.toLowerCase());
      });
    } else {
      results = data.filter((item) => {
        if ('label' in item) {
          return item.label.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return item.value.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    return results;
  }
}
