import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItem } from './select-item';
import { BaseSelectComponent } from './base-select.component';
import { SelectDropdownService } from '../dropdown/select-dropdown.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-base-single-select',
  templateUrl: './base-select.component.html',
  styleUrls: ['./base-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseSingleSelectComponent),
      multi: true,
    },
    SelectDropdownService,
  ],
})
export class BaseSingleSelectComponent extends BaseSelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = this.translateService.instant('select');

  value: unknown;

  writeValue(value: SelectItem<unknown> | number | string | object): void | undefined {
    if (this.value == value) {
      return; // prevent value changes when same value
    }

    const [item] = this.items.filter((item: SelectItem<unknown>) => {
      return item.is(value);
    });

    if (item) {
      this.displayValue = item.getLabel();
      this.value = item.getValue();
      this.onChangeFn(this.value);
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.displayValue = '';
    this.value = null;
    this.onChangeFn(this.value);
    this.changeDetectorRef.detectChanges();
  }

  openDropdown(): void {
    super.openDropdown((item) => {
      this.onItemClick(item);
    });
  }

  onItemClick(item: SelectItem<unknown>): void {
    if (item.is(this.value)) {
      return; // prevent value changes when same value
    }
    this.displayValue = item.getLabel();
    this.value = item.getValue();
    this.onChangeFn(this.value);
    this.changeDetectorRef.detectChanges();
    this.selectDropdownService.close();
  }

  onXIconClick(): void {
    this.writeValue(null);
  }
}
