export const ROUTES = {
  DESKTOP: {
    module: 'desktop',
    path: 'desktop',
    translate: 'routes.desktop',
  },
  LOGIN: {
    module: '',
    path: 'auth/login',
    translate: '',
  },
  USER_VERIFY: {
    module: '',
    path: 'auth/user-verify',
    translate: '',
  },
  AUTHORIZATION: {
    module: 'authorization',
    path: 'authorization',
    translate: 'routes.authorization',
  },
  AUTHORIZATION_DETAILS: {
    module: 'authorization/:id',
    path: 'authorization/ID', // replace ID with database id
    translate: '',
  },
  ORDERS: {
    module: 'orders',
    path: 'orders',
    translate: 'routes.orders',
  },
  ORDERS_LIST: {
    module: 'orders/list',
    path: 'orders/list',
    translate: 'routes.orders-list',
  },
  MAKE_ORDERS: {
    module: 'orders/make-orders',
    path: 'orders/make-orders',
    translate: 'routes.make-orders',
  },
  ORDERS_WITH_RESERVATION: {
    module: 'orders/orders-with-reservation',
    path: 'orders/orders-with-reservation',
    translate: 'routes.orders-with-reservation',
  },
  ORDERS_WITH_RESERVATION_IMPORT: {
    module: 'orders/orders-with-reservation/import',
    path: '/orders/orders-with-reservation/import/step-1',
    translate: 'routes.orders-with-reservation-import',
  },
  ORDERS_WITH_DELIVERY: {
    module: 'orders/orders-with-delivery',
    path: 'orders/orders-with-delivery',
    translate: 'routes.orders-with-delivery',
  },
  ORDERS_WITH_RECEPTION: {
    module: 'orders/orders-with-reception',
    path: 'orders/orders-with-reception',
    translate: 'routes.orders-with-reception',
  },
  LIST_OF_SUPPLIERS: {
    module: 'orders/list-of-suppliers',
    path: 'orders/list-of-suppliers',
    translate: 'routes.list-of-suppliers',
  },
  STOCK_LEVELS: {
    module: 'orders/stock-levels',
    path: 'orders/stock-levels',
    translate: 'routes.stock-levels',
  },
  DOOR_TO_DOOR: {
    module: 'door-to-door',
    path: 'door-to-door',
    translate: 'routes.door-to-door',
  },
  DOOR_TO_DOOR_ORDER: {
    module: 'door-to-door/order',
    path: 'door-to-door/order',
    translate: 'routes.door-to-door-order',
  },
  DOOR_TO_DOOR_LIST: {
    module: 'door-to-door/list',
    path: 'door-to-door/list',
    translate: 'routes.door-to-door-list',
  },
  INVOICES: {
    module: 'invoices',
    path: 'invoices',
    translate: 'routes.invoices',
  },
  INVOICES_FLEETS: {
    module: 'invoices',
    path: 'invoices/fleet',
    translate: 'routes.invoices-fleet',
  },
  INVOICES_FLEETS_EDIT: {
    module: 'invoices/fleet/:id/edit',
    path: 'invoices/fleet/item/ID/MODE', // replace ID with database id
    translate: 'routes.fleets-invoice-edit',
  },
  INVOICES_WORKSHOPS: {
    module: 'invoices',
    path: 'invoices/workshops',
    translate: 'routes.invoices-workshops',
  },
  INVOICES_PANEL: {
    module: 'invoices',
    path: 'invoices/panel',
    translate: 'routes.invoices-panel',
  },
  INVOICES_PANEL_NEW: {
    module: 'invoices',
    path: 'invoices/panel/new',
    translate: 'routes.new-invoice',
  },
  BILLING_AND_INVOICES: {
    module: 'estimates',
    path: 'invoices/workshops',
    translate: 'routes.billing-and-invoices',
  },
  BILLING_AND_INVOICES_WORKSHOP: {
    module: 'estimates',
    path: 'estimates',
    translate: 'routes.billing-and-invoices',
  },
  BILLING_AND_INVOICES_ID: {
    module: 'estimates/:id',
    path: 'estimates/ID',
    translate: 'routes.billing-and-invoices',
  },
  BILLING_AND_INVOICES_PSOA_ID: {
    module: 'invoices/workshops/:id',
    path: 'invoices/workshops/ID',
    translate: 'routes.billing-and-invoices',
  },
  REPORTS: {
    module: 'reports',
    path: 'reports',
    translate: 'routes.reports',
  },
  REPORTS_REPORTS: {
    module: 'reports',
    path: 'reports/reports',
    translate: 'routes.reports',
  },
  REPORTS_STATS: {
    module: 'stats',
    path: 'reports/stats',
    translate: 'routes.stats',
  },
  RESERVATIONS: {
    module: 'reservations',
    path: 'reservations',
    translate: 'routes.visit-reservations',
  },
  RESERVATION_NOT_TAKEN: {
    module: 'reservations-not-taken',
    path: 'reservations/not-taken',
    translate: 'routes.not-taken-reservations',
  },
  RESERVATION_APPROVED: {
    module: 'reservations-approved',
    path: 'reservations/approved',
    translate: 'routes.approved-reservations',
  },
  RESERVATION_ONLINE: {
    module: 'reservations-online',
    path: 'reservations/online',
    translate: 'routes.visit-reservations',
  },
  RESERVATION_OFFLINE_NOT_TAKEN: {
    module: 'reservations-not-taken',
    path: 'reservations/offline/not-taken',
    translate: 'routes.not-taken-reservations',
  },
  RESERVATION_OFFLINE_APPROVED: {
    module: 'reservations-approved',
    path: 'reservations/offline/approved',
    translate: 'routes.approved-reservations',
  },
  REQUESTS: {
    module: 'requests',
    path: 'requests',
    translate: 'routes.requests',
  },
  REQUEST_ADD_EDIT: {
    module: 'request-add-edit',
    path: 'requests/add-edit',
  },
  REQUEST_ADD_EDIT_STEP_1: {
    module: 'request-add-edit',
    path: 'requests/add-edit/vehicle-and-pre-request-selection',
    translate: 'requests.vehicle-and-order-selection',
  },
  REQUEST_ADD_EDIT_STEP_2: {
    module: 'request-add-edit',
    path: 'requests/add-edit/vehicle-data',
    translate: 'requests.completion-of-vehicle-data-and-confirmation-of-the-request',
  },
  REQUESTS_IMPORT: {
    module: 'requests/import',
    path: 'requests/import/step-1',
    translate: 'routes.requests-zam-import',
  },
  DISPOSITION_ADD_EDIT_STEP_3_SUMMARY: {
    module: 'request-add-edit',
    path: 'requests/add-edit/summary',
    translate: 'requests.authorizations-and-summary',
  },
  DISPOSITION_ADD_EDIT_STEP_3_AUTHORIZATION: {
    module: 'request-add-edit',
    path: 'requests/add-edit/authorization',
    translate: 'requests.authorizations-and-summary',
  },
  DISPOSITION_ADD_EDIT_STEP_3_FINALIZED: {
    module: 'request-add-edit',
    path: 'requests/add-edit/finalized',
    translate: 'requests.authorizations-and-summary',
  },
  // LEGACY STEP 3:
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITHOUT_AUTHORIZATION: {
    module: 'request-add-edit',
    path: 'requests/add-edit/summary-without-authorization',
    translate: 'requests.authorizations-and-summary',
  },
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITHOUT_AUTHORIZATION_FINALIZED: {
    module: 'request-add-edit',
    path: 'requests/add-edit/request-without-authorization/finalized',
    translate: 'requests.authorizations-and-summary',
  },
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITH_AUTHORIZATION: {
    module: 'request-add-edit',
    path: 'requests/add-edit/request-with-authorization',
    translate: 'requests.authorizations-and-summary',
  },
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITH_AUTHORIZATION_WAITING_FOR_AUTHORIZATION: {
    module: 'request-add-edit',
    path: 'requests/add-edit/request-with-authorization/waiting-for-authorization',
    translate: 'requests.authorizations-and-summary',
  },
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITH_AUTHORIZATION_AUTHORIZATION_APPROVED: {
    module: 'request-add-edit',
    path: 'requests/add-edit/request-with-authorization/authorization-approved',
    translate: 'requests.authorizations-and-summary',
  },
  REQUEST_ADD_EDIT_STEP_3_LEGACY_WITH_AUTHORIZATION_FINALIZED: {
    module: 'request-add-edit',
    path: 'requests/add-edit/request-with-authorization/finalized',
    translate: 'requests.authorizations-and-summary',
  },
  NEW_REQUESTS: {
    module: 'request-add-edit',
    path: 'requests/add-edit/vehicle-and-pre-request-selection',
    translate: 'requests.new-request',
  },
  REQUESTS_LIST: {
    module: 'requests',
    path: 'requests',
    translate: 'requests.request-list',
  },
  RELOCATIONS: {
    module: 'relocations',
    path: 'relocations',
    translate: 'routes.relocations',
  },
  RELOCATION_DETAILS: {
    module: 'relocations:id',
    path: 'relocations/ID',
    translate: 'routes.relocation-disposition-number',
  },
  RELOCATIONS_IMPORT: {
    module: 'relocations/import',
    path: '/relocations/import/step-1',
    translate: 'routes.relocations-import',
  },
  DEPOSITS: {
    module: 'deposits',
    path: 'deposits',
    translate: 'routes.deposits',
  },
  DEPOSITS_LIST: {
    module: 'deposits',
    path: 'deposits',
    translate: 'deposits-list',
  },
  DEPOSIT_DETAILS: {
    module: 'deposits:id',
    path: 'deposits/edit/ID',
    translate: 'routes.deposit',
  },
  DEPOSITS_TYRES_UTILIZATION_IMPORT: {
    module: 'deposits/tyres-utilization/import',
    path: 'deposits/tyres-utilization/import/step-1',
    translate: 'routes.tyres-utilization-import',
  },
  DEPOSITS_TYRES_RELEASE_IMPORT: {
    module: 'deposits/release-tyres/import',
    path: 'deposits/release-tyres/import/step-1',
    translate: 'routes.release-tyres-import',
  },
  RELEASE_ACTIONS: {
    module: 'release-actions',
    path: 'deposits/release-actions',
    translate: 'release-actions',
  },
  USERS: {
    module: 'users',
    path: 'users',
    translate: 'routes.users',
  },
  USERS_LIST: {
    module: 'users',
    path: 'users',
    translate: 'routes.users-list',
  },
  USERS_EDIT: {
    module: 'users/edits/:id',
    path: 'users/edits/ID', // replace ID with database id
    translate: '',
  },
  USERS_ROLES: {
    module: 'users/roles-group',
    path: 'users/roles-group',
    translate: 'routes.roles-group',
  },
  USERS_ROLES_EDIT: {
    module: 'users/roles-group/edit/:id',
    path: 'users/roles-group/edit/ID', // replace ID with database id
    translate: '',
  },
  USERS_NEW: {
    module: 'users/edit/new',
    path: 'users/edit/new',
    translate: 'routes.user-new',
  },
  FLEETS: {
    module: 'fleets',
    path: 'fleets',
    translate: 'routes.fleets',
  },
  FLEETS_LIST: {
    module: 'fleets',
    path: 'fleets',
    translate: 'routes.fleets-list',
  },
  FLEETS_EDIT: {
    module: 'fleets/edit/:id',
    path: 'fleets/edit/ID', // replace ID with database id
    translate: 'routes.fleets-edit',
  },
  FLEET_VEHICLES_LIST: {
    module: 'fleets/vehicles/list',
    path: 'fleets/vehicles/list',
    translate: 'routes.vehicles-list',
  },
  FLEET_VEHICLES_IMPORT: {
    module: 'fleets/vehicles/import',
    path: '/fleets/vehicles/import/step-1',
    translate: 'routes.vehicles-import',
  },
  FLEET_VEHICLES_EDIT: {
    module: 'vehicles/edit/:id',
    path: 'fleets/vehicles/list/edit/ID', // replace ID with database id
    translate: 'routes.vehicles-edit',
  },
  VEHICLES: {
    module: 'vehicles',
    path: 'vehicles',
    translate: 'vehicles',
  },
  VEHICLES_EDIT_AFL: {
    // TODO: compare PSOA and AFL routes and refactor
    module: 'vehicles/edit/:id',
    path: '/vehicles/edit/ID', // replace ID with database id
    translate: 'routes.vehicles-edit',
  },
  VEHICLES_IMPORT: {
    module: 'vehicles/import',
    path: 'vehicles/import/step-1',
    translate: 'routes.vehicles-import',
  },
  FLEET_DISCOUNTS: {
    module: 'fleets/discounts',
    path: 'fleets/discounts',
    translate: 'routes.discounts',
  },
  PRODUCTS_AND_SERVICES: {
    module: 'products-and-services',
    path: 'products-and-services',
    translate: 'routes.products-and-services',
  },
  PRODUCTS_AND_SERVICES_LIST: {
    module: 'products-and-services',
    path: 'products-and-services',
    translate: 'routes.products-and-services-list',
  },
  PRODUCTS_AND_SERVICES_LIST_TIRES: {
    module: 'products-and-services',
    path: '/products-and-services',
    translate: 'routes.products-and-services-list-tires',
  },
  PRODUCTS_AND_SERVICES_IMPORT: {
    module: 'products-and-services/import',
    path: 'products-and-services/import/step-1',
    translate: 'routes.products-and-services-import',
  },
  PRODUCTS_AND_SERVICES_IMPORT_TIRES: {
    module: 'products-and-services/import',
    path: 'products-and-services/import/step-1',
    translate: 'routes.products-and-services-import-tires',
  },
  PRODUCTS_AND_SERVICES_IMPORT_STEP_1: {
    module: 'products-and-services/import',
    path: 'products-and-services/import/step-1',
    translate: 'routes.products-and-services-import',
  },
  PRODUCTS_AND_SERVICES_SERVICES: {
    module: 'services',
    path: 'products-and-services/services',
    translate: 'routes.products-and-services-services',
  },
  OTHER_PRODUCTS_AND_SERVICES_SERVICES: {
    module: 'services',
    path: '/products-and-services/services',
    translate: 'routes.other-products-and-services',
  },
  PRODUCTS_AND_SERVICES_PRODUCERS: {
    module: 'services',
    path: 'products-and-services/producers',
    translate: 'routes.products-and-services-producers',
  },
  PRODUCTS_AND_SERVICES_UTILIZATION_REASONS: {
    module: 'services',
    path: 'products-and-services/utilization-reasons',
    translate: 'routes.products-and-services-utilization-reasons',
  },
  PRODUCTS_AND_SERVICES_TYRE_CATALOG: {
    module: 'services',
    path: 'products-and-services',
    translate: 'routes.products-and-services-tyre-catalogue',
  },
  WORKSHOPS: {
    module: 'workshops',
    path: 'workshops',
    translate: 'routes.workshops',
  },
  WORKSHOPS_LIST: {
    module: 'workshops/list',
    path: 'workshops/list',
    translate: 'routes.workshops-list',
  },
  WORKSHOPS_EDIT: {
    module: 'workshops/edit/:id',
    path: 'workshops/edit/ID', // replace ID with database id
    translate: 'routes.fleets-edit',
  },
  WORKSHOPS_DISCOUNTS: {
    module: 'workshops/discounts',
    path: 'workshops/discounts',
    translate: 'routes.discounts',
  },
  RECIPIENTS: {
    module: 'recipients',
    path: 'recipients',
    translate: 'routes.recipients',
  },
  RECIPIENT_DETAILS: {
    module: 'recipients:id',
    path: 'recipients/ID',
    translate: 'routes.recipients',
  },
  COMMUNICATION: {
    module: 'messenger',
    path: 'messenger',
    translate: 'routes.communication',
  },
  COMMUNICATOR: {
    module: 'messenger/communicator',
    path: 'messenger/communicator',
    translate: 'routes.communicator',
  },
  MESSANGER: {
    module: 'messenger/messages',
    path: 'messenger/messages',
    translate: 'routes.messages',
  },
  FILES: {
    module: 'messenger/files',
    path: 'messenger/files',
    translate: 'routes.files',
  },
  NOTIFICATIONS: {
    module: 'messenger/notifications',
    path: 'messenger/notifications',
    translate: 'routes.notifications',
  },
  SYSTEM_NOTIFICATIONS: {
    module: 'messenger/system-notifications',
    path: 'messenger/system-notifications',
    translate: 'routes.system-notifications',
  },
  SYSTEM_NOTIFICATIONS_DETAILS: {
    module: 'messenger/system-notifications:id',
    path: '/messenger/system-notifications/ID',
    translate: 'routes.system-notifications-number',
  },
  ZAM: {
    module: '',
    path: 'https://zam.pso.pl/admin/login/index',
    translate: 'zam',
  },
  ZAM_NEW_ORDER: {
    modlue: '',
    path: 'https://zam.staging.wchfs.pl/admin/orders/new/?code=ID', // replace ID with database id
    translate: 'order-in-zam',
  },
  DASHBOARD: {
    module: '',
    path: 'dashboard',
    translate: 'dashboard',
  },
};

export enum ROUTE_MODES {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  READ = 'READ',
}
