<app-modal-header (iconClicked)="close(false)">Zmiana terminu rezerwacji ({{ step }} z 2)</app-modal-header>

<app-edit-reservation-date-step-one
  *ngIf="step === 1"
  (confirmButtonClicked)="step = 2"
  (closeButtonClicked)="close(false)"
></app-edit-reservation-date-step-one>
<app-edit-reservation-date-step-two
  *ngIf="step === 2"
  (confirmButtonClicked)="confirm()"
  (closeButtonClicked)="close(false)"
></app-edit-reservation-date-step-two>
