export interface AuthStateModel {
  token_type: 'Bearer' | null;
  expires_in: number | null;
  access_token: string | null;
  refresh_token: string | null;
  impersonating_as: string | null;
}

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: { username: string; password: string }) {}
}

export class UpdateCredentials {
  static readonly type = '[Auth] Update Credentials';
  constructor(public data: AuthStateModel) {}
}

export class SetImpersonatingAs {
  static readonly type = '[Auth] Set Impersonating As';
  constructor(public impersonatingAs: string) {}
}

export class ClearCredentials {
  static readonly type = '[Auth] Clear Credentials';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
