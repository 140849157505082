export enum CalendarTypes {
  days = 'days',
  onlyMonths = 'onlyMonths',
  onlyYears = 'onlyYears',
}

export const DEFAULT_CALENDAR_TYPE = CalendarTypes.days;
export const NUMBER_OF_YEARS_ON_PAGE = 12;
export const NUMBER_OF_DAYS_ON_WEEK = 6;

export enum CalendarDateFormat {
  'yyyyMMdd' = 'yyyy/MM/dd',
  'ddMMyyyy' = 'dd/MM/yyyy',
}
