import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UiStorageService } from '../services/ui-storage.service';

@Component({
  selector: 'wchfs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableComponent implements OnInit {
  @Input() dateSource: any[] = [];
  @Input() displayedColumns: any[] = [];
  @Input() showTableMenuButton = false;
  @Input() tableName: string;
  @Output() onColumnListChange = new EventEmitter();

  @ViewChild('columnListBody') columnListRef: TemplateRef<any>;
  @ViewChild('tableMenuButton') tableMenuButtonRef: HTMLElement;

  columnList: FormGroup = new FormGroup({});
  overlayRef: OverlayRef | null;
  selection = new SelectionModel<Element>(true, []);
  visibileColumns: any[];

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private uiStorageService: UiStorageService
  ) {}

  ngOnInit(): void {
    if (this.showTableMenuButton) {
      this.generateFormGroupForColumnList(this.displayedColumns);
      this.listenForSearchChanges();
    }
  }

  open(): void {
    const configs = this.createOverlayConfig();
    this.overlayRef = this.overlay.create(configs);
    this.overlayRef.attach(new TemplatePortal(this.columnListRef, this.viewContainerRef));
    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.overlayRef.dispose());
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dateSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dateSource.forEach((row) => this.selection.select(row));
  }

  private generateFormGroupForColumnList(columnsList: string[]): void {
    const columns = [...columnsList];
    const storageColumns = this.uiStorageService.getTableColumnCasheList(this.tableName);

    this.removeSelectColumnFromColumnList(columns);

    columns.forEach((column) => {
      if (storageColumns?.indexOf(column) === -1) {
        this.columnList.addControl(column, new FormControl(false));
      }
      this.columnList.addControl(column, new FormControl(true));
    });

    setTimeout(() => {
      if (storageColumns) {
        this.filterVisibleColumn();
      }
    });
  }

  private removeSelectColumnFromColumnList(columns: string[]): void {
    if (this.displayedColumns.indexOf('select') !== -1) {
      columns.shift();
    }
  }

  private addSelectColumnFromColumnList(columns: string[]): void {
    if (this.displayedColumns.indexOf('select') !== -1) {
      columns.unshift('select');
    }
  }

  private listenForSearchChanges(): void {
    this.columnList.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.filterVisibleColumn();
      this.uiStorageService.setTableColumnCasheList(this.tableName, this.visibileColumns);
    });
  }

  private filterVisibleColumn(): void {
    this.visibileColumns = [];
    for (const property in this.columnList.controls) {
      if (this.columnList.controls[property].value) {
        this.visibileColumns.push(property);
      }
    }
    this.addSelectColumnFromColumnList(this.visibileColumns);
    this.onColumnListChange.emit(this.visibileColumns);
  }

  private createOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.tableMenuButtonRef)
      .withPush(false)
      .withDefaultOffsetY(-1)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
    const config = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background',
      positionStrategy,
    });
    return config;
  }

  ngOnDestroy(): void {}
}
