import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeDropdownComponent } from './time-dropdown/time-dropdown.component';
import { TimeObject, TimePickerType } from './timpicker.constans';

@Injectable()
export class TimepickerService implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject();
  backdropClick$: Subject<void> = new Subject();
  overlayRef: OverlayRef;
  overlayConfig: OverlayConfig;

  private _parsedTime = new BehaviorSubject<string>('');
  parsedTime$ = this._parsedTime.asObservable();
  private _timeObject = new BehaviorSubject<TimeObject>({
    HH: '',
    MM: '',
  });
  timeObject$ = this._timeObject.asObservable();

  constructor(private readonly overlay: Overlay, private readonly overlayPositionBuilder: OverlayPositionBuilder) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    this.backdropClick$.unsubscribe();
  }

  updateTimeObject(value: TimePickerType) {
    this._timeObject.next({
      ...this._timeObject.value,
      [value.type]: value.value,
    });
    this._parsedTime.next(`${this._timeObject.value.HH}:${this._timeObject.value.MM}`);
  }

  upadteTimeObjectFromString(value: string) {
    if (this.isTimeFormat24(value)) {
      const splitedTime = value.split(':');
      this._timeObject.next({
        HH: splitedTime[0],
        MM: splitedTime[1],
      });
    }
  }

  isTimeFormat24(val: string): boolean {
    const value = /^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(val) ? true : false;

    return value;
  }

  openModal(element: HTMLElement): void {
    this.createModalPositionConfig(element);
    this.overlayRef = this.overlay.create(this.overlayConfig);

    const componentPortal = new ComponentPortal(TimeDropdownComponent);
    const componentRef = this.overlayRef.attach(componentPortal) as ComponentRef<TimeDropdownComponent>;

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(() => {
        this.overlayRef.dispose();
        this.backdropClick$.next();
      });

    componentRef.instance.timepickerServiceReference = this;
  }

  closeModal(): void {
    this.overlayRef.detach();
  }

  private createModalPositionConfig(element: HTMLElement): void {
    const positionStrategy = this.overlayPositionBuilder

      .flexibleConnectedTo(element)
      .withLockedPosition()
      .withGrowAfterOpen()
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: -1,
        },

        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetY: 1,
        },
      ]);

    this.overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'timepicker-container-backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        scrollThrottle: 0,
      }),
    });
  }
}
