<div class="wrapper">
  <div class="line-1">
    <span class="small">
      {{ textLine1Small }}
    </span>
    <span class="big supporting-5-light-blue">{{ textLine1Big }}</span>
  </div>
  <div class="line-2">
    <span class="small">
      {{ textLine2Small }}
    </span>
    <span class="big supporting-7-navy">{{ textLine2Big }}</span>
  </div>
</div>

<app-link
  [text]="buttonLabel | translate | uppercase"
  [color]="'supporting-5-light-blue'"
  (click)="buttonClicked.emit()"
  [dataCy]="'grey-box-with-big-text-button-' + buttonLabel"
></app-link>
