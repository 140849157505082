import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoTooltipToggleComponent } from '@app/@wchfs-ui/lib/info-tooltip/toggle/info-tooltip-toggle.component';
import { SvgIconInfoTooltipComponent } from '@app/@wchfs-ui';
import { InfoTooltipComponent } from '@app/@wchfs-ui/lib/info-tooltip/info-tooltip.component';

@NgModule({
  imports: [CommonModule],
  declarations: [InfoTooltipToggleComponent, SvgIconInfoTooltipComponent, InfoTooltipComponent],
  exports: [InfoTooltipToggleComponent, SvgIconInfoTooltipComponent, InfoTooltipComponent],
})
export class InfoTooltipModule {}
