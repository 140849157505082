import { Id } from '@app/@core/http/crud-model';
import { MetaResource } from '@shared/interfaces/paged-resource';
import { TyreResource } from '@data/tyres/tyres.model';

export interface ReleaseActionsStateModel {
  activeDeposit: TyreResource;
  visibleColumns: string[];
  tab_slug: string;
}

export class SetActiveDeposit {
  static readonly type = '[ReleaseActions] Set Active Deposit';

  constructor(public id: Id) {}
}

export class UnsetActiveDeposit {
  static readonly type = '[ReleaseActions] Unset Active Deposit';
}

export class SetVisibleColumns {
  static readonly type = '[ReleaseActions] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetMeta {
  static readonly type = '[ReleaseActions] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class SetTabSlug {
  static readonly type = '[ReleaseActions] Set Tab Slug';

  constructor(public slug: string) {}
}

export class ResetDepositState {
  static readonly type = '[ReleaseActions] Reset Deposit State';
}
