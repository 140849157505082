import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { pluck } from 'rxjs/operators';
import { SetupStatuses, TyresStateModel } from './tyres.actions';
import { TyreAdditionalStateValue, TyreStateResource } from '@data/tyres/tyres.model';
import { TyresService } from '@data/tyres/tyres.service';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

@State<TyresStateModel>({
  name: 'tyres',
  defaults: {
    statuses: null,
  },
})
@Injectable()
export class TyresState {
  @Selector()
  static tyreStatuses(state: TyresStateModel): TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[] {
    return state.statuses;
  }

  @Action(SetupStatuses)
  setupStatuses(ctx: StateContext<TyresStateModel>, action: SetupStatuses): void {
    this.tyresService
      .getStates()
      .pipe(pluck('data'))
      .subscribe((statuses: TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[]) => {
        ctx.patchState({
          statuses: statuses,
        });
      });
  }

  constructor(private tyresService: TyresService) {}

  static tyreStatus(
    value: TyreStateValue | TyreAdditionalStateValue
  ): (state: TyresStateModel) => TyreStateResource<TyreStateValue | TyreAdditionalStateValue> {
    return createSelector([TyresState], (state: TyresStateModel) => {
      return state.statuses.find((s) => s.value === value);
    });
  }
}
