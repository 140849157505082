<app-impersonating-as-widget
  [style.height.px]="impersonatingAsWidgetHeightInPx"
  *ngIf="impersonatedBy$ | async"
></app-impersonating-as-widget>

<ng-container *ngIf="(surveyStatus$ | async)?.key === SurveyStatus.UNSENT">
  <app-survey-widget
    *ngIf="surveyService.survey | async as survey"
    [style.height.px]="surveyWidgetHeightInPx"
    [survey]="survey"
  ></app-survey-widget>
</ng-container>

<router-outlet></router-outlet>
