import { Create, Id, Update } from '@app/@core/http/crud-model';
import { HttpClient } from '@angular/common/http';
import { DataResource } from '@app/@shared/interfaces/data-resource';
import { Observable } from 'rxjs';
import { NoteUpdateRolesPatchRequest, NotePostRequest, NoteResource } from './note.model';

const ENDPOINT = '/note';

export class NoteService
  implements Create<NotePostRequest, NoteResource>, Update<NoteUpdateRolesPatchRequest, NoteResource> {
  constructor(private httpClient: HttpClient, private endpoint: string, private id: Id) {}

  create(params: NotePostRequest): Observable<DataResource<NoteResource>> {
    return this.httpClient.post<DataResource<NoteResource>>(`${this.endpoint}/${this.id}${ENDPOINT}`, params);
  }

  update(id: Id, params: NoteUpdateRolesPatchRequest): Observable<DataResource<NoteResource>> {
    return this.httpClient.patch<DataResource<NoteResource>>(
      `${this.endpoint}/${this.id}${ENDPOINT}/${id}/update-roles`,
      params
    );
  }
}
