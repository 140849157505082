import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DepositActionData,
  DisposalActionData,
  ServiceMatchAction,
  ServiceMatchResource,
  ServiceMatchResponse,
  SpareWheelInstallationActionData,
  VehicleActionData,
} from '@data/disposition-add-edit/service-matches/service-matches.model';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Id } from '@core/http/crud-model';
import { DataResource } from '@shared/interfaces/data-resource';

const ENDPOINT = '/service-matches';

@Injectable({
  providedIn: 'root',
})
export class ServiceMatchesService {
  constructor(private httpClient: HttpClient) {}

  match(
    action: ServiceMatchAction,
    tyreId: string,
    data: DepositActionData | VehicleActionData | SpareWheelInstallationActionData | DisposalActionData
  ): Observable<ServiceMatchResponse> {
    return this.httpClient
      .get<ServiceMatchResponse>(`${ENDPOINT}/${action}/${tyreId}`, {
        params: this.convertDataToParams(data),
      })
      .pipe(pluck('data'));
  }

  auth(serviceId: Id, workshopId: Id, fleetId: Id): Observable<DataResource<ServiceMatchResource>> {
    return this.httpClient.get<DataResource<ServiceMatchResource>>(
      `${ENDPOINT}/auth/${serviceId}/${workshopId}/${fleetId}`
    );
  }

  private convertDataToParams(
    data: DepositActionData | VehicleActionData | SpareWheelInstallationActionData | DisposalActionData
  ): {
    [key: string]: string;
  } {
    const _data = {};
    Object.keys(data).map((key) => {
      _data[key] = data[key] as string;
    });

    return _data;
  }
}
