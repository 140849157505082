import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { OptionComponent } from '../option/option.component';
import { ChipListService, ChipType, GroupData } from './chip-list.service';
import { CompareStrategy, CompareStrategyService } from './compare-strategy.service';
import { DropdownComponent } from './dropdown.component';
import { DropdownService } from './dropdown.service';
import { MultiselectSelectionChange, MultiselectSelectionModel } from './multiselect-selection-model';
import { coerceBoolean, coerceNumber, ToCoerce } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    DropdownService,
    CompareStrategyService,
  ],
})
export class SelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @coerceBoolean
  @Input()
  public clearOption: ToCoerce = true;
  @Input()
  public placeholder: string;
  @Input()
  @coerceBoolean
  public required: ToCoerce = false;
  @Input()
  @coerceBoolean
  public disabled: ToCoerce = false;
  @Input()
  @coerceBoolean
  public multiselect: ToCoerce = false;
  @Input()
  @coerceBoolean
  public readonly: ToCoerce = false;
  @Input()
  @coerceBoolean
  public withChips: ToCoerce = false;
  @Input()
  public debounce = 0;
  @Input()
  @coerceBoolean
  public hint: ToCoerce = false;
  @Input()
  public hintDebounce = 300;
  @Input()
  public hintPlaceholder = 'Hint';
  @Input()
  @coerceNumber
  public dropdownMaxHeight: ToCoerce = 500;
  @Input()
  @coerceBoolean
  public filter: ToCoerce = false;
  @Input()
  @coerceBoolean
  public typeahead: ToCoerce = false;
  @Output()
  public allSelectedOptionsDeleted: EventEmitter<null> = new EventEmitter();
  @Output()
  public onInputChange: EventEmitter<string> = new EventEmitter();
  onInputChangeDebounce: Subject<string> = new Subject<string>();
  @Output()
  public onHintInputChange: EventEmitter<null> = new EventEmitter();
  onHintInputChangeDebounce: Subject<string> = new Subject<string>();
  @ViewChild('input')
  public input: ElementRef;
  @ViewChild('hintInput')
  public hintInput: ElementRef;
  @ViewChild(DropdownComponent)
  public dropdown: DropdownComponent;
  @ContentChildren(OptionComponent, { descendants: true })
  public options: QueryList<OptionComponent>;
  public selected: any;
  public selectedOption: OptionComponent;
  public selectionModel: MultiselectSelectionModel<OptionComponent>;
  public displayText = '';
  public selectionModelRemoveItem$: Observable<MultiselectSelectionChange<OptionComponent>>;
  public selectionModelAddItem$: Observable<MultiselectSelectionChange<OptionComponent>>;
  public chipListService: ChipListService;
  private keyManager: ActiveDescendantKeyManager<OptionComponent>;
  private onDestroy$ = new Subject<void>();

  constructor(private dropdownService: DropdownService, private compareStrategyService: CompareStrategyService) {
    this.dropdownService.register(this);
  }

  get compareWith() {
    return this.compareStrategyService.compareWith;
  }

  @Input() set compareWith(value) {
    this.compareStrategyService.compareWith = value;
  }

  private _value: OptionComponent | OptionComponent[];

  get value() {
    if (Array.isArray(this._value)) {
      return this._value.map((v) => v.value);
    }
    return this._value?.value || null;
  }

  set value(option: OptionComponent | OptionComponent[]) {
    this._value = option;
    this.onChange();
  }

  public ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.options)
      .withHorizontalOrientation('ltr')
      .withVerticalOrientation()
      .withWrap();

    this.selectionModelRemoveItem$ = this.selectionModel.changed.asObservable().pipe(
      takeUntil(this.onDestroy$),
      filter((v: MultiselectSelectionChange<OptionComponent>) => {
        return v.removed.length > 0 && v.added.length === 0;
      })
    );
    this.selectionModelAddItem$ = this.selectionModel.changed.asObservable().pipe(
      takeUntil(this.onDestroy$),
      filter((v: MultiselectSelectionChange<OptionComponent>) => {
        return v.removed.length === 0 && v.added.length > 0;
      })
    );

    this.options.changes.pipe(takeUntil(this.onDestroy$)).subscribe((queryList: QueryList<OptionComponent>) => {
      const selectedValues = this.selectionModel.selected.map((o: OptionComponent) => o.value);
      selectedValues.forEach((sv) => {
        queryList.forEach((option: OptionComponent) => {
          if (this.compareWith(option.value, sv)) {
            this.selectionModel.select(option);
          }
        });
      });
      this.chipListService.resetGroups();
    });

    this.selectionModelAddItem$.subscribe((change: MultiselectSelectionChange<OptionComponent>) => {
      this.chipListService.addOptions(...change.added);
      this.refreshValue();
      this.updateInput();
    });

    this.selectionModelRemoveItem$.subscribe((change: MultiselectSelectionChange<OptionComponent>) => {
      this.selectionModel.deselectDuplicates(...change.removed);
      this.chipListService.removeOptions(...change.removed);
      this.refreshValue();
      this.updateInput();
    });
  }

  public showDropdown() {
    if (!this.readonly) {
      this.dropdown.show();
    }

    if (!this.options.length) {
      return;
    }

    this.selected ? this.keyManager.setActiveItem(this.selectedOption) : this.keyManager.setFirstItemActive();
  }

  ngOnInit(): void {
    this.selectionModel = new MultiselectSelectionModel<OptionComponent>(this, this.multiselect as boolean, [], true);
    this.chipListService = new ChipListService(this);

    this.onInputChangeDebounce
      .pipe(takeUntil(this.onDestroy$), debounceTime(this.debounce))
      .subscribe((value: string) => {
        this.onInputChange.emit(value);
        this.clearSelected();
        this.filterOptions(value);
        this.displayText = value;
      });

    this.onHintInputChangeDebounce
      .pipe(takeUntil(this.onDestroy$), debounceTime(this.hintDebounce))
      .subscribe((value: any) => {
        this.onHintInputChange.emit(value);
        if (this.filter) {
          this.filterOptions(value);
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public selectOption(option: OptionComponent) {
    this.multiselect ? this.selectOptionMultiSelect(option) : this.selectOptionSingleSelect(option);
  }

  public toggleOption(option: OptionComponent) {
    this.multiselect ? this.toggleOptionMultiSelect(option) : this.selectOptionSingleSelect(option);
  }

  public toggleOptionMultiSelect(option: OptionComponent) {
    if (this.isSelected(option)) {
      this.deselectOptionMultiselect(option);
    } else {
      this.selectOptionMultiSelect(option);
    }
  }

  public isSelected(option: OptionComponent) {
    return this.selectionModel.isSelected(option);
  }

  public deselectOptionMultiselect(option: OptionComponent) {
    this.selectionModel.deselect(option);
    this.updateInput();
  }

  public refreshValue() {
    const uniqueSelected = this.selectionModel.getUniqueSelected();
    if (uniqueSelected.length === 0 && this.value === null) {
      return;
    }
    this.value = uniqueSelected.length > 0 ? uniqueSelected : null;
  }

  public selectOptionSingleSelect(option: OptionComponent) {
    this.keyManager.setActiveItem(option);
    this.selected = option.key;
    this.selectedOption = option;
    this.displayText = this.selectedOption ? this.selectedOption.getLabel() : '';
    if (this.typeahead) {
      this.filterOptions(this.displayText);
    }
    this.hideDropdown();
    this.value = this.selectedOption;
  }

  public selectOptionMultiSelect(option: OptionComponent) {
    this.selectionModel.select(option);
    this.updateInput();
  }

  public selectAllProvidedOptionsMultiSelect(options: OptionComponent[]) {
    this.selectionModel.select(...options);
    this.refreshValue();
    this.updateInput();
  }

  public deselectAllProvidedOptionsMultiSelect(options: OptionComponent[]) {
    this.selectionModel.deselect(...options);
    this.refreshValue();
    if (this.selectionModel.selected.length < 1) {
      this.reset();
    }
    this.updateInput();
  }

  updateInput() {
    this.selectedOption = this.selectionModel.selected[0] ? this.selectionModel.selected[0] : null;
    this.selected = this.selectedOption ? this.selectedOption.key : null;
    this.displayText = this.selectedOption ? this.selectedOption.getLabel() : '';
    if (this.typeahead) {
      this.displayText = this.displayText;
    }
  }

  public hideDropdown() {
    this.dropdown.hide();
  }

  public onChangeFn = (_: any) => {};

  public onTouchedFn = () => {};

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.setSelectionByValue(value);
  }

  public onTouched() {
    this.onTouchedFn();
  }

  public onChange() {
    this.onChangeFn(this.value);
  }

  public clearSelected() {
    this.allSelectedOptionsDeleted.emit();
    this.reset();
  }

  public reset() {
    this.selectionModel.clear();
    this.displayText = '';
    if (this.typeahead) {
      this.filterOptions(this.displayText);
    }
    this.selected = '';
    this.selectedOption = null;
    this.refreshValue();
  }

  removeChip($event: { key: string; level: number }, value: OptionComponent | GroupData) {
    const type = value instanceof OptionComponent ? ChipType.ITEM : ChipType.HEADER;
    if (type === ChipType.ITEM) {
      const options = this.selectionModel.selected.filter((o) =>
        this.compareWith(o.value, (value as OptionComponent).value)
      );
      this.selectionModel.deselectDuplicates(...options);
    }

    if (type === ChipType.HEADER) {
      this.selectionModel.deselect(...(value as GroupData).options);
    }
  }

  hintInputKeyUpHandler(event: KeyboardEvent) {
    this.onHintInputChangeDebounce.next(this.hintInput.nativeElement.value);
  }

  inputKeyUpHandler(event: KeyboardEvent) {
    this.onInputChangeDebounce.next(this.input.nativeElement.value);
  }

  setCompareStrategy(value: any) {
    this.compareStrategyService.setCompareStrategy(value);
  }

  private setSelectionByValue(value: any | any[]) {
    // INFO: https://angular.io/guide/zone
    setTimeout(() => {
      if (this.isValueShouldResetValues(value)) {
        this.reset();
        return;
      }
      this.compareStrategyService.checkValueTypeWithCompareStrategy(value);
      if (this.options && this.options.length && this.compareWith) {
        const options: any[] = this.options.toArray().filter((o) => {
          if (!Array.isArray(value)) {
            value = [value];
          }

          return value.filter((v: any) => this.compareWith(o.value, v)).length > 0;
        });

        this.reset();
        options.forEach((o: OptionComponent) => {
          this.selectOption(o);
        });
      }
    });
  }

  private isValueShouldResetValues(value: any) {
    if (!value) {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }

  private filterOptions(value: string) {
    if (this.compareStrategyService.compareStrategy === CompareStrategy.PRIMITIVE) {
      this.options.map((option: OptionComponent) => {
        option.hidden = !option.value.toString().toLowerCase().includes(value.toLowerCase());
      });
    } else {
      this.options.map((option: OptionComponent) => {
        let hidden = true;
        Object.keys(option.value).every((key) => {
          if (option.value[key].toString().toLowerCase().includes(value.toLowerCase())) {
            hidden = false;
            return false; // INFO: break loop
          }
          return true; // INFO: continue loop
        });
        option.hidden = hidden;
      });
    }
  }
}
