<div class="table-container">
  <ng-content select="table"></ng-content>
  <div #tableMenuButton class="table-menu" *ngIf="showTableMenuButton" (click)="open()">
    <wchfs-icon color="accent" svgIcon="wchfs:more" wchfsSuffix></wchfs-icon>
  </div>
  <ng-template #columnListBody>
    <div class="menu-body">
      <wchfs-checkbox
        class="column-list-item"
        *ngFor="let column of columnList?.controls | keyvalue"
        [formControl]="column.value"
      >
        {{ 'table.' + tableName + '.' + column.key | translate }}
      </wchfs-checkbox>
    </div>
  </ng-template>
  <ng-content select="wchfs-dot-loader"></ng-content>
</div>
