import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatepickerButtonComponent } from '../button/button.component';
import { WchfsRippleDatepickerDirective } from '../shared/ripple/ripple';
import { SvgIconDatepickerComponent } from '../svg-icon/svg-icon.component';
import { CalendarActionsComponent } from './calendar-actions/calendar-actions.component';
import { CalendarComponent } from './calendar.component';
import { DayPickerComponent } from './day-picker/day-picker.component';
import { DayPickerService } from './day-picker/day-picker.service';
import { DaysOfWeekComponent } from './days-of-week/days-of-week.component';
import { MonthHeaderComponent } from './month-header/month-header.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { RangeButtonsComponent } from './range-buttons/range-buttons.component';
import { RangeDateInputComponent } from './range-date-input/range-date-input.component';
import { DatepickerToggleComponent } from './toggle/datepicker-toggle.component';
import { YearPickerComponent } from './year-picker/year-picker.component';

@NgModule({
  imports: [CommonModule, A11yModule, PortalModule],
  declarations: [
    SvgIconDatepickerComponent,
    CalendarComponent,
    DayPickerComponent,
    DaysOfWeekComponent,
    MonthHeaderComponent,
    DatepickerToggleComponent,
    MonthPickerComponent,
    YearPickerComponent,
    CalendarActionsComponent,
    RangeButtonsComponent,
    WchfsRippleDatepickerDirective,
    RangeDateInputComponent,
    DatepickerButtonComponent,
  ],
  providers: [DayPickerService],
  exports: [
    CalendarComponent,
    DatepickerToggleComponent,
    RangeDateInputComponent,
    SvgIconDatepickerComponent,
    DatepickerButtonComponent,
  ],
})
export class DatePickerModule {}
