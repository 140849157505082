import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ServicesStateModel, SetServices } from '@data/services/services.actions';
import { Injectable } from '@angular/core';
import { ServiceResource } from '@data/services/services.interface';

@State<ServicesStateModel>({
  name: 'services',
  defaults: {
    services: [],
  },
})
@Injectable()
export class ServicesState {
  @Selector()
  static services(state: ServicesStateModel): ServiceResource[] {
    return state.services;
  }

  @Action(SetServices)
  setServices(ctx: StateContext<ServicesStateModel>, action: SetServices): void {
    ctx.patchState({
      services: action.services,
    });
  }
}
