import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DispositionAddEditMetaStateModel,
  ResetDispositionAddEditMetaState,
  SetFillDataOverlayChecked,
  SetFillDataOverlayDontShowInFuture,
  SetFillDataOverlayVisibility,
} from '@data/disposition-add-edit/disposition-add-edit-meta.actions';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';

@State<DispositionAddEditMetaStateModel>({
  name: 'dispositionAddEditMeta',
  defaults: {
    fill_data_overlay: {
      visible: false,
      dont_show_in_future: false,
      checked: false,
    },
  },
})
@Injectable()
export class DispositionAddEditMetaState {
  @Selector()
  static fillDataOverlay(
    state: DispositionAddEditMetaStateModel
  ): {
    visible: boolean;
    dont_show_in_future: boolean;
    checked: boolean;
  } {
    return state.fill_data_overlay;
  }

  @Action(SetFillDataOverlayVisibility)
  setFillDataOverlayVisibility(
    ctx: StateContext<DispositionAddEditMetaStateModel>,
    action: SetFillDataOverlayVisibility
  ): void {
    ctx.setState(
      patch({
        fill_data_overlay: patch({
          visible: action.visible,
        }),
      })
    );
  }

  @Action(SetFillDataOverlayDontShowInFuture)
  setFillDataOverlayDontShowInFuture(
    ctx: StateContext<DispositionAddEditMetaStateModel>,
    action: SetFillDataOverlayDontShowInFuture
  ): void {
    ctx.setState(
      patch({
        fill_data_overlay: patch({
          dont_show_in_future: action.dont_show_in_future,
        }),
      })
    );
  }

  @Action(SetFillDataOverlayChecked)
  setFillDataOverlayChecked(
    ctx: StateContext<DispositionAddEditMetaStateModel>,
    action: SetFillDataOverlayChecked
  ): void {
    ctx.setState(
      patch({
        fill_data_overlay: patch({
          checked: action.checked,
        }),
      })
    );
  }

  @Action(ResetDispositionAddEditMetaState)
  resetDispositionAddEditMetaState(
    ctx: StateContext<DispositionAddEditMetaStateModel>,
    action: ResetDispositionAddEditMetaState
  ): void {
    ctx.setState({
      fill_data_overlay: {
        ...ctx.getState().fill_data_overlay,
        checked: false,
      },
    });
  }
}
