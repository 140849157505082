import { Component, forwardRef, HostListener, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit, ControlValueAccessor {
  @Input() @coerceBoolean disabled = false;
  @Input() name: string;
  @Input() value: string;

  ngControl: NgControl;

  constructor(private injector: Injector) {}

  @HostListener('click', ['$event'])
  public toggleChecked(): void {
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {}

  public onChange: any = () => {};

  public onTouch: any = () => {};
}
