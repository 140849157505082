import { Component, HostBinding, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SnackBarData } from '../snack-bars.constants';

@Component({
  selector: 'wchfs-only-message-snack-bar',
  templateUrl: './only-message-snack-bar.component.html',
  styleUrls: ['./only-message-snack-bar.component.scss'],
})
export class OnlyMessageSnackBarComponent {
  snackbarIconName = 'wchfs:snackbar-' + this.data.type;

  @HostBinding('class') class = this.data.type;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    private _snackRef: MatSnackBarRef<OnlyMessageSnackBarComponent>
  ) {}

  dismiss() {
    this._snackRef.dismiss();
  }
}
