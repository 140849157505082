import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { SnackBarAction, SnackbarColors, SnackbarTypeName } from './Snackbar';

@Component({
  selector: 'app-notify-snackbar',
  templateUrl: './notify-snackbar.component.html',
  styleUrls: ['./notify-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifySnackbarComponent implements OnInit {
  color: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      title?: string;
      message: string;
      actions?: SnackBarAction[];
      type: SnackbarTypeName;
    },
    private snackRef: MatSnackBarRef<NotifySnackbarComponent>
  ) {}

  ngOnInit(): void {
    this.color = SnackbarColors.hex(this.data?.type);
  }

  close(): void {
    this.snackRef.dismiss();
  }
}
