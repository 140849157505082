import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitcherComponent } from '@app/@wchfs-ui/lib/form/switcher/switcher.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SvgIconModule } from '@app/@wchfs-ui/lib/svg-icon/svg-icon.module';

@NgModule({
  declarations: [SwitcherComponent],
  imports: [CommonModule, ReactiveFormsModule, SvgIconModule],
  exports: [SwitcherComponent],
})
export class SwitcherModule {}
