<ng-container *ngIf="icon && iconPosition === 'left'">
  <wchfs-icon [svgIcon]="icon" size="24" [style.margin-right]="'6px'"></wchfs-icon>
</ng-container>
<ng-content *ngIf="loading === false; else loader"></ng-content>
<ng-container *ngIf="icon && iconPosition === 'right'">
  <wchfs-icon [svgIcon]="icon" size="24" [style.margin-left]="'6px'"></wchfs-icon>
</ng-container>
<div
  class="button-ripple"
  [buttonRippleCentered]="wchfsRippleCentered"
  [buttonRippleColor]="rippleColor"
  [buttonRippleDisabled]="false"
  [buttonRippleTrigger]="hostElement"
  buttonRipple
></div>

<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>
