<app-calendar-date-time-picker
  *ngIf="editReservationDateModalService?.reservation?.workshop?.id as id"
  [datesService]="editReservationDateModalService"
  [formControl]="editReservationDateModalService.term"
  [id]="+id"
></app-calendar-date-time-picker>

<app-modal-buttons-group>
  <button pso-outline-button (click)="closeButtonClicked.emit()">{{ 'cancel' | translate | uppercase }}</button>
  <button
    pso-button
    (click)="confirmButtonClicked.emit()"
    [color]="'primary-b-blue'"
    [loading]="editReservationDateModalService.loading"
  >
    {{ 'save' | translate | uppercase }}
  </button>
</app-modal-buttons-group>
