import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileResource } from './profile-resource.interface';
import { DataResource } from '@shared/interfaces/data-resource';
import { Id } from '@app/@core/http/crud-model';
import { UserVerifyRequest } from '../auth/auth.interface';

const ENDPOINT = '/profile';
@Injectable({
  providedIn: 'root',
})
export class ProfileResourceService {
  constructor(private httpClient: HttpClient) {}

  getProfile(): Observable<DataResource<ProfileResource>> {
    return this.httpClient.get<DataResource<ProfileResource>>(ENDPOINT);
  }

  update(id: Id, params: UserVerifyRequest): Observable<DataResource<ProfileResource>> {
    return this.httpClient.patch<DataResource<ProfileResource>>(ENDPOINT, params);
  }

  impersonate(id: Id): Observable<DataResource<{ header_token: string }>> {
    return this.httpClient.post<DataResource<{ header_token: string }>>(ENDPOINT + '/impersonate', {
      user_id: id,
    });
  }
}
