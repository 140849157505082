import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DispositionAddEditStateModel } from '@data/disposition-add-edit/disposition-add-edit.actions';
import {
  DispositionPostRequest,
  DispositionStoreRequestService,
  DispositionStoreRequestTyre,
  DriverCodeSmsVerification,
  DriverVerificationData,
  RimTypeValue,
  ServiceGroup,
  DispositionDraftPostRequest,
  DispositionActionsAddSaleRequest,
  DispositionAddZamPostRequest,
} from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import {
  CompletionType,
  TyreAdditionalState,
  TyreAdditionalStateValue,
  TyreLogWithMeta,
  TyreStateResource,
} from '@data/tyres/tyres.model';
import { Store } from '@ngxs/store';
import { DispositionAddEditState } from '../disposition-add-edit/disposition-add-edit-state';
import { DispositionResource, DispositionStatus, DispositionStatusName } from '@data/dispositions/dispositions.model';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { DataResource } from '@shared/interfaces/data-resource';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { Create, Delete, Export, Id, Read, Update } from '@core/http/crud-model';
import { DispositionItemResource } from '@data/authorization/authorization.model';
import { EmptyParams } from '@shared/utils/empty-params';
import { price } from '@shared/utils/price';
import { FilterParams } from '@data/filtering/filtering.interface';
import { ReadById } from '@core/http/crud-model/read-by-id';
import { DispositionPdfService } from '@data/dispositions/pdf/disposition-pdf.service';
import { MediaResource } from '../media/media.model';
import { NoteService } from '@data/note/note.service';
import { DispositionItemService } from '@data/disposition-item/disposition-item.service';

const ENDPOINT = '/disposition';
const DEFAULT_WITH =
  'currentTyres,draftTyres,fleet,workshop,items,vehicle,vehicle_dispositions,vehicle_logs,tyreLogs,auths,draftTyreLogs,currentTyreLogs,user,deferredDispositions,wholesaler,notes,media,latestMileageUpdate';

@Injectable({
  providedIn: 'root',
})
export class DispositionsService
  implements
    Read<FilterParams | Partial<FilterParams>, DispositionResource>,
    ReadById<DispositionResource>,
    Create<DispositionPostRequest, DispositionResource>,
    Update<DispositionPostRequest, DispositionResource>,
    Export<FilterParams, MediaResource>,
    Delete<null> {
  state: DispositionAddEditStateModel;

  constructor(private httpClient: HttpClient, private store: Store) {}

  get(params: FilterParams | Partial<FilterParams>): Observable<PagedResource<DispositionResource>> {
    return this.httpClient.get<PagedResource<DispositionResource>>(`${ENDPOINT}`, {
      params,
    });
  }

  getById(id: Id, params?: FilterParams | Partial<FilterParams>): Observable<DataResource<DispositionResource>> {
    return this.httpClient.get<DataResource<DispositionResource>>(`${ENDPOINT}/${id.toString()}`, {
      params: {
        ...params,
        with: DEFAULT_WITH,
      },
    });
  }

  create(disposition: DispositionPostRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.post<DataResource<DispositionResource>>(ENDPOINT, disposition);
  }

  createDraft(disposition: DispositionDraftPostRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.post<DataResource<DispositionResource>>(ENDPOINT, disposition);
  }

  update(id: Id, params: DispositionPostRequest): Observable<DataResource<DispositionResource>> {
    return this.httpClient.put<DataResource<DispositionResource>>(`${ENDPOINT}/${id}`, params);
  }

  delete(id: Id, params?: null): Observable<DataResource<null>> {
    return this.httpClient.delete<DataResource<null>>(`${ENDPOINT}/${id}`);
  }

  updateMileage(id: Id, mileage: number): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/${id}`, {
      mileage,
    });
  }

  dispositionPdfServiceFactory(id: Id): DispositionPdfService {
    return new DispositionPdfService(this.httpClient, ENDPOINT, id);
  }

  noteFactory(id: Id): NoteService {
    return new NoteService(this.httpClient, ENDPOINT, id);
  }

  sendVerificationCode(formData: DriverVerificationData): Observable<string> {
    return this.httpClient.post<string>(`/driver-sms-verification/generate`, formData);
  }

  driverSmsCodeVerification(formData: DriverCodeSmsVerification): Observable<string> {
    return this.httpClient.post<string>(`/driver-sms-verification/validate`, formData);
  }

  approve(dispositionId: Id): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/${dispositionId}/actions/approve`, {});
  }

  verify(dispositionId: Id): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/${dispositionId}/actions/verify`, {});
  }

  approveable(dispositionId: Id): Observable<DataResource<boolean>> {
    return this.httpClient.get<DataResource<boolean>>(`${ENDPOINT}/${dispositionId}/actions/approvable`);
  }

  addSale(
    dispositionId: Id,
    params: DispositionActionsAddSaleRequest
  ): Observable<DataResource<DispositionItemResource>> {
    return this.httpClient.patch<DataResource<DispositionItemResource>>(
      `${ENDPOINT}/${dispositionId}/actions/add-sale`,
      params
    );
  }

  sendToApproval(dispositionId: Id): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(
      `${ENDPOINT}/${dispositionId}/actions/send-to-approval`,
      {
        with:
          'currentTyres,draftTyres,fleet,workshop,items,vehicle,vehicle_dispositions,vehicle_logs,tyreLogs,auths,draftTyreLogs,currentTyreLogs,user',
      }
    );
  }

  addZam(dispositionId: Id, params: DispositionAddZamPostRequest): Observable<DataResource<DispositionItemResource>> {
    return this.httpClient.patch<DataResource<DispositionItemResource>>(
      `${ENDPOINT}/${dispositionId}/actions/request-for-posting/add-zam`,
      params
    );
  }

  requestForPosting(dispositionId: Id): Observable<DataResource<DispositionItemResource>> {
    return this.httpClient.patch<DataResource<DispositionItemResource>>(
      `${ENDPOINT}/${dispositionId}/actions/request-for-posting`,
      {}
    );
  }

  updateServiceComment(dispositionId: Id, comment: string): Observable<DataResource<DispositionResource>> {
    return this.httpClient.patch<DataResource<DispositionResource>>(`${ENDPOINT}/${dispositionId}`, {
      service_comment: comment,
    });
  }

  export(params: FilterParams): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/export`, {
      params: new HttpParams({ fromObject: params }),
    });
  }

  dispositionItemServiceFactory(id: Id): DispositionItemService {
    return new DispositionItemService(this.httpClient, ENDPOINT, id);
  }

  prepareRequest(
    state: DispositionAddEditStateModel,
    isDraft = false,
    status?: DispositionStatusName
  ): DispositionPostRequest {
    this.state = state;
    const request = {
      fleet_id: this.getFleetId(),
      vehicle_id: this.getVehicleId(),
      driver_name: this.getDriverName(),
      driver_phone: this.getDriverPhone(),
      mileage: this.getMileage(),
      tpms: this.getTpms(),
      rim_type: this.getRimType(),
      spare_wheel: this.getSpareWheel(),
      tyres: this.getTyres(isDraft),
      services: this.getServices(),
      service_comment: this.getServiceComment(),
      workshop_id: this.getWorkshopId(),
      status: status || this.getStatus(isDraft),
    };

    return request;
  }

  private getFleetId(): string {
    return this.state?.fleet?.id?.toString();
  }

  private getVehicleId(): string {
    return this.state?.vehicle?.id?.toString();
  }

  private getDriverName(): string {
    return this.state?.car_and_driver?.data?.driver_name;
  }

  private getDriverPhone(): string {
    return this.state?.car_and_driver?.data?.driver_phone;
  }

  private getMileage(): string {
    return this.state?.car_and_driver?.data?.mileage?.toString();
  }

  private getTpms(): LaravelBoolean {
    return this.state?.car_and_wheels?.data?.tpms ? '1' : '0';
  }

  private getRimType(): RimTypeValue {
    return this.state?.car_and_wheels?.data?.rim;
  }

  private getSpareWheel() {
    return this.state?.car_and_wheels?.data?.spare;
  }

  private getTyres(isDraft: boolean): DispositionStoreRequestTyre[] {
    const toStoreRequestTyre = (tyre: TyreLogWithMeta): DispositionStoreRequestTyre => {
      const request = {
        id: tyre.data?.tyre?.id,
        tyre_position: tyre.meta.position,
        description: tyre.meta?.description,
        tyre_state: tyre.data?.tyre_state?.value,
        tyre_additional_states: tyre.data?.tyre_additional_states.map(
          (s: TyreStateResource<TyreAdditionalStateValue>) => s.value
        ),
        force_update_tyre_additional_states: true,
        deposit_code: tyre.data?.deposit_code,
        completion_type: tyre?.data?.completion_type,
        rim_type: tyre.data?.rim_type,
      };
      if (tyre.data?.tread_depth) {
        Object.assign(request, {
          tread_depth: tyre.data?.tread_depth.toString(),
        });
      }
      if (tyre.data?.origin_tyre_log_id) {
        Object.assign(request, {
          origin_tyre_log_id: tyre.data?.origin_tyre_log_id,
        });
      }

      return request;
    };

    const deposit = this.state.tyres_in_deposit.map(toStoreRequestTyre);
    const car = this.state.tyres_on_car.map(toStoreRequestTyre);

    return [...deposit, ...car];
  }

  private getServices(): DispositionStoreRequestService[] {
    return this.store.selectSnapshot(DispositionAddEditState.servicesAndProductsGroupedById).map(
      (serviceGroup: ServiceGroup): DispositionStoreRequestService => {
        const params = {
          id: serviceGroup.service.id,
          suborder_id: serviceGroup.meta.suborder_id,
          planned_at: serviceGroup.meta.executionDate,
          quantity: serviceGroup.meta.quantity,
          auth: serviceGroup.meta.authorizationRequired,
          is_deferred: serviceGroup?.meta?.deferred || false,
          producer: serviceGroup?.meta?.producer,
          part_type: serviceGroup?.meta?.part_type,
          description: serviceGroup?.meta?.remarks,
          catalog_number: serviceGroup?.meta?.catalog_number,
        };
        if (serviceGroup?.meta?.price_fleet) {
          Object.assign(params, {
            price_fleet: price(serviceGroup?.meta?.price_fleet),
          });
        }
        if (serviceGroup?.meta?.price_workshop) {
          Object.assign(params, {
            price_workshop: price(serviceGroup?.meta?.price_workshop),
          });
        }
        const isSale = serviceGroup.meta.order;
        const isRequestForPosting = serviceGroup.meta.deactivates_tyre_quantity_validation;
        if (isSale || isRequestForPosting) {
          Object.assign(params, {
            disposition_item_id: serviceGroup.meta.dispositionItemId,
          });
        }
        const requestParams = new EmptyParams(params).remove();
        return requestParams;
      }
    );
  }

  private getServiceComment() {
    return this.state.remarks;
  }

  private getWorkshopId(): number {
    return this.state.workshopId;
  }

  private getReceivedAt(): string {
    return new Date().toISOString().split('.')[0] + 'Z';
  }

  private getStatus(isDraft: boolean): DispositionStatusName {
    if (isDraft) {
      return DispositionStatus.DRAFT;
    }

    if (this.state.disposition.status === DispositionStatus.PRE_FILLED) {
      return DispositionStatus.PRE_FILLED;
    }

    return DispositionStatus.TO_APPROVAL;
    // return this.state.services.some((s) => s.meta.authorizationRequired === true)
    //   ? DispositionStatus.TO_APPROVAL
    //   : DispositionStatus.APPROVED;
  }
}
