import { HttpClient } from '@angular/common/http';
import { Id, Patch } from '@core/http/crud-model';
import { SuborderItemPatchRequest } from '@data/suborder-item/suborder-item.model';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import { DispositionItemResource } from '@data/authorization/authorization.model';

const ENDPOINT = '/suborderItem';

export class SuborderItemService implements Patch<SuborderItemPatchRequest, DispositionItemResource> {
  constructor(private http: HttpClient, private endpoint: string) {}

  patch(id: Id, params: SuborderItemPatchRequest): Observable<DataResource<DispositionItemResource>> {
    return this.http.patch<DataResource<DispositionItemResource>>(`${this.endpoint}${ENDPOINT}/${id}`, params);
  }
}
