<ng-container *ngIf="opened">
  <ng-container *ngIf="isRangePicker && showSelectButton">
    <wchfs-range-buttons (selectLastDaysChange)="selectLastDays($event)"></wchfs-range-buttons>
  </ng-container>
  <div
    #calendar
    [class.calendar--disabled]="disabled"
    [ngClass]="'calendar--first-day-of-week-' + (firstDayOfWeek | lowercase)"
    class="calendar"
  >
    <ng-container *ngFor="let month of months; trackBy: trackByMilliseconds">
      <wchfs-month-header
        (activeMonthChange)="onActiveMonthChange($event)"
        (changeCalendarType)="changeCalendarType($event)"
        [activeMonth]="activeMonth"
        [calendarType]="calendarType"
        [dateInStepper]="month"
        [locale]="locale"
        [showMonthStepper]="showMonthStepper"
      ></wchfs-month-header>
      <ng-container *ngIf="calendarType === CalendarTypes.days">
        <wchfs-days-of-week [locale]="locale"></wchfs-days-of-week>
        <wchfs-day-picker
          [dateFormat]="dateFormat"
          (activeDateChange)="onActiveDateChange($event)"
          (endDateInRangeChange)="onSelectRange($event, false)"
          (selectedDateChange)="onSelect($event, true)"
          (startDateInRangeChange)="onSelectRange($event)"
          [activeDate]="activeDate"
          [firstDayOfWeek]="firstDayOfWeek"
          [isRangePicker]="isRangePicker"
          [locale]="locale"
          [min]="min"
          [month]="month"
          [rangeValueEnd]="rangeControlEnd?.value"
          [rangeValueStart]="rangeControlStart?.value"
          [selectedDate]="value"
          [selectedLastDays]="selectedLastDaysRange"
          [showSelectButton]="showSelectButton"
        ></wchfs-day-picker>
      </ng-container>
      <ng-container *ngIf="calendarType === CalendarTypes.onlyMonths">
        <wchfs-month-picker
          (changeCalendarType)="changeCalendarType($event)"
          (selectedMonthChange)="onSelect($event)"
          [dateInStepper]="activeMonth"
          [locale]="locale"
          [selectedDate]="value"
        ></wchfs-month-picker>
      </ng-container>
      <ng-container *ngIf="calendarType === CalendarTypes.onlyYears">
        <wchfs-year-picker
          (changeCalendarType)="changeCalendarType($event)"
          (selectedYearChange)="onSelect($event)"
          [dateInStepper]="activeMonth"
          [locale]="locale"
          [selectedDate]="value"
        ></wchfs-year-picker>
      </ng-container>
      <ng-container *ngIf="showSelectButton">
        <wchfs-calendar-actions
          (cancelRangePicker)="cancelRangePicker()"
          (selectDayChange)="changeDateByButton()"
          (selectRangePicker)="changeRangeByButton($event)"
          (selectTodayChange)="selectToday()"
          [isRangePicker]="isRangePicker"
          [value]="value"
        ></wchfs-calendar-actions>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
