import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DepositsStateModel,
  ResetDepositState,
  SetActiveDeposit,
  SetActiveTab,
  SetTabSlug,
  SetVisibleColumns,
  UnsetActiveDeposit,
} from '@data/deposits/deposits.actions';
import { Injectable } from '@angular/core';
import { TyreResource } from '@data/tyres/tyres.model';
import { TyresService } from '@data/tyres/tyres.service';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

const DEFAULTS: DepositsStateModel = {
  activeDeposit: null,
  visibleColumns: [],
  activeTab: null,
  tab_slug: '',
};

@State<DepositsStateModel>({
  name: 'deposits',
  defaults: DEFAULTS,
})
@Injectable()
export class DepositsState {
  @Selector()
  static activeDeposit(state: DepositsStateModel): TyreResource {
    return state.activeDeposit;
  }

  @Selector()
  static visibleColumns(state: DepositsStateModel): string[] {
    return state.visibleColumns;
  }

  @Selector()
  static activeTab(state: DepositsStateModel): ListTabWithColumns {
    return state.activeTab;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<DepositsStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetActiveDeposit)
  setActiveDeposit(ctx: StateContext<DepositsStateModel>, action: SetActiveDeposit): void {
    this.tyresService.getById(action.id).subscribe((res) => {
      ctx.patchState({
        activeDeposit: res.data,
      });
    });
  }

  @Action(UnsetActiveDeposit)
  unsetActiveDeposit(ctx: StateContext<DepositsStateModel>): void {
    ctx.patchState({
      activeDeposit: null,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<DepositsStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<DepositsStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }

  @Action(ResetDepositState)
  resetDepositState(ctx: StateContext<DepositsStateModel>, action: ResetDepositState): void {
    ctx.setState(DEFAULTS);
  }

  constructor(private tyresService: TyresService) {}
}
