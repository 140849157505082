import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { GenericModal, Modal, ModalConfig, ModalWithAction } from '@pso-ui/modal';
import { UpdateShipmentNumbersComponent } from '@pages-and-modals/relocations/modals/update-shipment-numbers/update-shipment-numbers.component';
import { OrderWithReservationRejectModalComponent } from '@pages-and-modals/orders-with-reservation/shared/order-with-reservation-reject-modal/order-with-reservation-reject-modal.component';
import { RelocationOrderCourierAgainComponent } from '@pages-and-modals/relocations/modals/relocation-order-courier-again/relocation-order-courier-again.component';
import { ProductTyreIdModalComponent } from '@pages-and-modals/deposits/product-tyre-id-modal/product-tyre-id-modal.component';
import { TireReleaseModalComponent } from '@pages-and-modals/deposits/shared/release/tire-release-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<D = null, R = boolean>(
    component: ComponentType<
      | Modal
      | ModalWithAction<unknown>
      | GenericModal<unknown>
      | UpdateShipmentNumbersComponent
      | OrderWithReservationRejectModalComponent
      | RelocationOrderCourierAgainComponent
      | ProductTyreIdModalComponent
      | TireReleaseModalComponent
    >,
    config: ModalConfig,
    data?: D
  ): Observable<R> {
    return this.dialog.open(component, this.getConfig(config, data)).afterClosed();
  }

  openedDialogs(): MatDialogRef<any>[] {
    return this.dialog.openDialogs;
  }

  noModalIsOpen(): boolean {
    return this.openedDialogs().length === 0;
  }

  private getConfig<D>(config: ModalConfig, data: D): ModalConfig<D> {
    return {
      ...config,
      data: data,
    };
  }
}
