<div class="modal-wrap" *ngIf="config">
  <div class="close-wrap">
    <app-cross-close (click)="close()" dataCy="generic-modal-close-button"></app-cross-close>
  </div>
  <div class="wrapper">
    <app-modal-icon [type]="type"></app-modal-icon>
    <app-modal-header-only>{{ config?.title | translate }}</app-modal-header-only>

    <div class="text-wrap">
      <app-modal-text [style.text-align]="'center'">{{ config?.text | translate }}</app-modal-text>
    </div>
    <div *ngIf="config.customContent as customContent" [style]="customContent.style">
      {{ customContent.content }}
    </div>

    <app-modal-buttons-group>
      <ng-container *ngFor="let button of config.buttons">
        <ng-container [ngSwitch]="button.type">
          <button
            *ngSwitchCase="PSOButtons.DEFAULT"
            pso-button
            [size]="button.size"
            [color]="button.color"
            (click)="confirm(button.action)"
          >
            {{ button.label | translate | uppercase }}
          </button>
          <button
            *ngSwitchCase="PSOButtons.GHOST"
            pso-outline-button
            [size]="button.size"
            (click)="confirm(button.action)"
          >
            {{ button.label | translate | uppercase }}
          </button>
        </ng-container>
      </ng-container>
    </app-modal-buttons-group>
  </div>
</div>
