import { Injectable } from '@angular/core';
import { compareStrategyIncompatibleError, propsNotExistsInComparedObjectsError } from './select.errors';

@Injectable()
export class CompareStrategyService {
  compareWith: (o1: any, o2: any) => boolean;
  compareStrategy: CompareStrategy;

  checkValueTypeWithCompareStrategy(value: any) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    let expectedTypes: string[] = [];

    switch (this.compareStrategy) {
      case CompareStrategy.OBJECT:
        expectedTypes = ['object'];
        break;
      case CompareStrategy.PRIMITIVE:
        expectedTypes = ['number', 'string'];
        break;
    }

    if (expectedTypes.length > 0) {
      value.forEach((v: any) => {
        if (!expectedTypes.includes(typeof v)) {
          throw compareStrategyIncompatibleError(expectedTypes, typeof v);
        }
      });
    }
  }

  setCompareStrategy(value: any): void {
    if (!this.compareWith) {
      switch (typeof value) {
        case 'object':
          this.compareStrategy = CompareStrategy.OBJECT;
          this.compareWith = (o1: any, o2: any) => {
            if (!o1.id || !o2.id) {
              throw propsNotExistsInComparedObjectsError('id');
            }

            return o1 && o2 ? o1.id === o2.id : o1 === o2;
          };
          break;

        default:
          this.compareStrategy = CompareStrategy.PRIMITIVE;
          this.compareWith = (v1: any, v2: any) => {
            return v1.toString() === v2.toString();
          };
          break;
      }
    } else {
      this.compareStrategy = this.compareStrategy || CompareStrategy.CUSTOM;
    }
  }
}

export enum CompareStrategy {
  PRIMITIVE = 'Primitive', // INFO: option value as string, number etc.
  OBJECT = 'Object', // INFO: option value as object
  CUSTOM = 'Custom', // INFO: custom compare function passed in <wchfs-select> as `compareWith` props
}
