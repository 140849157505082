import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { OptionComponent } from '../option/option.component';
import { GroupData } from '../select/chip-list.service';
import { DropdownService } from '../select/dropdown.service';
import { SelectComponent } from '../select/select.component';
import { OptionGroupHeaderComponent } from './option-group-header.component';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss'],
})
export class OptionGroupComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input() @coerceBoolean showDivider = false;
  @ContentChild(OptionGroupHeaderComponent) header: OptionGroupHeaderComponent;
  @ContentChildren(OptionComponent) options: QueryList<OptionComponent>;
  select: SelectComponent;
  private onDestroy$ = new Subject<void>();

  constructor(private dropdownService: DropdownService) {
    this.select = this.dropdownService.getSelect();
  }

  get groupData(): GroupData {
    return {
      header: this.header,
      options: this.options.toArray(),
    };
  }

  ngAfterContentInit(): void {
    // INFO: https://angular.io/guide/zone
    setTimeout(() => {
      if (this.header && this.options) {
        this.options.toArray().forEach((o) => (o.padding = true));
        this.select.allSelectedOptionsDeleted.subscribe(() => (this.header.checked = false));
      }

      this.select.selectionModel.changed
        .asObservable()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.checkOptionsAndToggleHeader();
        });
    });
  }

  ngAfterViewInit(): void {
    if (this.header && this.options) {
      // Subscribing for header checkbox value change
      this.header.changed.subscribe(($event: boolean) => {
        const optionsArray = Array.from(this.options);

        if ($event) {
          this.select.selectAllProvidedOptionsMultiSelect(optionsArray);
          this.select.chipListService.addGroup(this.groupData);
        } else {
          this.select.deselectAllProvidedOptionsMultiSelect(optionsArray);
          this.select.chipListService.removeGroup(this.groupData);
        }
      });

      if (this.select.options) {
        this.select.options.changes
          .pipe(
            debounceTime(200), // TODO: Manual Change Def refactor. ExpressionChangedAfterItHasBeenCheckedError
            takeUntil(this.onDestroy$)
          )
          .subscribe(() => {
            this.checkOptionsAndToggleHeader();
          });
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private checkOptionsAndToggleHeader() {
    let isAllGroupOptionsSelected = true;
    let unselectedOption = null;
    this.options.forEach((o) => {
      if (!this.select.selectionModel.isSelected(o)) {
        isAllGroupOptionsSelected = false;
        unselectedOption = o;
      }
    });
    if (this.header) {
      this.header.checked = isAllGroupOptionsSelected;
    }

    if (isAllGroupOptionsSelected) {
      this.select.chipListService.addGroup(this.groupData);
    } else {
      this.select.chipListService.removeGroup(this.groupData);
      const selected = this.options.filter((o) => this.select.selectionModel.isSelected(o));
      this.select.chipListService.addOptions(...selected);
    }
  }
}
