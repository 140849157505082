import { TyreAdditionalStateValue, TyreStateResource } from '@data/tyres/tyres.model';
import { TyreStateValue } from '@data/disposition-add-edit/disposition-add-edit.interfaces';

export interface TyresStateModel {
  statuses: TyreStateResource<TyreStateValue | TyreAdditionalStateValue>[];
}

export class SetupStatuses {
  static readonly type = '[Tyres] Setup Statuses';
}
