import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalTypeName } from '@app/@pso-ui/modal/modal.model';

@Component({
  selector: 'app-modal-icon',
  templateUrl: './modal-icon.component.html',
  styleUrls: ['./modal-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalIconComponent {
  @Input() type: ModalTypeName;
}
