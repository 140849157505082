import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthState } from '@data/auth/auth-state';
import { Store } from '@ngxs/store';

@Injectable()
export class ImpersonatingAsInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.store.selectSnapshot(AuthState.isAuthenticated)) {
      const { impersonating_as } = this.store.selectSnapshot(AuthState);
      if (impersonating_as) {
        request = request.clone({
          setHeaders: {
            'X-Impersonating-As': impersonating_as,
          },
        });
      }
    }

    return next.handle(request);
  }
}
