import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalBoxType, SignalBoxTypeValue } from '@pso-ui/signal-box/signal-box.model';
import { PsoColorVar } from '@app/@wchfs-ui/lib/colors/colors.model';
import { SvgIconModule } from '@app/@wchfs-ui';
import { ModalType } from '@pso-ui/modal';

@Component({
  selector: 'app-signal-box',
  standalone: true,
  imports: [CommonModule, SvgIconModule],
  templateUrl: './signal-box.component.html',
  styleUrls: ['./signal-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalBoxComponent {
  @Input() type: SignalBoxTypeValue;
  @Input() heading: string;
  @Input() textLine1: string;
  @Input() textLine2: string;

  @HostBinding('style.color')
  get styleColor(): PsoColorVar {
    return SignalBoxType.getColor(this.type);
  }

  @HostBinding('style.background-color')
  get styleBackgroundColor(): PsoColorVar {
    return SignalBoxType.getBackgroundColor(this.type);
  }

  protected readonly ModalType = ModalType;
  protected readonly SignalBoxType = SignalBoxType;
}
