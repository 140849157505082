import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { PsoTypeAheadComponent } from './pso-typeahead.component';
import { TranslateModule } from '@ngx-translate/core';
import { PsoTypeaheadBaseComponent } from './pso-typeahead-base.component';

@NgModule({
  declarations: [PsoTypeAheadComponent, PsoTypeaheadBaseComponent],
  imports: [CommonModule, HttpClientJsonpModule, TranslateModule],
  exports: [PsoTypeAheadComponent, CommonModule],
})
export class PsoTypeaheadModule {}
