import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { TimeDropdownComponent } from './timepicker/time-dropdown/time-dropdown.component';
import { TimeListComponent } from './timepicker/time-dropdown/time-list/time-list.component';
import { FormsModule } from '@angular/forms';
import { TimeMaskDirective } from './timepicker/time-mask.directive';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  declarations: [TimepickerComponent, TimeDropdownComponent, TimeListComponent, TimeMaskDirective],
  imports: [CommonModule, SvgIconModule, FormsModule],
  exports: [TimepickerComponent],
})
export class TimepickerModule {}
