import { Roles, RouteRolePrefix } from '@core/Roles';
import {
  DispositionAuthGroup,
  DispositionAuthGroupName,
  DispositionAuthMode,
  DispositionAuthModeName,
  DispositionAuthStatus,
  DispositionAuthStatusName,
} from '@data/authorization/authorization.model';
import { UserResource } from '@data/users/users.model';

export interface DispositionAuthResource {
  id: number;
  auth_code_id: number;
  code: string;
  status: DispositionAuthStatusName;
  authorized_at: string;
  auth_group: DispositionAuthGroupName;
  is_auth_required: boolean;
  user: UserResource;
}

export class DispositionAuth {
  flaTouched: DispositionAuthResource;
  psoTouched: DispositionAuthResource;
  fla: DispositionAuthResource;
  pso: DispositionAuthResource;
  mode: DispositionAuthModeName;
  psoCanSend: boolean;
  flaCanSend: boolean;
  psoCanEditPrice: boolean;

  constructor(private auths: DispositionAuthResource[]) {
    this.setMode();
    this.flaTouched = this.auths.find((a) => a.auth_group === DispositionAuthGroup.FLA && a.user);
    this.psoTouched = this.auths.find((a) => a.auth_group === DispositionAuthGroup.PSO && a.user);
    this.fla = this.auths.find((a) => a.auth_group === DispositionAuthGroup.FLA);
    this.pso = this.auths.find((a) => a.auth_group === DispositionAuthGroup.PSO);
    this.setPsoCanSend();
    this.setFlaCanSend();
    this.setPsoCanEditPrice();
  }

  isFinished(): boolean {
    return !this.auths.find((a) => a.status === null);
  }

  isApproved(): boolean {
    const acceptedOrViewed = this.auths.filter((a) =>
      [DispositionAuthStatus.ACCEPTED, DispositionAuthStatus.VIEWED].includes(a.status)
    );
    return acceptedOrViewed.length === this.auths.length;
  }

  getAuthorizationPerson(): string {
    return this.flaTouched?.user?.name || this.psoTouched?.user?.name || '-';
  }

  getAuthorizationRole(): RouteRolePrefix | '-' {
    return this.flaTouched ? Roles.PREFIX_AFL : this.psoTouched ? Roles.PREFIX_PSOA : '-';
  }

  getExecutionDate(): string {
    return this.flaTouched ? this.flaTouched.authorized_at : this.psoTouched ? this.psoTouched.authorized_at : null;
  }

  getLastAuthorization(): DispositionAuthResource {
    return this.flaTouched || this.psoTouched || null;
  }

  isPsoMode(): boolean {
    return this.mode === DispositionAuthMode.PSO;
  }

  isFlaMode(): boolean {
    return this.mode === DispositionAuthMode.FLA;
  }

  isCommonMode(): boolean {
    return this.mode === DispositionAuthMode.COMMON;
  }

  private getMode(): DispositionAuthModeName | undefined {
    return this.mode;
  }

  private setPsoCanSend(): undefined {
    if (this.getMode() === DispositionAuthMode.FLA) {
      this.psoCanSend = !this.auths.find((a) => a.auth_group === DispositionAuthGroup.FLA && a.user);
      return;
    } else if (this.getMode() === DispositionAuthMode.PSO) {
      this.psoCanSend = !this.auths.find((a) => a.auth_group === DispositionAuthGroup.PSO && a.user);
      return;
    }
    this.psoCanSend = false; // TODO: check;
    return;
  }

  private setFlaCanSend() {
    if (this.getMode() === DispositionAuthMode.FLA) {
      this.flaCanSend = !this.auths.find((a) => a.auth_group === DispositionAuthGroup.FLA && a.user);
      return;
    }
    this.flaCanSend = false; // TODO: check;
  }

  private setMode(): void | undefined {
    const fla = !!this.auths.find((a) => a.auth_group === DispositionAuthGroup.FLA && a.is_auth_required);
    const pso = !!this.auths.find((a) => a.auth_group === DispositionAuthGroup.PSO && a.is_auth_required);

    if (fla && pso) {
      this.mode = DispositionAuthMode.COMMON; // TODO
      return;
    }

    if (fla) {
      this.mode = DispositionAuthMode.FLA;
      return;
    }

    if (pso) {
      this.mode = DispositionAuthMode.PSO;
      return;
    }

    this.mode = undefined;
  }

  private setPsoCanEditPrice(): void {
    this.psoCanEditPrice = !this.auths.find((a) => a.auth_group === DispositionAuthGroup.PSO && a.user);
  }
}
