<table [dataSource]="data" cdk-table>
  <ng-container cdkColumnDef="select">
    <th cdk-header-cell *cdkHeaderCellDef class="table-header" [style.width.px]="selectColumnWidthInPx">
      <wchfs-checkbox
        *ngIf="selection.isMultipleSelection()"
        color="primary"
        (change)="collectiveCheckboxChanged($event, data)"
        [checked]="selection.selected.length === data.length"
      ></wchfs-checkbox>
    </th>
    <td cdk-cell *cdkCellDef="let item" [style.width.px]="selectColumnWidthInPx">
      <wchfs-checkbox
        color="primary"
        (change)="rowCheckboxChanged($event, item)"
        [checked]="selection.isSelected(item)"
      ></wchfs-checkbox>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of columns">
    <ng-container [cdkColumnDef]="column.getName()">
      <th cdk-header-cell *cdkHeaderCellDef class="table-header" [style.width]="column.getWidth()">
        <div>
          {{ column.getHeader() | translate }}
        </div>
      </th>
      <td *cdkCellDef="let item" cdk-cell [style.width]="column.getWidth()">
        <ng-container [ngSwitch]="column.getContentType()">
          <ng-container
            *ngSwitchCase="GenericTableColumn.CONTENT_TYPE_TEMPLATE"
            [ngTemplateOutlet]="column.getTemplate()"
            [ngTemplateOutletContext]="{ item: item }"
          >
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ item[column.getName()] ? item[column.getName()] : '-' }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  <tr cdk-header-row *cdkHeaderRowDef="visibleColumns"></tr>
  <tr cdk-row *cdkRowDef="let item; columns: visibleColumns"></tr>
</table>
