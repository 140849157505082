import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ExpandButtonComponent } from './expand-button/expand-button.component';
import { ExpandedListContentComponent } from './expanded-list/expanded-list-content/expanded-list-content.component';
import { ExpandedListGroupComponent } from './expanded-list/expanded-list-group/expanded-list-group.component';
import { ExpandedListHeaderComponent } from './expanded-list/expanded-list-header/expanded-list-header.component';
import { ExpandedListComponent } from './expanded-list/expanded-list.component';

@NgModule({
  declarations: [
    ExpandButtonComponent,
    ExpandedListComponent,
    ExpandedListGroupComponent,
    ExpandedListHeaderComponent,
    ExpandedListContentComponent,
  ],
  imports: [CommonModule, SvgIconModule, ButtonModule],
  exports: [
    ExpandButtonComponent,
    ExpandedListComponent,
    ExpandedListGroupComponent,
    ExpandedListHeaderComponent,
    ExpandedListContentComponent,
  ],
})
export class ExpandedModule {}
