import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProfileState } from '@app/@data/profile/profile-state';
import { ImpersonateService } from '@core/http/impersonate.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-impersonating-as-widget',
  templateUrl: './impersonating-as-widget.component.html',
  styleUrls: ['./impersonating-as-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpersonatingAsWidgetComponent {
  @Select(ProfileState.profileName) name$: Observable<string>;

  constructor(private impersonateService: ImpersonateService) {}

  onEndImpersonatingClick(): void {
    this.impersonateService.finish();
  }
}
