import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { ErrorMessageResource } from '@shared/interfaces/data-resource';
import { TranslateService } from '@ngx-translate/core';
import { NotifySnackbarComponent } from '../../@pso-ui/notify-snackbar/notify-snackbar.component';
import { SnackBarType } from '../../@pso-ui/notify-snackbar/Snackbar';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  notifySuccess(message: string): void {
    this.openSnackBar(message, SnackBarType.SUCCESS);
  }

  notifyInfo(message: string): void {
    this.openSnackBar(message, SnackBarType.INFO);
  }

  notifyError(error: ErrorMessageResource | string): void | undefined {
    let message;

    const errorType = typeof error;

    if (errorType === 'string') {
      message = error as string;
      this.openSnackBar(message, SnackBarType.ERROR);

      return;
    }

    error = error as ErrorMessageResource;

    if (error && error.errors) {
      const errorsFromBackend = Object.values(error.errors);
      message = errorsFromBackend.join(' ');
    } else {
      message = 'Undefined error';
    }

    this.openSnackBar(message, SnackBarType.ERROR);
  }

  showQuickConfirmation(message: string): void {
    this.snackBar.openFromComponent(NotifySnackbarComponent, {
      data: {
        message: message,
        type: SnackBarType.SUCCESS,
      },
      panelClass: 'notification-snackbar',
      duration: 2000,
      verticalPosition: 'top',
    });
  }

  undoableActionConfirmation(message: string, action: () => void): MatSnackBarRef<NotifySnackbarComponent> | undefined {
    if (!action) {
      return;
    }
    const snackBarRef = this.snackBar.openFromComponent(NotifySnackbarComponent, {
      data: {
        message: this.translateService.instant(message),
        type: SnackBarType.SUCCESS,
        actions: [
          {
            handler: action,
            actionMessage: this.translateService.instant('undo'),
          },
        ],
      },
      panelClass: 'notification-snackbar',
      duration: 20000,
      verticalPosition: 'top',
    });
    return snackBarRef;
  }

  actionConfirmation(
    message: string,
    buttonLabel: string,
    action: () => void
  ): MatSnackBarRef<NotifySnackbarComponent> | undefined {
    if (!action) {
      return;
    }
    const snackBarRef = this.snackBar.openFromComponent(NotifySnackbarComponent, {
      data: {
        message: this.translateService.instant(message),
        type: SnackBarType.INFO,
        actions: [
          {
            handler: action,
            actionMessage: this.translateService.instant(buttonLabel),
          },
        ],
      },
      panelClass: 'notification-snackbar',
      duration: 20000,
      verticalPosition: 'top',
    });
    return snackBarRef;
  }

  private openSnackBar(message: string, snackType: string): void {
    this.snackBar.openFromComponent(NotifySnackbarComponent, {
      data: {
        message: this.translateService.instant(message),
        type: snackType,
      },
      panelClass: 'notification-snackbar',
      duration: 10000,
      verticalPosition: 'top',
    });
  }
}
