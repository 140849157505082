export class EmptyParams<T> {
  params: T;

  constructor(params: T) {
    this.params = params;
  }

  remove(): any {
    const params = {};
    if (!this.params) {
      return params;
    }

    Object.keys(this.params).forEach((k: string) => {
      if (this.params[k] !== '' && this.params[k] !== undefined && this.params[k] !== null) {
        params[k] = this.params[k];
      }
    });
    return params;
  }
}
