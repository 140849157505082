import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'wchfs-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent {
  textValue: string;
  isHide = true;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() set text(value: string) {
    if (value !== this.textValue) {
      this.textValue = value;
      this.isHide = !value;
      this.cdr.detectChanges();
    }
  }
}
