import { Component, HostBinding, Input } from '@angular/core';
import { coerceBoolean, ToCoerce } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-draggable-chip',
  templateUrl: './draggable-chip.component.html',
  styleUrls: ['./draggable-chip.component.scss'],
})
export class DraggableChipComponent {
  @Input() @HostBinding('class.active') @coerceBoolean active: ToCoerce = false;
  @Input() @HostBinding('class.drag') @coerceBoolean drag: ToCoerce = false;
}
