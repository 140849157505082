import { Component, HostBinding, Input } from '@angular/core';
import { Roles, RouteRolePrefix } from '@core/Roles';
import { Select } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss'],
})
export class DotLoaderComponent {
  @Select(ProfileState.routeRolePrefix)
  role$: Observable<RouteRolePrefix>;
  @Input() page = false;
  @Input() size: 's' | 'l' = 'l';
  @Input() role: RouteRolePrefix;
  @HostBinding('class.page') get useOverlay(): boolean {
    return this.page;
  }
  @HostBinding('class.loader-small')
  get isSmall(): boolean {
    return this.size === 's';
  }

  protected readonly Roles = Roles;
}
