import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { DropdownService } from '../select/dropdown.service';
import { SelectComponent } from '../select/select.component';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-option-group-header',
  templateUrl: './option-group-header.component.html',
  styleUrls: ['./option-group-header.component.scss'],
})
export class OptionGroupHeaderComponent {
  @Output() changed = new EventEmitter<boolean>(false);
  @ViewChild('header') header: ElementRef;
  public select: SelectComponent;
  showGroupOptions = new BehaviorSubject<boolean>(true);
  selectAllGroupOptions = new Subject<boolean>();

  constructor(private dropdownService: DropdownService) {
    this.select = this.dropdownService.getSelect();
  }

  private _checked = false;

  get checked() {
    return this._checked;
  }

  @Input() @coerceBoolean set checked(value) {
    this._checked = value;
    // this.changed.emit(value);
  }

  onHeaderClick() {
    this.showGroupOptions.next(!this.showGroupOptions.getValue());
  }

  toggleCheckbox($event: boolean) {
    this.checked = $event;
    this.changed.next($event);
  }

  getLabel(): string {
    return this.header.nativeElement.outerText.trim();
  }
}
