import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipPosition, TooltipStyle } from './tooltip.constants';

const defaultPosition: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
];
@Directive({
  selector: '[wchfsTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('wchfsTooltipPosition') set tooltipPosition(position: TooltipPosition) {
    this.generatePositionConfig(position);
  }
  @Input('wchfsTooltipIcon')
  icon: boolean = false;
  @Input('wchfsTooltipIsDisabled')
  isDisabled = false;
  @Input('wchfsTooltip') text: string;
  @Input('wchfsTooltipHTML') htmlContent: string;
  @Input('wchfsTooltipStyle') style: TooltipStyle;

  @HostListener('mouseenter')
  show() {
    this.hide();
    if (
      !this.isDisabled &&
      ((this.text && this.text !== '' && this.text !== null) ||
        (this.htmlContent && this.htmlContent !== '' && this.htmlContent !== null))
    ) {
      const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.htmlContent = this.htmlContent;
      tooltipRef.instance.style = this.style ? this.style : undefined;
      tooltipRef.instance.icon = this.icon ? this.icon : undefined;
    }
  }
  @HostListener('mouseleave')
  hide() {
    this.overlayRef.detach();
  }

  private overlayRef: OverlayRef;
  private positionConfig: ConnectedPosition[] = defaultPosition;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.createTooltip();
  }

  ngOnDestroy(): void {
    this.hide();
  }

  private createTooltip(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.positionConfig);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  private generatePositionConfig(position: TooltipPosition): void {
    switch (position) {
      case TooltipPosition.TOP:
        this.positionConfig = [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
        ];
        break;

      case TooltipPosition.LEFT:
        this.positionConfig = [
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
          },
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ];
        break;

      case TooltipPosition.RIGHT:
        this.positionConfig = [
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
          },
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ];
        break;

      case TooltipPosition.BOTTOM:
        this.positionConfig = [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ];
        break;
    }
  }
}
