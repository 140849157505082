import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Credentials,
  LoginFormResource,
  OauthTokenRequest,
  ResetPasswordResource,
  VerifyParams,
} from './auth.interface';

import { ClearProfileData } from '../profile/profile.actions';
import { ClearCredentials } from './auth.actions';
import { DataResource, MessageResource } from '@shared/interfaces/data-resource';
import { Id } from '@app/@core/http/crud-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  passwordValidationPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_()+=#?!@$%^&*-]).{8,}$';

  constructor(private httpClient: HttpClient, private readonly store: Store) {}

  login(loginForm: LoginFormResource): Observable<Credentials> {
    const body = this.createBodyRequest(loginForm);
    return this.httpClient.post<Credentials>('/oauth/token', body);
  }

  logout(): Observable<MessageResource> {
    return this.httpClient.post<MessageResource>(`/auth/logout`, {}).pipe(
      tap(() => this.store.dispatch(new ClearProfileData())),
      tap(() => this.store.dispatch(new ClearCredentials()))
    );
  }

  forgotPassword(email: string): Observable<MessageResource> {
    return this.httpClient.post(`/auth/forgot-password`, {
      email,
    });
  }

  resetPassword(data: ResetPasswordResource): Observable<MessageResource> {
    return this.httpClient.post(`/auth/reset-password`, data);
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('password_confirmation').value;

    return pass === confirmPass ? null : { notSame: true };
  };

  checkNewPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('new_password').value;
    const confirmPass = group.get('new_password_confirmation').value;

    return pass === confirmPass ? null : { notSame: true };
  };

  verify(id: Id, hash: string, params: VerifyParams): Observable<DataResource<null>> {
    return this.httpClient.get<DataResource<null>>(`/auth/verify/${id}/${hash}`, { params });
  }
  private createBodyRequest(loginForm: LoginFormResource) {
    const body: OauthTokenRequest = {
      grant_type: 'password',
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      username: loginForm.username,
      password: loginForm.password,
    };

    return body;
  }
}
