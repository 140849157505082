export enum ReservationsNotTakenModalAction {
  ALERT = 'alert',
  EDIT = 'edit',
  NOTHING = 'nothing',
}

export enum ReservationNotTakenAlertModalAction {
  CLOSE = 'close',
  DO_NOT_CLOSE = 'do_not_close',
}
