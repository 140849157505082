import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  OrdersWithReservationStateModel,
  SetVisibleColumns,
} from '@data/orders-with-reservation/orders-with-reservation.actions';
import { Injectable } from '@angular/core';

const DEFAULTS: OrdersWithReservationStateModel = {
  visibleColumns: [],
};

@State<OrdersWithReservationStateModel>({
  name: 'ordersWithReservation',
  defaults: DEFAULTS,
})
@Injectable()
export class OrdersWithReservationState {
  @Selector()
  static visibleColumns(state: OrdersWithReservationStateModel): string[] {
    return state.visibleColumns;
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<OrdersWithReservationStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }
}
