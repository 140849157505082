import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ProfileState } from '@app/@data/profile/profile-state';
import { GetProfileData } from '@app/@data/profile/profile.actions';
import { NotificationService } from '@app/@shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ROUTES } from '../routes';
import { EchoService } from '../websockets/echo.service';
import { SurveyService } from '@data/survey/survey.service';

@Injectable({ providedIn: 'root' })
export class AccountVerificationGuard implements CanActivate {
  ROUTES = ROUTES;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private readonly store: Store,
    private translate: TranslateService,
    private echoService: EchoService,
    private surveyService: SurveyService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.dispatch(new GetProfileData())?.pipe(
      switchMap(() => {
        return this.store.select(ProfileState.profile);
      }),
      map((profile) => {
        const { email, email_verified } = profile;

        if (!email || !email_verified) {
          this.notificationService.notifyError(this.translate.instant('to-continue-please-fill-your-data-below'));
          return this.router.createUrlTree([ROUTES.USER_VERIFY.path]);
        } else {
          this.echoService.connect();
          this.surveyService.setSurvey();
          return true;
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}
