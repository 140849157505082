import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeedInputContext, InputOptionResource } from '@app/@feed-inputs/index';
import { SquareBracketsHttpUrlEncodingCodec } from '@core/http/square-brackets-http-url-encoding-codec';
import { DataResource } from '@shared/interfaces/data-resource';
import { EMPTY, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeedInputService {
  ENDPOINT = '/feed-input';
  cache: [string, Observable<DataResource<InputOptionResource[]>>] | [] = [];

  constructor(private httpClient: HttpClient) {}

  getList(entity: string, context?: FeedInputContext | number): Observable<DataResource<InputOptionResource[]>> {
    if (!context) {
      return this.withoutContext(entity);
    }
    switch (typeof context) {
      case 'string':
        return this.stringContext(entity, context);
      case 'object':
        return this.objectContext(entity, context);
      default:
        return EMPTY;
    }
  }

  clearCache(): void {
    this.cache = [];
  }

  refreshCacheForEntity(entity: string): Observable<DataResource<InputOptionResource[]>> {
    delete this.cache[entity];
    return this.withoutContext(entity);
  }

  private withoutContext(entity: string): Observable<DataResource<InputOptionResource[]>> {
    const params = { paginate: 0 };
    if (!this.cache[entity]) {
      this.cache[entity] = this.httpClient
        .get<DataResource<InputOptionResource[]>>(`${this.ENDPOINT}/${entity}`, {
          params,
        })
        .pipe(shareReplay(1));
    }

    return this.cache[entity];
  }

  private stringContext(entity: string, context: string): Observable<DataResource<InputOptionResource[]>> {
    const params = {
      paginate: 0,
      context: context,
    };

    return this.httpClient.get<DataResource<InputOptionResource[]>>(`${this.ENDPOINT}/${entity}`, {
      params: params,
    });
  }

  private objectContext(entity: string, context: object): Observable<DataResource<InputOptionResource[]>> {
    let params = Object.keys(context).reduce(
      (params, key) => params.append(FeedInputService.getParamNameByKey(key), context[key]),
      new HttpParams({
        encoder: new SquareBracketsHttpUrlEncodingCodec(),
      })
    );
    params = params.append('paginate', '0');

    return this.httpClient.get<DataResource<InputOptionResource[]>>(`${this.ENDPOINT}/${entity}`, {
      params: params,
    });
  }

  private static getParamNameByKey(key: string): string {
    return `context[${key}]`;
  }
}
