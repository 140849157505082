export class Roles {
  // PREFIXES
  public static PREFIX_AFL: RouteRolePrefix = 'afl';
  public static PREFIX_PSOA: RouteRolePrefix = 'psoa';
  public static PREFIX_WORKSHOP: RouteRolePrefix = 'workshop';
  //ROLE GROUP
  public static ROLES_GROUP_PSO_EMPLOYEES: RolesGroupEnum = 'PSOA';
  public static ROLES_GROUP_FLEET: RolesGroupEnum = 'AFL';
  public static ROLES_GROUP_WORKSHOP: RolesGroupEnum = 'Serwis';
  public static ROLES_GROUP_BASIC_USER = 'Podstawowy użytkownik';
  // PSO
  public static ROLE_PSO_DIRECTOR: RoleSlug = 'pso_director';
  public static ROLE_PSO_SUPER_ADMIN: RoleSlug = 'pso_super_admin';
  public static ROLE_PSO_ADMIN: RoleSlug = 'pso_admin';
  public static ROLE_PSO_ACCOUNTANT: RoleSlug = 'pso_accountant';
  public static ROLE_PSO_CHAIRMAN: RoleSlug = 'pso_chairman';
  // WORKSHOP
  public static ROLE_WORKSHOP_MANAGER: RoleSlug = 'workshop_manager';
  public static ROLE_WORKSHOP_EMPLOYEE: RoleSlug = 'workshop_employee';
  // FLEET
  public static ROLE_FLEET_SUPER_ADMIN: RoleSlug = 'fleet_super_admin';
  public static ROLE_FLEET_ADMIN: RoleSlug = 'fleet_admin';
  public static ROLE_FLEET_DRIVER: RoleSlug = 'fleet_driver';

  static psoa(): RoleSlug[] {
    return [
      Roles.ROLE_PSO_DIRECTOR,
      Roles.ROLE_PSO_SUPER_ADMIN,
      Roles.ROLE_PSO_ADMIN,
      Roles.ROLE_PSO_ACCOUNTANT,
      Roles.ROLE_PSO_CHAIRMAN,
    ];
  }

  static afl(): RoleSlug[] {
    return [Roles.ROLE_FLEET_SUPER_ADMIN, Roles.ROLE_FLEET_ADMIN, Roles.ROLE_FLEET_DRIVER];
  }

  static aflAdmin(): RoleSlug[] {
    return [Roles.ROLE_FLEET_SUPER_ADMIN, Roles.ROLE_FLEET_ADMIN];
  }

  static workshop(): RoleSlug[] {
    return [Roles.ROLE_WORKSHOP_MANAGER, Roles.ROLE_WORKSHOP_EMPLOYEE];
  }

  static prefix(role: RoleSlug): RouteRolePrefix {
    if (Roles.afl().includes(role)) {
      return Roles.PREFIX_AFL;
    }
    if (Roles.psoa().includes(role)) {
      return Roles.PREFIX_PSOA;
    }
    if (Roles.workshop().includes(role)) {
      return Roles.PREFIX_WORKSHOP;
    }
  }

  static hasPsoRole(roles: RoleSlug[]): boolean {
    return roles.some((role: RoleSlug) => Roles.psoa().includes(role));
  }

  static isAflSuperAdmin(roles: RoleSlug[]): boolean {
    return roles.some((role: RoleSlug) => role === this.ROLE_FLEET_SUPER_ADMIN);
  }

  static isAflAdmin(roles: RoleSlug[]): boolean {
    return roles.some((role: RoleSlug) => Roles.aflAdmin().includes(role));
  }

  static isWorkshopManager(roles: RoleSlug[]): boolean {
    return roles.some((role: RoleSlug) => role === this.ROLE_WORKSHOP_MANAGER);
  }

  static isPsoaSuperAdmin(roles: RoleSlug[]): boolean {
    return roles.some((role: RoleSlug) => role === this.ROLE_PSO_SUPER_ADMIN);
  }
}

export type RoleSlug =
  | 'pso_director'
  | 'pso_super_admin'
  | 'pso_admin'
  | 'pso_accountant'
  | 'pso_chairman'
  | 'workshop_manager'
  | 'workshop_employee'
  | 'fleet_super_admin'
  | 'fleet_admin'
  | 'fleet_driver';

export type RouteRolePrefix = 'psoa' | 'afl' | 'workshop';
export type RolesGroupEnum = 'PSOA' | 'AFL' | 'Serwis' | 'Podstawowy użytkownik';
