<div [class]="{ padding: padding, multiselect: select.multiselect }" class="option-wrapper">
  <wchfs-checkbox *ngIf="select.multiselect" [checked]="selected"></wchfs-checkbox>

  <div class="option-prefix">
    <ng-content select="wchfs-option-prefix"></ng-content>
  </div>

  <div #label class="option-value">
    <ng-content></ng-content>
  </div>

  <div class="option-suffix">
    <ng-content select="wchfs-option-suffix"></ng-content>
  </div>
</div>
