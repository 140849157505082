<app-modal-header
  (iconClicked)="
    close({
      action: ReservationsNotTakenModalAction.ALERT,
      data: null
    })
  "
  >Niepodjęte rezerwacje
</app-modal-header>

<app-generic-table
  *ngIf="reservations$ | async as reservations; else loader"
  [data]="reservations"
  [columns]="columns"
></app-generic-table>
<ng-template #loader>
  <app-dot-loader></app-dot-loader>
</ng-template>

<ng-template #columnCarTemplate let-item="item">
  {{ item?.vehicle?.registration_number }}
</ng-template>
<ng-template #columnFleetTemplate let-item="item">
  {{ item?.vehicle?.fleet?.short_name }}
</ng-template>
<ng-template #columnPhoneNumberTemplate let-item="item">
  {{ item?.contact_details?.phone_number }}
</ng-template>
<ng-template #columnTypeTemplate let-item="item">
  {{ item?.type?.name }}
</ng-template>
<ng-template #columnStartDateTemplate let-item="item">
  <p class="bold m0">
    {{ item?.start_date | date: 'dd.MM.yyyy' }}
  </p>
  {{ item?.start_date | date: 'HH:mm' }} - {{ item?.end_date | date: 'HH:mm' }}
</ng-template>

<ng-template #columnActionTemplate let-item="item">
  <div class="action">
    <app-link [text]="'ZMIEŃ'" (click)="editButtonClicked(item)" [size]="'s'"></app-link>
    <div class="vertical-separator"></div>
    <app-link
      [text]="'AKCEPTUJ'"
      (click)="acceptButtonClicked(item)"
      [color]="'signal-success-green'"
      [size]="'s'"
    ></app-link>
  </div>
</ng-template>
