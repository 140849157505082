import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
  SecurityContext,
  SkipSelf,
} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { forkJoin, Observable, of as observableOf, throwError as observableThrow } from 'rxjs';
import { catchError, finalize, map, share, tap } from 'rxjs/operators';

export function getWchfsIconNameNotFoundError(iconName: string): Error {
  return Error(`Unable to find icon with the name "${iconName}"`);
}

export function getWchfsIconNoHttpProviderError(): Error {
  return Error(
    'Could not find HttpClient provider for use with icons. ' +
      'Please include the HttpClientModule from @angular/common/http in your ' +
      'app imports.'
  );
}

export function getWchfsIconFailedToSanitizeUrlError(url: SafeResourceUrl): Error {
  return Error(
    `The URL provided to SvgIconRegistry was not trusted as a resource URL ` +
      `via Angular's DomSanitizer. Attempted URL was "${url}".`
  );
}

export function getWchfsIconFailedToSanitizeLiteralError(literal: SafeHtml): Error {
  return Error(
    `The literal provided to SvgIconRegistry was not trusted as safe HTML by ` +
      `Angular's DomSanitizer. Attempted literal was "${literal}".`
  );
}

export interface IconOptions {
  viewBox?: string;
  withCredentials?: boolean;
}

class SvgIconConfig {
  svgElement: SVGElement | null;

  constructor(public url: SafeResourceUrl, public svgText: string | null, public options?: IconOptions) {}
}

type LoadedSvgIconConfig = SvgIconConfig & { svgText: string };

@Injectable({
  providedIn: 'root',
})
export class SvgIconRegistry implements OnDestroy {
  private document: Document;
  private svgIconConfigs = new Map<string, SvgIconConfig>();
  private iconSetConfigs = new Map<string, SvgIconConfig[]>();
  private cachedIconsByUrl = new Map<string, SVGElement>();
  private inProgressUrlFetches = new Map<string, Observable<string>>();
  private fontCssClassesByAlias = new Map<string, string>();
  private defaultFontSetClass = 'wchfs-icons';

  constructor(
    @Optional() private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    @Optional() @Inject(DOCUMENT) document: any,
    private readonly errorHandler: ErrorHandler
  ) {
    this.document = document;
  }

  addSvgIcon(iconName: string, url: SafeResourceUrl, options?: IconOptions): this {
    return this.addSvgIconInNamespace('', iconName, url, options);
  }

  addSvgIconLiteral(iconName: string, literal: SafeHtml, options?: IconOptions): this {
    return this.addSvgIconLiteralInNamespace('', iconName, literal, options);
  }

  addSvgIconInNamespace(namespace: string, iconName: string, url: SafeResourceUrl, options?: IconOptions): this {
    return this.addSvgIconConfig(namespace, iconName, new SvgIconConfig(url, null, options));
  }

  addSvgIconLiteralInNamespace(namespace: string, iconName: string, literal: SafeHtml, options?: IconOptions): this {
    const cleanLiteral = this.sanitizer.sanitize(SecurityContext.HTML, literal);

    if (!cleanLiteral) {
      throw getWchfsIconFailedToSanitizeLiteralError(literal);
    }

    return this.addSvgIconConfig(namespace, iconName, new SvgIconConfig('', cleanLiteral, options));
  }

  addSvgIconSet(url: SafeResourceUrl, options?: IconOptions): this {
    return this.addSvgIconSetInNamespace('', url, options);
  }

  addSvgIconSetLiteral(literal: SafeHtml, options?: IconOptions): this {
    return this.addSvgIconSetLiteralInNamespace('', literal, options);
  }

  addSvgIconSetInNamespace(namespace: string, url: SafeResourceUrl, options?: IconOptions): this {
    return this.addSvgIconSetConfig(namespace, new SvgIconConfig(url, null, options));
  }

  addSvgIconSetLiteralInNamespace(namespace: string, literal: SafeHtml, options?: IconOptions): this {
    const cleanLiteral = this.sanitizer.sanitize(SecurityContext.HTML, literal);

    if (!cleanLiteral) {
      throw getWchfsIconFailedToSanitizeLiteralError(literal);
    }

    return this.addSvgIconSetConfig(namespace, new SvgIconConfig('', cleanLiteral, options));
  }

  registerFontClassAlias(alias: string, className: string = alias): this {
    this.fontCssClassesByAlias.set(alias, className);
    return this;
  }

  classNameForFontAlias(alias: string): string {
    return this.fontCssClassesByAlias.get(alias) || alias;
  }

  setDefaultFontSetClass(className: string): this {
    this.defaultFontSetClass = className;
    return this;
  }

  getDefaultFontSetClass(): string {
    return this.defaultFontSetClass;
  }

  getSvgIconFromUrl(safeUrl: SafeResourceUrl): Observable<SVGElement> {
    const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl);

    if (!url) {
      throw getWchfsIconFailedToSanitizeUrlError(safeUrl);
    }

    const cachedIcon = this.cachedIconsByUrl.get(url);

    if (cachedIcon) {
      return observableOf(cloneSvg(cachedIcon));
    }

    return this.loadSvgIconFromConfig(new SvgIconConfig(safeUrl, null)).pipe(
      tap((svg) => this.cachedIconsByUrl.set(url!, svg)),
      map((svg) => cloneSvg(svg))
    );
  }

  getNamedSvgIcon(name: string, namespace: string = ''): Observable<SVGElement> {
    // Return (copy of) cached icon if possible.
    const key = iconKey(namespace, name);
    const config = this.svgIconConfigs.get(key);

    if (config) {
      return this.getSvgFromConfig(config);
    }

    // See if we have any icon sets registered for the namespace.
    const iconSetConfigs = this.iconSetConfigs.get(namespace);

    if (iconSetConfigs) {
      return this.getSvgFromIconSetConfigs(name, iconSetConfigs);
    }

    return observableThrow(getWchfsIconNameNotFoundError(key));
  }

  ngOnDestroy() {
    this.svgIconConfigs.clear();
    this.iconSetConfigs.clear();
    this.cachedIconsByUrl.clear();
  }

  private getSvgFromConfig(config: SvgIconConfig): Observable<SVGElement> {
    if (config.svgText) {
      // We already have the SVG element for this icon, return a copy.
      return observableOf(cloneSvg(this.svgElementFromConfig(config as LoadedSvgIconConfig)));
    } else {
      // Fetch the icon from the config's URL, cache it, and return a copy.
      return this.loadSvgIconFromConfig(config).pipe(map((svg) => cloneSvg(svg)));
    }
  }

  private getSvgFromIconSetConfigs(name: string, iconSetConfigs: SvgIconConfig[]): Observable<SVGElement> {
    // For all the icon set SVG elements we've fetched, see if any contain an icon with the
    // requested name.
    const namedIcon = this.extractIconWithNameFromAnySet(name, iconSetConfigs);

    if (namedIcon) {
      // We could cache namedIcon in svgIconConfigs, but since we have to make a copy every
      // time anyway, there's probably not much advantage compared to just always extracting
      // it from the icon set.
      return observableOf(namedIcon);
    }

    // Not found in any cached icon sets. If there are icon sets with URLs that we haven't
    // fetched, fetch them now and look for iconName in the results.
    const iconSetFetchRequests: Observable<string | null>[] = iconSetConfigs
      .filter((iconSetConfig) => !iconSetConfig.svgText)
      .map((iconSetConfig) => {
        return this.loadSvgIconSetFromConfig(iconSetConfig).pipe(
          catchError((err: HttpErrorResponse) => {
            const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, iconSetConfig.url);

            // Swallow errors fetching individual URLs so the
            // combined Observable won't necessarily fail.
            const errorMessage = `Loading icon set URL: ${url} failed: ${err.message}`;
            this.errorHandler.handleError(new Error(errorMessage));
            return observableOf(null);
          })
        );
      });

    // Fetch all the icon set URLs. When the requests complete, every IconSet should have a
    // cached SVG element (unless the request failed), and we can check again for the icon.
    return forkJoin(iconSetFetchRequests).pipe(
      map(() => {
        const foundIcon = this.extractIconWithNameFromAnySet(name, iconSetConfigs);

        if (!foundIcon) {
          throw getWchfsIconNameNotFoundError(name);
        }

        return foundIcon;
      })
    );
  }

  private extractIconWithNameFromAnySet(iconName: string, iconSetConfigs: SvgIconConfig[]): SVGElement | null {
    // Iterate backwards, so icon sets added later have precedence.
    for (let i = iconSetConfigs.length - 1; i >= 0; i--) {
      const config = iconSetConfigs[i];

      // Parsing the icon set's text into an SVG element can be expensive. We can avoid some of
      // the parsing by doing a quick check using `indexOf` to see if there's any chance for the
      // icon to be in the set. This won't be 100% accurate, but it should help us avoid at least
      // some of the parsing.
      if (config.svgText && config.svgText.indexOf(iconName) > -1) {
        const svg = this.svgElementFromConfig(config as LoadedSvgIconConfig);
        const foundIcon = this.extractSvgIconFromSet(svg, iconName, config.options);
        if (foundIcon) {
          return foundIcon;
        }
      }
    }
    return null;
  }

  private loadSvgIconFromConfig(config: SvgIconConfig): Observable<SVGElement> {
    return this.fetchIcon(config).pipe(
      tap((svgText) => (config.svgText = svgText)),
      map(() => this.svgElementFromConfig(config as LoadedSvgIconConfig))
    );
  }

  private loadSvgIconSetFromConfig(config: SvgIconConfig): Observable<string | null> {
    if (config.svgText) {
      return observableOf(null);
    }

    return this.fetchIcon(config).pipe(tap((svgText) => (config.svgText = svgText)));
  }

  private extractSvgIconFromSet(iconSet: SVGElement, iconName: string, options?: IconOptions): SVGElement | null {
    // Use the `id="iconName"` syntax in order to escape special
    // characters in the ID (versus using the #iconName syntax).
    const iconSource = iconSet.querySelector(`[id="${iconName}"]`);

    if (!iconSource) {
      return null;
    }

    // Clone the element and remove the ID to prevent multiple elements from being added
    // to the page with the same ID.
    const iconElement = iconSource.cloneNode(true) as Element;
    iconElement.removeAttribute('id');

    // If the icon node is itself an <svg> node, clone and return it directly. If not, set it as
    // the content of a new <svg> node.
    if (iconElement.nodeName.toLowerCase() === 'svg') {
      return this.setSvgAttributes(iconElement as SVGElement, options);
    }

    // If the node is a <symbol>, it won't be rendered so we have to convert it into <svg>. Note
    // that the same could be achieved by referring to it via <use href="#id">, however the <use>
    // tag is problematic on Firefox, because it needs to include the current page path.
    if (iconElement.nodeName.toLowerCase() === 'symbol') {
      return this.setSvgAttributes(this.toSvgElement(iconElement), options);
    }

    // createElement('SVG') doesn't work as expected; the DOM ends up with
    // the correct nodes, but the SVG content doesn't render. Instead we
    // have to create an empty SVG node using innerHTML and append its content.
    // Elements created using DOMParser.parseFromString have the same problem.
    // http://stackoverflow.com/questions/23003278/svg-innerhtml-in-firefox-can-not-display
    const svg = this.svgElementFromString('<svg></svg>');
    // Clone the node so we don't remove it from the parent icon set element.
    svg.appendChild(iconElement);

    return this.setSvgAttributes(svg, options);
  }

  private svgElementFromString(str: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = str;
    const svg = div.querySelector('svg') as SVGElement;

    if (!svg) {
      throw Error('<svg> tag not found');
    }

    return svg;
  }

  private toSvgElement(element: Element): SVGElement {
    const svg = this.svgElementFromString('<svg></svg>');
    const attributes = element.attributes;
    // Copy over all the attributes from the `symbol` to the new SVG, except the id.
    for (let i = 0; i < attributes.length; i++) {
      const { name, value } = attributes[i];

      if (name !== 'id') {
        svg.setAttribute(name, value);
      }
    }

    for (let i = 0; i < element.childNodes.length; i++) {
      if (element.childNodes[i].nodeType === this.document.ELEMENT_NODE) {
        svg.appendChild(element.childNodes[i].cloneNode(true));
      }
    }

    return svg;
  }

  private setSvgAttributes(svg: SVGElement, options?: IconOptions): SVGElement {
    svg.setAttribute('fit', '');
    svg.setAttribute('height', '100%');
    svg.setAttribute('width', '100%');
    svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    svg.setAttribute('focusable', 'false'); // Disable IE11 default behavior to make SVGs focusable.

    if (options && options.viewBox) {
      svg.setAttribute('viewBox', options.viewBox);
    }

    return svg;
  }

  private fetchIcon(iconConfig: SvgIconConfig): Observable<string> {
    const { url: safeUrl, options } = iconConfig;
    const withCredentials = options?.withCredentials ?? false;

    if (!this.httpClient) {
      throw getWchfsIconNoHttpProviderError();
    }

    if (safeUrl == null) {
      throw Error(`Cannot fetch icon from URL "${safeUrl}".`);
    }

    const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl);

    if (!url) {
      throw getWchfsIconFailedToSanitizeUrlError(safeUrl);
    }

    // Store in-progress fetches to avoid sending a duplicate request for a URL when there is
    // already a request in progress for that URL. It's necessary to call share() on the
    // Observable returned by http.get() so that multiple subscribers don't cause multiple XHRs.
    const inProgressFetch = this.inProgressUrlFetches.get(url);

    if (inProgressFetch) {
      return inProgressFetch;
    }

    const req = this.httpClient.get(url, { responseType: 'text', withCredentials }).pipe(
      finalize(() => this.inProgressUrlFetches.delete(url)),
      share()
    );

    this.inProgressUrlFetches.set(url, req);
    return req;
  }

  private addSvgIconConfig(namespace: string, iconName: string, config: SvgIconConfig): this {
    this.svgIconConfigs.set(iconKey(namespace, iconName), config);
    return this;
  }

  private addSvgIconSetConfig(namespace: string, config: SvgIconConfig): this {
    const configNamespace = this.iconSetConfigs.get(namespace);

    if (configNamespace) {
      configNamespace.push(config);
    } else {
      this.iconSetConfigs.set(namespace, [config]);
    }

    return this;
  }

  private svgElementFromConfig(config: LoadedSvgIconConfig): SVGElement {
    if (!config.svgElement) {
      const svg = this.svgElementFromString(config.svgText);
      this.setSvgAttributes(svg, config.options);
      config.svgElement = svg;
    }

    return config.svgElement;
  }
}

export function ICON_REGISTRY_PROVIDER_FACTORY(
  parentRegistry: SvgIconRegistry,
  httpClient: HttpClient,
  sanitizer: DomSanitizer,
  errorHandler: ErrorHandler,
  document?: any
) {
  return parentRegistry || new SvgIconRegistry(httpClient, sanitizer, document, errorHandler);
}

export const ICON_REGISTRY_PROVIDER = {
  // If there is already an SvgIconRegistry available, use that. Otherwise, provide a new one.
  provide: SvgIconRegistry,
  deps: [
    [new Optional(), new SkipSelf(), SvgIconRegistry],
    [new Optional(), HttpClient],
    DomSanitizer,
    ErrorHandler,
    [new Optional(), DOCUMENT as InjectionToken<any>],
  ],
  useFactory: ICON_REGISTRY_PROVIDER_FACTORY,
};

function cloneSvg(svg: SVGElement): SVGElement {
  return svg.cloneNode(true) as SVGElement;
}

function iconKey(namespace: string, name: string) {
  return namespace + ':' + name;
}
