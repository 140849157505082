import { VehicleResource } from '@data/vehicles/vehicles.model';
import { SuborderItemResource } from '@data/orders-with-reservation/suborders/orders-with-reservation-suborders.model';
import { FleetResource } from '@data/fleets/fleets.model';
import { UserResource } from '@data/users/users.model';
import { WorkshopResource } from '@data/workshop/workshop.model';
import { DateTimeString } from '@shared/interfaces/date-string';

export type OrderStatusValue =
  | 'new'
  | 'confirmed'
  | 'delivered'
  | 'ready_for_pickup'
  | 'pickup_ordered'
  | 'received'
  | 'finished'
  | 'cancelled'
  | 'rejected'
  | 'substitutes'
  | 'expired';

export class OrderStatus {
  static NEW: OrderStatusValue = 'new'; // Nowe
  static CONFIRMED: OrderStatusValue = 'confirmed'; // Potwierdzone
  static DELIVERED: OrderStatusValue = 'delivered'; // Dostarczone
  static READY_FOR_PICKUP: OrderStatusValue = 'ready_for_pickup'; // Gotowe do odbioru
  static PICKUP_ORDERED: OrderStatusValue = 'pickup_ordered'; // Zlecony odbiór
  static RECEIVED: OrderStatusValue = 'received'; // Opony odebrane
  static FINISHED: OrderStatusValue = 'finished'; // Zakończone
  static CANCELLED: OrderStatusValue = 'cancelled'; // Anulowane
  static REJECTED: OrderStatusValue = 'rejected'; // Odrzucone
  static SUBSTITUTES: OrderStatusValue = 'substitutes'; // Zamienniki
  static EXPIRED: OrderStatusValue = 'expired'; // Przeterminowane

  static color(status: OrderStatusValue): string {
    switch (status) {
      case OrderStatus.NEW:
        return '#04CFEB';
      case OrderStatus.CONFIRMED:
        return '#0055FF';
      case OrderStatus.DELIVERED:
        return 'var(--signal-success-green)';
      case OrderStatus.READY_FOR_PICKUP:
        return 'var(--supporting-4-purple)';
      case OrderStatus.PICKUP_ORDERED:
        return 'var(--supporting-8-olive)';
      case OrderStatus.RECEIVED:
        return 'var(--primary-b-blue)';
      case OrderStatus.FINISHED:
        return 'var(--supporting-7-navy)';
      case OrderStatus.CANCELLED:
        return 'var(--ui-g4-gray4)';
      case OrderStatus.REJECTED:
        return 'var(--supporting-1-orange)';
      case OrderStatus.EXPIRED:
        return '#f5812e';
      case OrderStatus.SUBSTITUTES:
        return 'var(--supporting-7-lighten20)';
      default:
        return 'var(--ui-g1-gray1)';
    }
  }
}

export type SuborderTypeSlug = 'with-reservation' | 'disposition-sale' | 'zam-sale';

export class SuborderType {
  public static WITH_RESERVATION: SuborderTypeSlug = 'with-reservation';
  public static DISPOSITION_SALE: SuborderTypeSlug = 'disposition-sale';
  public static ZAM_SALE: SuborderTypeSlug = 'zam-sale';
}

export interface SuborderResource {
  id: number;
  number: string;
  user: UserResource;
  workshop: WorkshopResource;
  ordered_item: SuborderItemResource;
  substitutes: SuborderItemResource[];
  items: SuborderItemResource[];
  is_with_reservation: boolean;
  vehicle: VehicleResource;
  vehicle_registration_number: string;
  vehicle_vin: string;
  fleet: FleetResource;
  fleet_name: string;
  status: OrderStatusValue;
  status_changed_at: string;
  response_time_until: string;
  type: SuborderTypeSlug;
  created_at: string;
  updated_at: string;
  confirmed_at: DateTimeString;
}
