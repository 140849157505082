import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { getSupportedInputTypes, Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { FormControlName, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormFieldControl } from '../../form/form-field/form-field-control';
import { CBS_INPUT_VALUE_ACCESSOR } from './input-value-accessor';

let nextUniqueId = 0;

// https://github.com/angular/components/blob/89d5790c433e02dbf372899d2109fe3502eba678/src/material/input/input.ts#L311
// tslint:disable-next-line:no-conflicting-lifecycle
@Directive({
  selector: `input[wchfsInput], textarea[wchfsInput], select[wchfsInput],
      input[wchfsInput], textarea[wchfsInput]`,
  exportAs: 'wchfsInput',
  providers: [{ provide: FormFieldControl, useExisting: WchfsInputDirective }],
})
export class WchfsInputDirective implements FormFieldControl, OnChanges, OnDestroy, AfterViewInit, DoCheck {
  ariaDescribedby: string;
  readonly isServer: boolean;
  readonly isNativeSelect: boolean;
  readonly isTextarea: boolean;
  focused = false;
  readonly stateChanges: Subject<void> = new Subject<void>();
  controlType = 'wchfs-input';
  autofilled = false;

  @Input() placeholder: string;
  @Input() isHint: boolean;
  errorState = false;
  @HostBinding('class') inputClasses = 'wchfs-input-element wchfs-form-field-autofill-control';
  protected uid = `wchfs-input-${nextUniqueId++}`;
  // tslint:disable-next-line:no-any
  protected previousNativeValue: any;
  protected neverEmptyInputTypes = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'].filter((t) =>
    getSupportedInputTypes().has(t)
  );
  protected disabledValue = false;
  protected idValue: string;
  protected requiredValue = false;
  protected typeValue = 'text';
  // tslint:disable-next-line:no-any
  private inputValueAccessor: { value: any };
  private readonlyValue = false;

  constructor(
    protected elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    protected platform: Platform,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Optional() @Self() @Inject(CBS_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    private autofillMonitor: AutofillMonitor,
    ngZone: NgZone
  ) {
    const element = this.elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    this.inputValueAccessor = inputValueAccessor || element;
    this.previousNativeValue = this.value;

    this.id = this.id;

    if (platform.IOS) {
      ngZone.runOutsideAngular(() => {
        elementRef.nativeElement.addEventListener('keyup', (event: Event) => {
          const el = event.target as HTMLInputElement;
          if (!el.value && !el.selectionStart && !el.selectionEnd) {
            el.setSelectionRange(1, 1);
            el.setSelectionRange(0, 0);
          }
        });
      });
    }

    this.isServer = !this.platform.isBrowser;
    this.isNativeSelect = nodeName === 'select';
    this.isTextarea = nodeName === 'textarea';

    if (this.isNativeSelect) {
      this.controlType = (element as HTMLSelectElement).multiple
        ? 'wchfs-native-select-multiple'
        : 'wchfs-native-select';
    }
  }

  @HostBinding('class.wchfs-hint')
  public get isWchfsHint() {
    return this.isHint;
  }

  @HostBinding('attr.id')
  public get isAttrId() {
    return this.id;
  }

  @HostBinding('attr.placeholder')
  public get isAttrPlaceholder() {
    return this.placeholder;
  }

  @HostBinding('attr.readonly')
  public get isAttrReadonly() {
    return (this.readonly && !this.isNativeSelect) || null;
  }

  @HostBinding('attr.aria-describedby')
  public get isAttrAriaDescribedby() {
    return this.ariaDescribedby || null;
  }

  @HostBinding('attr.aria-invalid')
  public get isAttrAriaInvalid() {
    return this.errorState;
  }

  @HostBinding('attr.aria-required')
  public get isAttrAriaRequired() {
    return this.required.toString();
  }

  @HostBinding('disabled') get isInputDisabled() {
    return this.disabled;
  }

  @HostBinding('required') get isInputRequired() {
    return this.required;
  }

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this.disabledValue;
  }

  set disabled(value: boolean) {
    if (this.ngControl instanceof FormControlName) {
      console.error(
        "Error: It looks like you're using the disabled attribute with a reactive form directive. The disable attribute " +
          'does not work correctly with reactive forms. Check the official docs of Angular: https://angular.io/guide/reactive-forms'
      );
    }

    this.disabledValue = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  @Input()
  get id(): string {
    return this.idValue;
  }

  set id(value: string) {
    this.idValue = value || this.uid;
  }

  @Input()
  get required(): boolean {
    return this.requiredValue;
  }

  set required(value: boolean) {
    this.requiredValue = coerceBooleanProperty(value);
  }

  @Input()
  get type(): string {
    return this.typeValue;
  }

  set type(value: string) {
    this.typeValue = value || 'text';
    if (!this.isTextarea && getSupportedInputTypes().has(this.typeValue)) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this.typeValue;
    }
  }

  @Input()
  get value(): string {
    return this.inputValueAccessor.value;
  }

  set value(value: string) {
    if (value !== this.value) {
      this.inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  @Input()
  get readonly(): boolean {
    return this.readonlyValue;
  }

  set readonly(value: boolean) {
    this.readonlyValue = coerceBooleanProperty(value);
  }
  get empty(): boolean {
    return !this._isNeverEmpty() && !this.elementRef.nativeElement.value && !this._isBadInput() && !this.autofilled;
  }

  ngAfterViewInit() {
    if (this.platform.isBrowser) {
      this.autofillMonitor.monitor(this.elementRef.nativeElement).subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    }
  }

  ngOnChanges() {
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();

    if (this.platform.isBrowser) {
      this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
    }
  }

  ngDoCheck() {
    this._dirtyCheckNativeValue();
  }

  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  protected _dirtyCheckNativeValue() {
    const newValue = this.elementRef.nativeElement.value;

    if (this.previousNativeValue !== newValue) {
      this.previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  protected _isNeverEmpty() {
    return this.neverEmptyInputTypes.indexOf(this.typeValue) > -1;
  }

  protected _isBadInput() {
    const validity = (this.elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }
}
