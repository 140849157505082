import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CalendarComponent } from '../calendar.component';

@Component({
  selector: 'wchfs-datepicker-toggle',
  templateUrl: 'datepicker-toggle.component.html',
  styleUrls: ['datepicker-toggle.component.scss'],
})
export class DatepickerToggleComponent {
  @Input() for: CalendarComponent;
  @ViewChild('button') toggleButton: ElementRef;
  @ViewChild('button') button: ElementRef;

  open(event: Event): void {
    event.stopPropagation();
    this.for.openCalendar();
  }
}
