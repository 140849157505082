<div class="calendar-only-months">
  <div
    *ngFor="let month of months; let index = index"
    class="month-element"
    [class.month-element--selected]="isSelectedMonth(month)"
    [class.month-element--current]="isCurrentMonth(month)"
    (click)="$event.stopPropagation(); selectMonth(index)"
  >
    <span class="month-caption"> {{ month }}</span>
  </div>
</div>
