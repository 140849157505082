import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { SelectConfig, SelectData } from '../select.interfaces';
import { SelectItem } from './select-item';
import { SelectDropdownService } from '../dropdown/select-dropdown.service';
import { BehaviorSubject, concat, Observable, of, Subject, Subscription } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SearchInputComponent } from '@app/@wchfs-ui/lib/form/search-input/search-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-base-select',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectDropdownService],
})
export class BaseSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() config: Partial<SelectConfig> = {
    search: false,
    prefixTemplate: null,
    showMoreIdx: null,
    showMoreLabel: 'see-all',
  };
  @Input() width = 'auto';
  @HostBinding('style.width') get widthStyle(): string {
    return typeof this.width === 'number' ? `${this.width}px` : this.width;
  }

  @ViewChild('options') options: TemplateRef<unknown>;
  @ViewChild('select') select: ElementRef;
  @ViewChild(SearchInputComponent) searchInput: SearchInputComponent;

  @Input()
  disabled: boolean;
  items: SelectItem<unknown>[];
  ngControl: NgControl;
  search = new UntypedFormControl('');
  filteredItems$: Observable<SelectItem<unknown>[]>;
  onDestroy$ = new Subject<void>();
  itemsLoading$ = new BehaviorSubject<boolean>(false);
  emptyFilteringResults$: Observable<boolean>;
  icon$: Observable<string>;
  displayValue = '';
  focus$: Observable<boolean>;
  invalid$: Observable<boolean>;
  subscription = new Subscription();
  showModeClicked = false;
  constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly injector: Injector,
    protected readonly selectDropdownService: SelectDropdownService,
    protected readonly focusMonitor: FocusMonitor,
    protected readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.subscription.add(this.onValueChanges());
    this.updateItems();

    this.icon$ = this.selectDropdownService.state$.pipe(
      map((state: 'open' | 'close') => {
        switch (state) {
          case 'open':
            return 'pso:arrow_up_s';
          case 'close':
            return 'pso:arrow_down_s';
          default:
            return 'pso:arrow_down_s';
        }
      })
    );

    this.focus$ = this.selectDropdownService.state$.pipe(map((state) => state === 'open'));
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscription.unsubscribe();
  }

  @Input()
  set data(value: SelectData<unknown>[] | (string | number)[]) {
    if (!value) {
      this.items = [];
      this.updateItems();
      return;
    }

    this.items = value.map((item: SelectData<unknown> | string | number, idx: number) => {
      if (typeof item === 'object' && 'value' in item) {
        return new SelectItem(item.value, item.label);
      } else {
        return new SelectItem(item, item.toString());
      }
    });
    this.updateItems();
  }
  onChangeFn = (_: unknown): void => {
    //
  };
  onTouchedFn = (_: unknown): void => {
    //
  };

  registerOnChange(fn: () => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  onInputClick(): void {
    if (this.readonly) {
      return;
    }
    this.openDropdown();
    this.onTouchedFn(null);
  }

  onArrowIconClick(): void {
    this.openDropdown();
  }

  openDropdown(callback?: (item: SelectItem<unknown>) => void): void {
    this.selectDropdownService.open(this.select?.nativeElement, this.options);
    this.changeDetectorRef.detectChanges();
    if (this.searchInput) {
      this.searchInput.clearInput();
      this.searchInput.focus();

      if (this.config.search) {
        this.subscription.add(this.listenSearchInputEnterPressed(callback));
      }
    }
  }

  updateItems(): void {
    if (this.config.search) {
      this.filteredItems$ = this.search.valueChanges.pipe(
        tap(() => this.itemsLoading$.next(true)),
        takeUntil(this.onDestroy$),
        map((keyword: string) => {
          return this.items.filter((item: SelectItem<unknown>) => {
            return item.match(keyword);
          });
        }),
        tap(() => this.itemsLoading$.next(false)),
        startWith(this.items),
        shareReplay(1) // Share the observable and replay the latest emitted value to new subscribers
      );
      this.emptyFilteringResults$ = this.filteredItems$.pipe(map((items) => items.length === 0));
    } else {
      this.filteredItems$ = of(this.items);
      this.emptyFilteringResults$ = this.filteredItems$.pipe(map((items) => items.length === 0));
    }
  }

  listenSearchInputEnterPressed(callback?: (item: SelectItem<unknown>) => void): Subscription {
    return this.searchInput.enterKeyPressed
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap(() => this.filteredItems$.pipe(take(1)))
      )
      .subscribe((items) => {
        if (items.length === 1) {
          callback(items[0]);
          this.selectDropdownService.close();
        }
      });
  }

  onValueChanges(): Subscription {
    return this.ngControl.valueChanges.subscribe((res) => {
      //reset search control after filters reset
      if (res === null || res?.length === 0) {
        this.search.reset();
      }
    });
  }
}
