import { FormControl, FormGroup } from '@angular/forms';
import { FilterParams, PaginationParams, SortingParams, TabParams } from '../filtering/filtering.interface';
import { InputOptionResource } from '@app/@feed-inputs';
import { DateString, DateTimeString } from '@shared/interfaces/date-string';
import { Id } from '@core/http/crud-model';
import { VehicleResource } from '@data/vehicles/vehicles.model';
import { WorkshopResource } from '@data/workshop/workshop.model';
import { NoteResource } from '@data/note/note.model';
import { EnumResource } from '@shared/interfaces/enum-resource';
import { DateTime } from 'luxon';

export interface ReservationsFilterParams extends PaginationParams, SortingParams, TabParams {
  vehicles: InputOptionResource;
  contact_details_phone_number: string;
  created_at_from: DateString;
  created_at_to: DateString;
  start_date: DateString;
  end_date: DateString;
  subfleets: string[];
  fleets: string[];
  workshops: string[];
  is_online: boolean;
  statuses: string[];
  types: string[];
}

export type ReservationsFilterParamsFormGroup = FormGroup<{
  vehicles: FormControl<InputOptionResource>;
  contact_details_phone_number: FormControl<string>;
  created_at_from: FormControl<DateString>;
  created_at_to: FormControl<DateString>;
  start_date: FormControl<DateString>;
  end_date: FormControl<DateString>;
  fleets: FormControl<string[]>;
  subfleets: FormControl<string[]>;
  workshops: FormControl<string[]>;
  is_online: FormControl<boolean>;
  statuses: FormControl<string[]>;
  types: FormControl<string[]>;
}>;

export interface DayResource {
  day_of_week: string;
  day_number: string;
  available_hours: AvailaleHoursResource;
}

export interface SelectedDayResource {
  day_of_week: string;
  day_number: number;
  hour: string;
}

export interface AvailaleHoursResource {
  before_noon: string[];
  afternoon: string[];
}

export interface ReservationResource {
  id: number;
  number: string;
  type: ReservationEventTypeResource;
  status: EnumResource<ReservationStatusValue>;
  status_occurred_at: DateTimeString;
  start_date: DateTimeString;
  end_date: DateTimeString;
  contact_details: ReservationContactDetails;
  processing_time: number; // INFO: czas procesowania w minutach
  delay_time: number; // INFO: czas opóźnienia
  time_to_approve: number; // INFO: czas pozostały do zatwierdzenia/odrzucenia w minutach
  vehicle: VehicleResource;
  workshop: WorkshopResource;
  workspace: ReservationWorkspaceResource;
  notes: NoteResource[];
  created_at: DateTimeString;
  updated_at: DateTimeString;
}

export type ReservationsFilterKey = 'relocations_workshop' | 'relocations';

export interface ReservationPostRequest {
  start_date: DateTimeString;
  end_date: DateTimeString;
  type: ReservationEventTypeResource;
  workshop_id: number;
  vehicle_id: number;
  contact_details: ReservationContactDetails;
  workspace: ReservationWorkspaceResource;
}

export type ReservationPutRequest = ReservationPostRequest & { status: ReservationStatusValue };
export type ReservationPatchRequest = Partial<ReservationPutRequest>;

export type ReservationStatusValue = 'approved' | 'pending' | 'expired' | 'cancelled';

export class ReservationStatus {
  static APPROVED: ReservationStatusValue = 'approved';
  static PENDING: ReservationStatusValue = 'pending';
  static EXPIRED: ReservationStatusValue = 'expired';
  static CANCELLED: ReservationStatusValue = 'cancelled';

  static getIcon(status: ReservationStatusValue): string {
    return new Map<ReservationStatusValue, string>([
      [ReservationStatus.APPROVED, 'pso:success_m'],
      [ReservationStatus.PENDING, 'pso:buffer_m'],
      [ReservationStatus.EXPIRED, 'pso:clock_supporting_3_m'],
      [ReservationStatus.CANCELLED, 'pso:failure_m'],
    ]).get(status);
  }
}

export interface ReservationContactDetails {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

export interface ReservationWorkspaceResource {
  id: number;
  name: string;
  available?: number;
}

export interface ReservationEventTypeResource {
  id: number;
  name: string;
  is_active: boolean;
}

export interface ReservationDayResource {
  date: DateString;
  closed: boolean;
  terms: ReservationDayTermResource[];
}

export interface ReservationDayTermResource {
  from: string;
  count: number;
  workspaces: number[];
}

export interface GetFreeDatesGetRequest extends FilterParams {
  workshop_id: number;
  start_date: DateString;
  end_date: DateString;
}

export interface ReservationWorkspacesGetRequest extends FilterParams {
  workshop_id: number;
}

export interface ReservationEventTypesGetRequest extends FilterParams {
  workshop_id: number;
}

export class ReservationDay {
  dateTime: DateTime;
  private readonly termsBeforeNoon: ReservationDayTerm[];
  private readonly termsAfterNoon: ReservationDayTerm[];

  constructor(private readonly resource: ReservationDayResource, private readonly terms: ReservationDayTerm[]) {
    this.dateTime = DateTime.fromFormat(resource.date, RESERVATION_BE_DATE_FORMAT);
    this.termsBeforeNoon = this.terms.filter((t) => t.isBeforeNoon());
    this.termsAfterNoon = this.terms.filter((t) => t.isAfterNoon());
  }

  getWeekdayNumber(): string {
    return this.dateTime.toFormat('dd');
  }

  getWeekdayName(): string {
    return this.dateTime.get('weekdayShort').toString();
  }

  isDisabled(): boolean {
    return this.resource.closed;
  }

  getRawDate(): string {
    return this.resource.date;
  }

  getTerms(): ReservationDayTerm[] {
    return this.terms;
  }

  getTermsBeforeNoon(): ReservationDayTerm[] {
    return this.termsBeforeNoon;
  }

  getTermsAfterNoon(): ReservationDayTerm[] {
    return this.termsAfterNoon;
  }
}

export const RESERVATION_BE_DATE_FORMAT = 'yyyy-MM-dd';

export class ReservationDayTerm {
  private readonly _isBeforeNoon: boolean;

  constructor(private readonly resource: ReservationDayTermResource, private readonly date: DateString) {
    this._isBeforeNoon = Number(this.resource.from.replace(':', '')) < 1200;
  }

  getHour(): string {
    return this.resource.from;
  }

  isBeforeNoon(): boolean {
    return this._isBeforeNoon;
  }

  isAfterNoon(): boolean {
    return !this.isBeforeNoon();
  }

  getValue(): DateTime {
    return DateTime.fromFormat(this.date + ' ' + this.getHour(), 'yyyy-MM-dd HH:mm');
  }
}

export const RESERVATION_PROCESSING_TIME_PSOA_IN_HOURS = 48;
export const RESERVATION_PROCESSING_TIME_WORKSHOP_IN_HOURS = 24;
