<div class="timepicker">
  <input
    (click)="openModal(inputReference)"
    wchfsInput
    #inputReference
    [disabled]="disabled"
    [readonly]="readonly"
    [placeholder]="readonly ? '-' : placeholder"
    [ngModel]="inputValue"
    (ngModelChange)="inputValueChange($event)"
    (blur)="onTouch()"
    wchfsTimeMask
  />
  <wchfs-icon svgIcon="wchfs:time_card_ico_s"></wchfs-icon>
</div>
