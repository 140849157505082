export type TimeType = TimeTypes.HH | TimeTypes.MM;

export enum TimeTypes {
  HH = 'HH',
  MM = 'MM',
}

export interface TimePickerType {
  type: TimeTypes;
  value: string;
}

export interface TimeObject {
  HH: string;
  MM: string;
}

export const TIME_PICKER_HOURS = 24;
export const TIME_PICKER_MINUTES = 60;
