import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingHandlerService } from './loader/loading-handler.service';

@Injectable({
  providedIn: 'root',
})
export class HttpStatusInterceptor implements HttpInterceptor {
  private loadingCalls = 0;

  constructor(private loadingStatusService: LoadingHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.changeLoadingStatus(true);
    return next.handle(req.clone()).pipe(
      finalize(() => {
        this.changeLoadingStatus(false);
      })
    );
  }

  private changeLoadingStatus(isCallingNewRequest: boolean): void {
    isCallingNewRequest ? this.loadingCalls++ : this.loadingCalls--;
    this.loadingStatusService.loading = this.loadingCalls > 0;
  }
}
