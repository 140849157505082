import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';
import { Roles } from '@core/Roles';
import { Survey } from '@data/survey/survey.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { ChannelType, EventToListen, HandlesWebsocketsEvent } from '@core/websockets/echo.model';
import { EchoService } from '@core/websockets/echo.service';
import { Router } from '@angular/router';
import { NotificationService } from '@shared/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService implements HandlesWebsocketsEvent {
  websocketEvent: EventToListen;

  public survey = new BehaviorSubject<Survey>(null);
  constructor(
    private store: Store,
    private echoService: EchoService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  setSurvey(): void {
    switch (this.store.selectSnapshot(ProfileState.routeRolePrefix)) {
      case Roles.PREFIX_AFL:
        // this.survey.next(
        //   new Survey(
        //     environment.google_forms_survey_id_afl,
        //     environment.google_forms_widget_text_afl,
        //     environment.google_forms_field_id_afl,
        //     640,
        //     1718
        //   )
        // );
        this.survey.next(null); // INFO: disable survey
        break;
      case Roles.PREFIX_WORKSHOP:
        // this.survey.next(
        //   new Survey(
        //     environment.google_forms_survey_id_workshop,
        //     environment.google_forms_widget_text_workshop,
        //     environment.google_forms_field_id_workshop,
        //     1000,
        //     1718
        //   )
        // );
        this.survey.next(null); // INFO: disable survey
        break;
      default:
        this.survey.next(null);
        break;
    }
  }

  reset(): void {
    this.survey.next(null);
  }

  setWebsocketEvent(): void {
    this.websocketEvent = {
      channel: {
        type: ChannelType.PRIVATE,
        name: `survey-status.${this.store.selectSnapshot(ProfileState.profileId)}`,
      },
      event: `survey.status.update`,
    };
  }

  listenForSurveyStatusChange(): void {
    this.echoService.listen(this.websocketEvent, (res) => {
      this.notificationService.notifySuccess('Dziękujemy za wypełnienie ankiety!');
      void this.router.navigate(['/']);
    });
  }

  stopListening(): void {
    this.echoService.echo.leave(this.websocketEvent?.channel?.name);
  }
}
