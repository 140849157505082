import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NavContentComponent } from './nav-content/nav-content.component';
import { NavDecreaseButtonComponent } from './nav-decrease-button/nav-decrease-button.component';
import { NavHeaderItemComponent } from './nav-header-item/nav-header-item.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { NavListItemComponent } from './nav-list-item/nav-list-item.component';
import { NavListComponent } from './nav-list/nav-list.component';
import { NavLogoComponent } from './nav-logo/nav-logo.component';
import { NavSublistItemComponent } from './nav-sublist-item/nav-sublist-item.component';
import { NavSublistTitleComponent } from './nav-sublist-title/nav-sublist-title.component';
import { NavSublistComponent } from './nav-sublist/nav-sublist.component';
import { NavUpListItemComponent } from './nav-up-list-item/nav-up-list-item.component';
import { NavUpListComponent } from './nav-up-list/nav-up-list.component';
import { NavComponent } from './nav/nav.component';

const components = [
  NavComponent,
  NavContentComponent,
  NavDecreaseButtonComponent,
  NavHeaderComponent,
  NavListComponent,
  NavUpListComponent,
  NavListItemComponent,
  NavLogoComponent,
  NavHeaderItemComponent,
  NavSublistComponent,
  NavSublistItemComponent,
  NavSublistTitleComponent,
  NavUpListItemComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, SvgIconModule],
  exports: [...components],
})
export class NavModule {}
