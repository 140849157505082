import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InvoicesPanelStateModel, SetVisibleColumns } from './invoices-panel.actions';

@State<InvoicesPanelStateModel>({
  name: 'invoicesPanel',
  defaults: {
    visibleColumns: [],
  },
})
@Injectable()
export class InvoicesPanelState {
  @Selector()
  static visibleColumns(state: InvoicesPanelStateModel): string[] {
    return state.visibleColumns;
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<InvoicesPanelStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }
}
