<div class="month-wrapper">
  <div
    class="month"
    (click)="onMonthClick($event)"
    (keyup.enter)="onMonthClick($event)"
    (keydown)="onKeydown($event)"
    role="listbox"
    tabindex="-1"
  >
    <time
      class="month__date"
      *ngFor="let dayOfMonth of daysOfMonth"
      [dateTime]="dayOfMonth | date: dateFormat"
      [attr.aria-label]="dayOfMonth | date: 'fullDate':undefined:locale"
      [class.month__date--selected]="isSelected(dayOfMonth)"
      [attr.aria-selected]="isSelected(dayOfMonth)"
      [class.month__date--disabled]="isDisabled(dayOfMonth)"
      [attr.aria-disabled]="isDisabled(dayOfMonth)"
      [class.month__date--active]="isActive(dayOfMonth)"
      [tabIndex]="isActive(dayOfMonth) ? 0 : -1"
      [attr.aria-current]="!isCurrent(dayOfMonth) && 'date'"
      [class.month__date--current]="isCurrent(dayOfMonth)"
      [attr.cdkFocusInitial]="isActive(dayOfMonth) ? '' : null"
      [class.month__date--other]="isOtherMonth(dayOfMonth)"
      [class.month__date--in-range]="isRangePicker && isInRange(dayOfMonth)"
      [class.month__date--in-pre-range]="isRangePicker && isInPreRange(dayOfMonth)"
      (mouseover)="setDayOnHover(dayOfMonth)"
      role="option"
      >{{ dayOfMonth | date: 'd' }}</time
    >
  </div>
</div>
