<wchfs-form-field>
  <wchfs-label>{{ label | translate }}</wchfs-label>
  <ng-container *ngIf="data$ | async as data; else loadingControlBlock">
    <app-base-single-select
      [formControl]="control"
      [data]="data"
      [placeholder]="'choose-and-search' | translate"
      [config]="{
        search: true
      }"
    ></app-base-single-select>
  </ng-container>
  <ng-template #loadingControlBlock>
    <div class="loading-control-block"></div>
  </ng-template>
</wchfs-form-field>
