<div
  class="select"
  #select
  (click)="onInputClick()"
  [class.select--focused]="focus$ | async"
  [class.select--readonly]="readonly"
  [class.select--disabled]="disabled"
>
  <div *ngIf="config.prefixTemplate" class="select__prefix">
    <ng-container *ngTemplateOutlet="config.prefixTemplate"></ng-container>
  </div>

  <span class="select__display-value" *ngIf="displayValue; else placeholderBlock">{{ displayValue }}</span>
  <ng-template #placeholderBlock>
    <span class="select__placeholder">{{ placeholder }}</span>
  </ng-template>
  <div class="select__suffix" *ngIf="!readonly && !disabled">
    <wchfs-icon
      *ngIf="value || value === false"
      (click)="onXIconClick(); $event.stopPropagation()"
      [svgIcon]="'wchfs:chip-cross'"
      class="cross"
    ></wchfs-icon>
    <wchfs-icon (click)="onArrowIconClick()" [svgIcon]="icon$ | async"></wchfs-icon>
  </div>
</div>

<ng-template #options>
  <div class="select-dropdown">
    <div class="select-dropdown__search" *ngIf="config.search">
      <wchfs-search-input
        [formControl]="search"
        [debounce]="600"
        [placeholder]="'search' | translate"
      ></wchfs-search-input>
    </div>

    <div class="select-dropdown__loader" *ngIf="itemsLoading$ | async">Loading...</div>

    <ng-container *ngFor="let item of filteredItems$ | async; let i = index">
      <div
        class="select-dropdown__item"
        [style.background-color]="item.is(value) ? '#0d7cb6' : null"
        [style.color]="item.is(value) ? 'white' : null"
        wchfsPadding="0 0 0 2"
        *ngIf="showModeClicked || (this.config.showMoreIdx ? config.showMoreIdx && i < config.showMoreIdx : true)"
        (click)="onItemClick(item)"
      >
        {{ item.getLabel() }}
      </div>

      <div
        class="select-dropdown__separator"
        *ngIf="this.config.showMoreIdx ? i === config.showMoreIdx - 1 : false"
      ></div>

      <div
        class="select-dropdown__item"
        wchfsPadding="0 0 0 2"
        *ngIf="!showModeClicked && this.config.showMoreIdx ? i === config.showMoreIdx : false"
        (click)="showModeClicked = true"
      >
        {{ 'see-all' | translate }}
      </div>
    </ng-container>

    <div class="select-dropdown__empty" *ngIf="emptyFilteringResults$ | async">{{ 'no-entries' | translate }}</div>
  </div>
</ng-template>
