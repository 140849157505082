export interface SnackBarAction {
  actionMessage: string;
  handler: () => void;
}

export class SnackBarType {
  public static ERROR: SnackbarTypeName = 'error';
  public static SUCCESS: SnackbarTypeName = 'success';
  public static WARNING: SnackbarTypeName = 'warning';
  public static INFO: SnackbarTypeName = 'info';
}

export class SnackbarColors {
  public static ERROR_COLOR = '#e5322c';
  public static SUCCESS_COLOR = '#68bb4b';
  public static WARNING_COLOR = '#fab900';
  public static INFO_COLOR = '#5e92fc';

  public static HEX_COLORS = new Map([
    [SnackBarType.ERROR, SnackbarColors.ERROR_COLOR],
    [SnackBarType.SUCCESS, SnackbarColors.SUCCESS_COLOR],
    [SnackBarType.WARNING, SnackbarColors.WARNING_COLOR],
    [SnackBarType.INFO, SnackbarColors.INFO_COLOR],
  ]);

  static hex(type: SnackbarTypeName): string {
    return this.HEX_COLORS.get(type);
  }
}

export type SnackbarTypeName = 'error' | 'success' | 'warning' | 'info';
