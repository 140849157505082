import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wchfs-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
