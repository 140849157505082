import { Component, HostBinding, Input } from '@angular/core';
import { Colors } from '../../shared/colors/colors.service';

@Component({
  selector: 'wchfs-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input() color: Colors;
  @Input() textColor: Colors;
  @Input() type: BadgeTypes = BadgeTypes.STANDALONE;

  constructor() {}

  @HostBinding('class') get getClassByType() {
    return `wchfs-badge-${this.type}`;
  }
}

export enum BadgeTypes {
  STANDALONE = 'standalone',
  ICON = 'icon',
  NOTIFICATION = 'notification',
}
