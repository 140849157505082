import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export interface VehiclesStateModel {
  visibleColumns: string[];
  activeTab: ListTabWithColumns;
  tab_slug: string;
}

export class SetActiveVehicle {
  static readonly type = '[Vehicles] Set Active Vehicle';

  constructor(public id: number) {}
}

export class SetVisibleColumns {
  static readonly type = '[Vehicles] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[Vehicles] Set Active Tab';

  constructor(public tab: ListTabWithColumns) {}
}
export class SetTabSlug {
  static readonly type = '[Vehicles] Set Tab Slug';

  constructor(public slug: string) {}
}
