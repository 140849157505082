import { Injectable } from '@angular/core';
import { ReservationsNotTakenModalComponent } from '@pages-and-modals/reservations-not-taken-modal/reservations-not-taken-modal.component';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ReservationNotTakenAlertModalAction,
  ReservationsNotTakenModalAction,
} from '@pages-and-modals/reservations-not-taken-modal/reservations-not-taken-modal.model';
import { GenericModalComponent } from '@pso-ui/modal/generic-modal/generic-modal.component';
import { GenericModalConfig, ModalType, PSOButtons } from '@pso-ui/modal';
import { Id } from '@core/http/crud-model';
import { ModalService } from '@shared/services/modal.service';
import { EditReservationDateComponent } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ReservationsNotTakenModalService {
  constructor(private modalService: ModalService) {}

  openModal(): Observable<any> {
    return this.modalService
      .open<
        null,
        {
          action: ReservationsNotTakenModalAction;
          data: unknown;
        }
      >(ReservationsNotTakenModalComponent, ReservationsNotTakenModalComponent.CONFIG)
      .pipe(
        switchMap((action: { action: ReservationsNotTakenModalAction; data: unknown }) => {
          switch (action.action) {
            case ReservationsNotTakenModalAction.ALERT: {
              return this.openAlertModal();
            }
            case ReservationsNotTakenModalAction.EDIT: {
              return this.openEditModal(action.data as Id);
            }
            case ReservationsNotTakenModalAction.NOTHING: {
              return of(true);
            }
          }
        })
      );
  }

  private openAlertModal(): Observable<ReservationNotTakenAlertModalAction> {
    return this.modalService
      .open<GenericModalConfig, ReservationNotTakenAlertModalAction>(
        GenericModalComponent,
        { width: '600px', disableClose: true },
        {
          type: ModalType.ALERT,
          title: 'Zamknij przypomnienie',
          text: 'Terminowość potwierdzania rezerwacji wpływa na ocenę Serwisu. Czy na pewno chcesz zamknąć to okno?',
          buttons: [
            {
              label: 'close',
              action: ReservationNotTakenAlertModalAction.CLOSE,
              type: PSOButtons.GHOST,
              size: 's',
            },
            {
              label: 'Nie zamykaj',
              action: ReservationNotTakenAlertModalAction.DO_NOT_CLOSE,
              color: 'primary-b-blue',
              type: PSOButtons.DEFAULT,
              size: 's',
            },
          ],
        }
      )
      .pipe(
        switchMap((action: ReservationNotTakenAlertModalAction) => {
          switch (action) {
            case ReservationNotTakenAlertModalAction.DO_NOT_CLOSE: {
              return this.openModal();
            }
            default: {
              return of(undefined);
            }
          }
        })
      );
  }

  private openEditModal(id: Id): Observable<boolean> {
    return this.modalService
      .open(
        EditReservationDateComponent,
        {
          ...EditReservationDateComponent.CONFIG,
          ...{ disableClose: true },
        },
        id
      )
      .pipe(switchMap(() => this.openModal()));
  }
}
