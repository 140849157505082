<input
  #input
  [placeholder]="placeholder"
  [value]="selected"
  [customErrors]="customErrors"
  type="text"
  (keyup.enter)="enterKeyPressed.emit()"
  wchfsInput
/>
<wchfs-icon *ngIf="!selected" [svgIcon]="'wchfs:search'" class="input-icon"></wchfs-icon>
<wchfs-icon (click)="clearSelected()" *ngIf="selected" [svgIcon]="'wchfs:chip-cross'" class="input-icon"></wchfs-icon>
