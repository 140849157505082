<app-signal-box
  *ngIf="editReservationDateModalService.reservation?.contact_details?.phone_number as phoneNumber"
  [type]="SignalBoxType.ERROR"
  [heading]="'UWAGA!'"
  [textLine1]="'Przed zmianą terminu skontaktuj się z klientem.'"
  [textLine2]="
    'Następnie kliknij przycisk “WPROWADŹ UZGODNIONY TERMIN” i podaj datę oraz godzinę uzgodnioną z klientem.'
  "
></app-signal-box>

<app-heading>Dane rezerwacji</app-heading>

<div class="data">
  <app-header-and-bold-data
    [bold]="editReservationDateModalService.reservation?.vehicle?.registration_number"
    [header]="'vehicle' | translate"
    [size]="'l'"
  ></app-header-and-bold-data>

  <app-header-and-bold-data
    [bold]="editReservationDateModalService.reservation?.vehicle?.fleet?.short_name"
    [header]="'fleet' | translate"
    [size]="'l'"
  ></app-header-and-bold-data>

  <app-header-and-bold-data
    [bold]="editReservationDateModalService.reservation?.contact_details?.phone_number"
    [header]="'phone' | translate"
    [size]="'l'"
  ></app-header-and-bold-data>
</div>

<div class="grey-header">Aktualny termin</div>

<app-grey-box-with-big-text
  *ngIf="editReservationDateModalService.reservation?.start_date"
  [textLine1Small]="ucfirst(editReservationDateModalService.reservation?.start_date | date: 'EEEE') + ', '"
  [textLine1Big]="editReservationDateModalService.reservation?.start_date | date: 'dd.MM.yyyy'"
  [textLine2Small]="'Godz. '"
  [textLine2Big]="editReservationDateModalService.reservation?.start_date | date: 'HH:mm'"
></app-grey-box-with-big-text>
<app-modal-buttons-group>
  <button pso-outline-button (click)="closeButtonClicked.emit()">{{ 'cancel' | translate | uppercase }}</button>
  <button
    pso-button
    (click)="confirmButtonClicked.emit()"
    [color]="'primary-b-blue'"
    [loading]="editReservationDateModalService.loading"
  >
    {{ 'Wprowadź uzgodniony termin' | uppercase }}
  </button>
</app-modal-buttons-group>
