import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FleetInvoicesStateModel, SetActiveTab, SetVisibleColumns } from './fleet-invoices.actions';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

@State<FleetInvoicesStateModel>({
  name: 'fleetInvoices',
  defaults: {
    visibleColumns: [],
    activeTab: null,
  },
})
@Injectable()
export class FleetInvoicesState {
  @Selector()
  static visibleColumns(state: FleetInvoicesStateModel): string[] {
    return state.visibleColumns;
  }

  @Selector()
  static activeTab(state: FleetInvoicesStateModel): ListTabWithColumns {
    return state?.activeTab;
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<FleetInvoicesStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<FleetInvoicesStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }
}
