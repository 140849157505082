import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { PsoUiModule } from '@pso-ui/pso-ui.module';
import { CalendarDateTimePickerComponent } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.component';

@NgModule({
  declarations: [CalendarDateTimePickerComponent],
  imports: [CommonModule, SharedModule, PsoUiModule],
  exports: [CalendarDateTimePickerComponent],
})
export class CalendarDateTimePickerModule {}
