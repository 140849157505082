import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimepickerService } from '../timepicker.service';
import { TimeObject, TimePickerType, TimeTypes } from '../timpicker.constans';

@Component({
  selector: 'wchfs-time-dropdown',
  templateUrl: './time-dropdown.component.html',
  styleUrls: ['./time-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeDropdownComponent implements OnInit, OnDestroy {
  timepickerServiceReference: TimepickerService;
  currentTime!: TimeObject;
  timeTypes = TimeTypes;
  unsubscribe$: Subject<void> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.timepickerServiceReference?.timeObject$.pipe(takeUntil(this.unsubscribe$.asObservable())).subscribe((time) => {
      this.currentTime = time;
      this.cdr.markForCheck();
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
  closeModal() {
    this.timepickerServiceReference.closeModal();
  }
  pickerValueChange(payload: TimePickerType) {
    this.timepickerServiceReference.updateTimeObject(payload);
  }
}
