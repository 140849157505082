import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dataCy]',
})
export class DataCyDirective implements OnInit {
  @Input() dataCy: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngOnInit(): void {
    // if (!environment.production) {
    //   this.renderer.setAttribute(this.el.nativeElement, 'data-cy', this.dataCy);
    // }
    this.renderer.setAttribute(this.el.nativeElement, 'data-cy', this.dataCy);
  }
}
