import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProfileState } from '@data/profile/profile-state';
import { Observable } from 'rxjs';
import { RouteRolePrefix } from '@core/Roles';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-routing',
  templateUrl: './home-routing.component.html',
  styleUrls: ['./home-routing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeRoutingComponent implements OnInit {
  @Select(ProfileState.routeRolePrefixWithSlashes)
  prefix$: Observable<RouteRolePrefix>;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.prefix$.subscribe((routeRolePrefix: RouteRolePrefix) => {
      void this.router.navigateByUrl(routeRolePrefix);
    });
  }
}
