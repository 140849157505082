import { Injectable } from '@angular/core';
import { ReservationDay, ReservationDayTerm, ReservationDayResource } from '@data/reservations/reservations.model';
import { DateTime } from 'luxon';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subject, throwError } from 'rxjs';
import { CalendarDateTimePickerDataProvider } from '@shared/modules/calendar-date-time-picker/calendar-date-time-picker.model';
import { catchError, map, retry, share, tap } from 'rxjs/operators';
import { ucfirst } from '@shared/utils/ucfirst';

@Injectable()
export class CalendarDateTimePickerService {
  id: number;
  startDate: DateTime;
  selectedMonth: string;
  selectedYear: string;
  days: ReservationDay[];
  selectedDay: ReservationDay;
  selectedTerm: ReservationDayTerm;
  markForCheck$ = new Subject();
  loading: boolean;
  private daySelection = new SelectionModel<string>();
  private datesService: CalendarDateTimePickerDataProvider;

  constructor() {}

  refresh(id: number, startDate: DateTime): Observable<ReservationDayResource[]> {
    this.loading = true;
    this.id = id;
    this.startDate = startDate;
    this.selectedMonth = ucfirst(this.startDate.get('monthLong').toString());
    this.selectedYear = this.startDate.get('year').toString();
    const hour = this.startDate.toFormat('HH:mm');
    let selectedTerm: ReservationDayTerm;
    let selectedDay: ReservationDay;
    return this.datesService.getFreeDates(id, startDate).pipe(
      catchError((err) => {
        this.loading = false;
        return throwError(err);
      }),
      tap((res: ReservationDayResource[]) => {
        this.days = res.map((resource) => {
          const day = new ReservationDay(
            resource,
            resource.terms.map((term) => new ReservationDayTerm(term, resource.date))
          );

          if (
            day.dateTime.hasSame(this.startDate, 'year') &&
            day.dateTime.hasSame(this.startDate, 'month') &&
            day.dateTime.hasSame(this.startDate, 'day') &&
            !day.isDisabled()
          ) {
            selectedDay = day;
            selectedTerm = day.getTerms().find((t) => t.getHour() === hour);
          }

          return day;
        });
        if (selectedDay) {
          this.selectDay(selectedDay);
        }
        if (selectedTerm) {
          this.selectTerm(selectedTerm);
        }
        this.loading = false;
        this.markForCheck$.next();
      })
    );
  }

  selectTerm(term: ReservationDayTerm): void {
    this.selectedTerm = term;
    this.markForCheck$.next();
  }

  clearSelectedDay(): void {
    this.selectedDay = undefined;
    this.markForCheck$.next();
  }

  previousMonth(): void {
    const startDate = this.startDate.minus({ month: 1 }).startOf('month');
    this.clearSelectedDay();
    this.clearSelectedTerm();
    this.refresh(this.id, startDate).subscribe();
  }

  nextMonth(): void {
    const startDate = this.startDate.plus({ month: 1 }).startOf('month');
    this.clearSelectedDay();
    this.clearSelectedTerm();
    this.refresh(this.id, startDate).subscribe();
  }

  previousDays(): void {
    const startDate = this.startDate.minus({ day: 7 });
    this.clearSelectedDay();
    this.clearSelectedTerm();
    this.refresh(this.id, startDate).subscribe();
  }

  nextDays(): void {
    const startDate = this.startDate.plus({ day: 7 });
    this.clearSelectedDay();
    this.clearSelectedTerm();
    this.refresh(this.id, startDate).subscribe();
  }

  selectDay(day: ReservationDay): void {
    this.daySelection.select(day.getRawDate());
    this.selectedDay = day;
    this.clearSelectedTerm();
    this.markForCheck$.next();
  }

  isSelected(day: ReservationDay): boolean {
    return this.daySelection.isSelected(day.getRawDate());
  }

  clearSelectedTerm(): void {
    this.selectedTerm = undefined;
    this.markForCheck$.next();
  }

  setDatesService(value: CalendarDateTimePickerDataProvider): void {
    this.datesService = value;
  }

  setId(value: number): void {
    this.id = value;
  }

  private clearDays(): void {
    this.days = undefined;
  }
}
