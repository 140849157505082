import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  DispositionsStateModel,
  ResetDispositionState,
  SetActiveTab,
  SetVisibleColumns,
} from '@data/dispositions/dispositions.actions';
import { Injectable } from '@angular/core';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

const DEFAULTS: DispositionsStateModel = {
  visibleColumns: [],
  activeTab: null,
  tab_slug: '',
};

@State<DispositionsStateModel>({
  name: 'dispositions',
  defaults: DEFAULTS,
})
@Injectable()
export class DispositionsState {
  @Selector()
  static visibleColumns(state: DispositionsStateModel): string[] {
    return state.visibleColumns;
  }

  @Selector()
  static activeTab(state: DispositionsStateModel): ListTabWithColumns<{ full: string[]; limited: string[] }> {
    return state.activeTab;
  }

  @Selector()
  static tabLimitedColumns(state: DispositionsStateModel): string[] {
    return state?.activeTab?.columns?.limited || [];
  }

  @Selector()
  static tabFullColumns(state: DispositionsStateModel): string[] {
    return state?.activeTab?.columns?.full || [];
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<DispositionsStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(SetActiveTab)
  setActiveTab(ctx: StateContext<DispositionsStateModel>, action: SetActiveTab): void {
    ctx.patchState({
      activeTab: action.tab,
    });
  }

  @Action(ResetDispositionState)
  resetDispositionState(ctx: StateContext<DispositionsStateModel>, action: ResetDispositionState): void {
    ctx.setState(DEFAULTS);
  }
}
