import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SignalBoxComponent } from '@pso-ui/signal-box/signal-box.component';
import { NgIf } from '@angular/common';
import { SignalBoxType } from '@pso-ui/signal-box/signal-box.model';
import { EditReservationDateModalService } from '@pages-and-modals/edit-reservation-date/edit-reservation-date-modal.service';
import { HeadingModule } from '@pso-ui/heading/heading.module';
import { PsoUiModule } from '@pso-ui/pso-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GreyBoxWithBigTextComponent } from '@pso-ui/grey-box-with-big-text/grey-box-with-big-text.component';
import { ucfirst } from '@shared/utils/ucfirst';

@Component({
  selector: 'app-edit-reservation-date-step-one',
  templateUrl: './edit-reservation-date-step-one.component.html',
  styleUrls: ['./edit-reservation-date-step-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SignalBoxComponent, NgIf, HeadingModule, PsoUiModule, TranslateModule, GreyBoxWithBigTextComponent],
})
export class EditReservationDateStepOneComponent implements OnInit, OnDestroy {
  @Output() confirmButtonClicked = new EventEmitter<void>();
  @Output() closeButtonClicked = new EventEmitter<void>();
  protected readonly SignalBoxType = SignalBoxType;
  protected readonly ucfirst = ucfirst;
  private subscription = new Subscription();

  constructor(
    public readonly editReservationDateModalService: EditReservationDateModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.listenMarkForCheck());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private listenMarkForCheck(): Subscription {
    return this.editReservationDateModalService.markForCheck$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }
}
