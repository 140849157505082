import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { ActionsSnackBarComponent } from './actions-snack-bar/actions-snack-bar.component';
import { OnlyMessageSnackBarComponent } from './only-message-snack-bar/only-message-snack-bar.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [OnlyMessageSnackBarComponent, ActionsSnackBarComponent],
  imports: [CommonModule, SvgIconModule, ButtonModule],
})
export class SnackBarsModule {}
