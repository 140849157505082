import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RangeTime } from '../calendar.interface';
import { DayPickerService } from '../day-picker/day-picker.service';

@Component({
  selector: 'wchfs-calendar-actions',
  templateUrl: './calendar-actions.component.html',
  styleUrls: ['./calendar-actions.component.scss'],
})
export class CalendarActionsComponent {
  @Input() value: Date;
  @Input() isRangePicker = false;
  @Output() selectDayChange = new EventEmitter<void>();
  @Output() selectTodayChange = new EventEmitter<void>();
  @Output() selectRangePicker = new EventEmitter<RangeTime>();
  @Output() cancelRangePicker = new EventEmitter<void>();

  selectedRange$ = this.dayPickerService.selectedRange$;

  constructor(private dayPickerService: DayPickerService) {}

  selectToday() {
    this.selectTodayChange.emit();
  }

  selectDate() {
    this.selectDayChange.emit();
  }

  selectRange() {
    this.selectRangePicker.emit(this.dayPickerService.getSelectedRangeSnapshot());
  }

  cancelRange() {
    this.cancelRangePicker.emit();
  }
}
