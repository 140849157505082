import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'wchfs-draggable-table',
  templateUrl: './draggable-table.component.html',
  styleUrls: ['./draggable-table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // TODO: hotfix - this breaks initial styles and looks ugly
})
export class DraggableTableComponent {
  @Output() activeColumnsChange = new EventEmitter<ColumnData[] | unknown[]>();
  // @Output() currentColumnsState = new EventEmitter<CurrentColumnsState>();
  @Input() customKeyValue: string = null;
  @Input() title = 'Available columns';
  @Input() placeholder = 'Search...';

  @Input()
  set activeColumns(value: ColumnData[]) {
    this._activeColumns = value;
    this.changeDetectorRef.markForCheck();
  }

  get activeColumns() {
    return this._activeColumns;
  }

  @Input()
  set columns(value: ColumnData[]) {
    this._columns = value;
    this.changeDetectorRef.markForCheck();
  }

  get columns() {
    return this._columns;
  }

  tableColumn = this.fb.group({
    activeColumn: [],
    availableColumn: [],
    column: [],
  });

  // @ts-ignore
  private _activeColumns = [];
  // @ts-ignore
  private _columns = [];

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  // removeChipByClick(itemName: ColumnData) {
  //   this.availableColumns = [
  //     ...this.activeColumns.splice(this.activeColumns.indexOf(itemName), 1),
  //     ...this.availableColumns,
  //   ];
  // }

  clearInputSearch(controlName: string) {
    this.tableColumn.controls[controlName].setValue('', {
      emitEvent: false,
    });
  }

  moveOrTransfer(event: CdkDragDrop<ColumnData[] | unknown[]>) {
    const pureindex = event.previousContainer.data.findIndex((item) => item === event.item.data);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, pureindex, event.currentIndex);
    }
    this.activeColumnsChange.emit(this.activeColumns);
    // this.currentColumnsState.emit({
    //   activeColumns: this.activeColumns,
    //   availableColumns: this.availableColumns,
    // });
  }

  switcherValueChanged(checked: boolean, { value }: ColumnData) {
    this.activeColumns = this.columns
      .map((column) => {
        if (column.value === value) {
          column.active = checked;
        }

        return column;
      })
      .filter((column) => column.active);

    this.activeColumnsChange.emit(this.activeColumns);
  }
}

export interface ColumnData {
  value: string;
  label?: string | undefined;
  active: boolean;
}

export interface CurrentColumnsState {
  activeColumns: ColumnData[] | unknown[];
  availableColumns: ColumnData[] | unknown[];
}
