import { FilteringModelValue, FilterParams } from '@app/@data/filtering/filtering.interface';
import { InputOptionResource } from '@app/@feed-inputs';

export const prepareGetRequestParams = (params: object): FilterParams => {
  let filteredParams = {};

  // INFO 1: usunięcie wartości pustych lub undefined
  Object.keys(params).forEach((key) => {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null) {
      filteredParams[key] = params[key] as FilteringModelValue;
    }
  });

  // INFO 2: spłaszczenie wartości tablicy
  Object.keys(filteredParams).forEach((key) => {
    if (Array.isArray(filteredParams[key])) {
      filteredParams[key] = (filteredParams[key] as []).join();
    }
  });

  // INFO 3: wszystkie InputOptionResource na id
  Object.keys(filteredParams).forEach((key) => {
    if (typeof filteredParams[key] === 'object' && filteredParams[key] !== null) {
      if ((filteredParams[key] as InputOptionResource).id) {
        filteredParams[key] = (filteredParams[key] as InputOptionResource).id;
      } else {
        delete filteredParams[key];
      }
    }
  });

  return filteredParams as FilterParams;
};
