import { DispositionResource } from '@data/dispositions/dispositions.model';
import { DispositionItemPatchData } from '@data/dispositions/disposition-details.model';
import { Id } from '@core/http/crud-model';

export class DispositionDetailsSetDisposition {
  static readonly type = '[Disposition Details] Set Disposition';

  constructor(public disposition: DispositionResource) {}
}

export class DispositionDetailsGetDisposition {
  static readonly type = '[Disposition Details] Get Disposition';

  constructor(public id: Id) {}
}

export class DispositionDetailsReset {
  static readonly type = '[Disposition Details] Reset';
}

export class DispositionDetailsSetDispositionItemPatchData {
  static readonly type = '[Disposition Details] Set Disposition Item Patch Data';

  constructor(public dispositionItemPatchData: DispositionItemPatchData) {}
}

export class DispositionDetailsSetEditMode {
  static readonly type = '[Disposition Details] Set Disposition Set Edit Mode';

  constructor(public mode: boolean) {}
}

export class DispositionDetailsSetLoading {
  static readonly type = '[Disposition Details] Set Disposition Set Loading';

  constructor(public loading: boolean) {}
}
