import { NgModule } from '@angular/core';
import { BaseSelectComponent } from './core/base-select.component';
import { BaseSingleSelectComponent } from './core/base-single-select.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseMultiSelectComponent } from './core/base-multi-select.component';
import { WchfsUiModule } from '@app/@wchfs-ui/lib/wchfs-ui.module';

@NgModule({
  declarations: [BaseSelectComponent, BaseSingleSelectComponent, BaseMultiSelectComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, WchfsUiModule],
  exports: [BaseSelectComponent, BaseSingleSelectComponent, BaseMultiSelectComponent],
})
export class SelectModule {}
