import { Id } from '@app/@core/http/crud-model';
import { MetaResource } from '@shared/interfaces/paged-resource';
import { TyreResource } from '@data/tyres/tyres.model';
import { ListTabWithColumns } from '@shared/interfaces/list-tab';

export interface DepositsStateModel {
  activeDeposit: TyreResource;
  visibleColumns: string[];
  activeTab: ListTabWithColumns;
  tab_slug: string;
}

export class SetActiveDeposit {
  static readonly type = '[Deposits] Set Active Deposit';

  constructor(public id: Id) {}
}

export class UnsetActiveDeposit {
  static readonly type = '[Deposits] Unset Active Deposit';
}

export class SetVisibleColumns {
  static readonly type = '[Deposits] Set Visible Columns';

  constructor(public columns: string[]) {}
}

export class SetActiveTab {
  static readonly type = '[Deposits] Set Active Tab';

  constructor(public tab: ListTabWithColumns) {}
}

export class SetMeta {
  static readonly type = '[Deposits] Set Meta';

  constructor(public meta: MetaResource) {}
}

export class SetTabSlug {
  static readonly type = '[Deposits] Set Tab Slug';

  constructor(public slug: string) {}
}

export class ResetDepositState {
  static readonly type = '[Deposits] Reset Deposit State';
}
