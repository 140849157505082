export interface RequestsParamsStateModel {
  page: number;
  per_page: number;
  tab_slug: string;
}

export class SetPerPage {
  static readonly type = '[Dispositions Params] Set Per Page';

  constructor(public perPage: number) {}
}

export class SetPage {
  static readonly type = '[Dispositions Params] Set Page';

  constructor(public page: number) {}
}

export class SetTabSlug {
  static readonly type = '[Dispositions Params] Set Tab Slug';

  constructor(public slug: string) {}
}
