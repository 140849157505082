import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { AuthStateModel, ClearCredentials, Login, Logout, SetImpersonatingAs, UpdateCredentials } from './auth.actions';
import { AuthService } from './auth.service';
import { Credentials } from './auth.interface';
import { Observable } from 'rxjs';
import { DataResource, MessageResource } from '@shared/interfaces/data-resource';
import { NotificationService } from '@shared/services/notification.service';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token_type: null,
    expires_in: null,
    access_token: null,
    refresh_token: null,
    impersonating_as: null,
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.access_token;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string {
    return state.access_token || '';
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login): Observable<Credentials> {
    return this.authService.login(action.payload).pipe(
      tap((result: Credentials) => {
        ctx.patchState({
          token_type: result.token_type,
          expires_in: result.expires_in,
          access_token: result.access_token,
          refresh_token: result.refresh_token,
          impersonating_as: null,
        });
      })
    );
  }

  @Action(UpdateCredentials)
  updateCredentials(ctx: StateContext<AuthStateModel>, action: DataResource<AuthStateModel>): AuthStateModel {
    return ctx.setState({
      token_type: action.data.token_type,
      expires_in: action.data.expires_in,
      access_token: action.data.access_token,
      refresh_token: action.data.refresh_token,
      impersonating_as: null,
    });
  }

  @Action(SetImpersonatingAs)
  impersonatingAs(ctx: StateContext<AuthStateModel>, action: SetImpersonatingAs): AuthStateModel {
    return ctx.patchState({
      impersonating_as: action.impersonatingAs,
    });
  }

  @Action(ClearCredentials)
  clearCredentials(ctx: StateContext<AuthStateModel>): void {
    ctx.setState({
      token_type: null,
      expires_in: null,
      access_token: null,
      refresh_token: null,
      impersonating_as: null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>): Observable<MessageResource> {
    return this.authService.logout().pipe(
      finalize(() => {
        ctx.setState({
          token_type: null,
          expires_in: null,
          access_token: null,
          refresh_token: null,
          impersonating_as: null,
        });
      }),
      tap((result) => {
        this.notificationService.notifySuccess(result.message);
        ctx.setState({
          token_type: null,
          expires_in: null,
          access_token: null,
          refresh_token: null,
          impersonating_as: null,
        });
      })
    );
  }

  constructor(private authService: AuthService, private notificationService: NotificationService) {}
}
