import { Component, EventEmitter, Input, Output } from '@angular/core';

import { addMonths, setYear, startOfMonth } from '../date-utils';
import { CalendarTypes, NUMBER_OF_YEARS_ON_PAGE } from '../calendar.constants';

@Component({
  selector: 'wchfs-month-header',
  templateUrl: './month-header.component.html',
  styleUrls: ['./month-header.component.scss'],
})
export class MonthHeaderComponent {
  @Input() dateInStepper = startOfMonth(new Date());
  @Input() activeMonth?: Date = startOfMonth(new Date());
  @Input() showMonthStepper = true;
  @Input() locale?: string;
  @Input() calendarType: CalendarTypes;

  @Output() activeMonthChange = new EventEmitter<Date>();
  @Output() changeCalendarType = new EventEmitter<CalendarTypes>();

  stepMonth<Delta extends number>(delta: Delta) {
    if (this.calendarType === CalendarTypes.days) {
      const activateHeaderDate = addMonths(this.activeMonth || new Date(), delta);
      this.activeMonthChange.emit(activateHeaderDate);
    } else if (this.calendarType === CalendarTypes.onlyMonths) {
      const year = this.dateInStepper.getFullYear();
      this.dateInStepper = setYear(this.dateInStepper, year + delta);
      this.activeMonthChange.emit(this.dateInStepper);
    } else if (this.calendarType === CalendarTypes.onlyYears) {
      const year = this.dateInStepper.getFullYear();
      this.dateInStepper = setYear(this.dateInStepper, year + NUMBER_OF_YEARS_ON_PAGE * delta);
      this.activeMonthChange.emit(this.dateInStepper);
    }
  }

  openMonthCalendar() {
    this.changeCalendarType.emit(CalendarTypes.onlyMonths);
  }

  openYearCalendar() {
    this.changeCalendarType.emit(CalendarTypes.onlyYears);
  }
}
