import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingDirective } from '../directives/loading.directive';
import { FormModule } from '../form/form.module';
import { LazyTabDirective } from './lazy-tab.directive';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabComponent } from './tab/tab.component';

@NgModule({
  declarations: [TabGroupComponent, TabComponent, LazyTabDirective, LoadingDirective],
  imports: [CommonModule, FormModule, PortalModule],
  exports: [TabGroupComponent, TabComponent, LazyTabDirective, LoadingDirective],
})
export class TabModule {}
