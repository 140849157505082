import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { coerceBoolean } from '@app/@wchfs-ui/decorators/coerce-property/coercions';
import { ButtonRippleDirective } from '@app/@pso-ui/button-pso/ripple/ripple';

@Component({
  template: '',
  styleUrls: ['./pso-base-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsoBaseButtonComponent implements OnInit {
  @HostBinding('class.pso-button-disabled')
  @coerceBoolean
  @Input()
  disabled = false;
  @Input()
  size: 'xs' | 's' | 'l' = 'l';
  @Input()
  iconPosition: 'left' | 'right' = 'right';
  @Input() loading = false;
  @HostBinding('class.with-icon')
  @Input()
  icon: string;

  @HostBinding('attr.disabled')
  get isDisabled(): boolean | null {
    return this.disabled || null;
  }

  @HostBinding('class.pso-button-size-regular')
  get isRegular(): boolean {
    return this.size === 'l';
  }

  @HostBinding('class.pso-button-size-small')
  get isSmall(): boolean {
    return this.size === 's';
  }

  @HostBinding('class.pso-button-size-extra-small')
  get isExtraSmall(): boolean {
    return this.size === 'xs';
  }

  @HostBinding('style.padding-left.px') get paddingLeft(): number {
    if (!this.icon) {
      if (this.size === 'xs') {
        return 10;
      }
      if (this.size === 's') {
        return 18;
      }
      return 20;
    }

    if (this.iconPosition === 'left') {
      return 3;
    }

    if (this.iconPosition === 'right') {
      return 12;
    }
  }

  @HostBinding('style.padding-right.px') get paddingRight(): number {
    if (!this.icon) {
      if (this.size === 'xs') {
        return 10;
      }
      if (this.size === 's') {
        return 18;
      }
      return 20;
    }

    if (this.iconPosition === 'left') {
      return 12;
    }

    if (this.iconPosition === 'right') {
      return 3;
    }
  }

  @ViewChild(ButtonRippleDirective) ripple: ButtonRippleDirective;

  wchfsRippleCentered: false;
  rippleColor = 'rgba(255, 255, 255, 0.3)';
  hostElement: HTMLElement;

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.getHostElement();
  }

  getHostElement(): void {
    this.hostElement = this.elementRef.nativeElement;
  }
}
