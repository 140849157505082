export class Survey {
  constructor(
    public readonly id: string,
    public readonly text: string,
    public readonly fieldId: string,
    public readonly width: number,
    public readonly height: number
  ) {}

  getUrl(): string {
    return 'survey/' + this.id;
  }
}

export class SurveyStatus {
  static SEND: SurveyStatusValue = 'SEND';
  static UNSENT: SurveyStatusValue = 'UNSENT';
}

export type SurveyStatusValue = keyof typeof SurveyStatus;
