import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { coerceBoolean } from '../../../decorators/coerce-property/coercions';

@Component({
  selector: 'wchfs-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  host: {
    '[class.wchfs-chip-level-1]': 'level == ChipLevels.LEVEL1 || !level',
    '[class.wchfs-chip-level-2]': 'level == ChipLevels.LEVEL2',
  },
})
export class ChipComponent implements OnInit {
  public ChipLevels = ChipLevels;
  @Input() level: ChipLevels;
  @Input() @coerceBoolean removable = false;
  @Input() key: string;
  @ViewChild('content') content: ElementRef;
  @Output() remove = new EventEmitter<{ key: string; level: number }>();

  constructor() {}

  ngOnInit(): void {}

  getLabel() {
    return this.content.nativeElement.textContent.trim();
  }

  onChipRemove() {
    this.remove.emit({ key: this.key, level: this.level });
  }
}

export enum ChipLevels {
  LEVEL1 = 1,
  LEVEL2 = 2,
}
