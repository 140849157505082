import { TemplateRef } from '@angular/core';

export type GenericTableColumnContentType = 'value' | 'template';

export class GenericTableColumn {
  static CONTENT_TYPE_VALUE: GenericTableColumnContentType = 'value';
  static CONTENT_TYPE_TEMPLATE: GenericTableColumnContentType = 'template';

  private header: string;
  private width: string;
  private template: TemplateRef<any>;
  private contentType: GenericTableColumnContentType = GenericTableColumn.CONTENT_TYPE_VALUE;

  constructor(private readonly name: string) {}

  getWidth(): string {
    return this.width;
  }

  setWidth(value: string): GenericTableColumn {
    this.width = value;
    return this;
  }

  getHeader(): string {
    return this.header || this.name;
  }

  setHeader(value: string): GenericTableColumn {
    this.header = value;
    return this;
  }

  setTemplate(template: TemplateRef<any>): GenericTableColumn {
    this.template = template;
    this.contentType = GenericTableColumn.CONTENT_TYPE_TEMPLATE;
    return this;
  }

  getTemplate(): TemplateRef<any> {
    return this.template;
  }

  getName(): string {
    return this.name;
  }

  getContentType(): GenericTableColumnContentType {
    return this.contentType;
  }
}
