import { ServiceResource } from '@data/services/services.interface';

export interface ServicesStateModel {
  services: ServiceResource[];
}

export class SetServices {
  static readonly type = '[Services] Set Services';

  constructor(public services: ServiceResource[]) {}
}
