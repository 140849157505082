import { PsoColorVar } from '@app/@wchfs-ui/lib/colors/colors.model';

export type SignalBoxTypeValue = 'info' | 'error' | 'warn' | 'success';

export class SignalBoxType {
  static INFO: SignalBoxTypeValue = 'info';
  static ERROR: SignalBoxTypeValue = 'error';
  static WARN: SignalBoxTypeValue = 'warn';
  static SUCCESS: SignalBoxTypeValue = 'success';

  static getColor(value: SignalBoxTypeValue): PsoColorVar {
    return new Map<SignalBoxTypeValue, PsoColorVar>([
      [SignalBoxType.INFO, 'var(--primary-b-blue)'],
      [SignalBoxType.ERROR, 'var(--signal-error-red)'],
      [SignalBoxType.WARN, 'var(--primary-y-yellow)'],
      [SignalBoxType.SUCCESS, 'var(--signal-success-green)'],
    ]).get(value);
  }

  static getBackgroundColor(value: SignalBoxTypeValue): PsoColorVar {
    return new Map<SignalBoxTypeValue, PsoColorVar>([
      [SignalBoxType.INFO, 'var(--secondary-b-lighten90)'],
      [SignalBoxType.ERROR, 'var(--signal-error-red-lighten90)'],
      [SignalBoxType.WARN, 'var(--secondary-y-lighten90)'],
      [SignalBoxType.SUCCESS, 'var(--signal-success-green-lighten90)'],
    ]).get(value);
  }

  static getIcon(value: SignalBoxTypeValue): string {
    return new Map<SignalBoxTypeValue, string>([
      [SignalBoxType.INFO, 'pso:info_xl'],
      [SignalBoxType.ERROR, 'pso:error_xl'],
      [SignalBoxType.WARN, 'pso:alert_xl'],
      [SignalBoxType.SUCCESS, 'pso:success_xl'],
    ]).get(value);
  }
}
