import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Create, Export, Id, Read, ReadById, Update } from '@core/http/crud-model';
import { Observable } from 'rxjs';
import { DataResource } from '@shared/interfaces/data-resource';
import {
  GetFreeDatesGetRequest,
  ReservationDayResource,
  ReservationEventTypeResource,
  ReservationEventTypesGetRequest,
  ReservationPatchRequest,
  ReservationPostRequest,
  ReservationPutRequest,
  ReservationResource,
  ReservationWorkspaceResource,
  ReservationWorkspacesGetRequest,
} from '@data/reservations/reservations.model';
import { FilterParams } from '@data/filtering/filtering.interface';
import { PagedResource } from '@shared/interfaces/paged-resource';
import { MediaResource } from '@data/media/media.model';
import { NoteService } from '@data/note/note.service';

const ENDPOINT = '/reservations';
const DEFAULT_WITH = 'vehicle,vehicle.fleet,workshop,notes';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService
  implements
    Read<FilterParams, ReservationResource>,
    ReadById<ReservationResource>,
    Create<ReservationPostRequest, ReservationResource>,
    Update<ReservationPutRequest, ReservationResource>,
    Export<FilterParams, MediaResource> {
  constructor(private httpClient: HttpClient) {}

  getById(id: Id): Observable<DataResource<ReservationResource>> {
    return this.httpClient.get<DataResource<ReservationResource>>(`${ENDPOINT}/${id}`, {
      params: {
        with: DEFAULT_WITH,
      },
    });
  }

  get(params: FilterParams): Observable<PagedResource<ReservationResource>> {
    return this.httpClient.get<PagedResource<ReservationResource>>(`${ENDPOINT}`, {
      params: {
        ...params,
        with: params?.with || DEFAULT_WITH,
      },
    });
  }

  create(params: ReservationPostRequest): Observable<DataResource<ReservationResource>> {
    return this.httpClient.post<DataResource<ReservationResource>>(ENDPOINT, params);
  }

  update(id: Id, params: ReservationPatchRequest): Observable<DataResource<ReservationResource>> {
    return this.httpClient.patch<DataResource<ReservationResource>>(`${ENDPOINT}/${id}`, params);
  }

  export(params: FilterParams): Observable<DataResource<MediaResource>> {
    return this.httpClient.get<DataResource<MediaResource>>(`${ENDPOINT}/export`, {
      params: {
        ...params,
      },
    });
  }

  updateNotificationDateAll(): Observable<DataResource<null>> {
    return this.httpClient.put<DataResource<null>>(`${ENDPOINT}/update-notification-date-all`, {});
  }

  getFreeDates(params: GetFreeDatesGetRequest): Observable<DataResource<ReservationDayResource[]>> {
    return this.httpClient.get<DataResource<ReservationDayResource[]>>(ENDPOINT + '/free-dates', {
      params,
    });
  }

  getWorkspaces(params: ReservationWorkspacesGetRequest): Observable<DataResource<ReservationWorkspaceResource[]>> {
    return this.httpClient.get<DataResource<ReservationWorkspaceResource[]>>(ENDPOINT + '/workspaces', {
      params,
    });
  }

  getEventTypes(params: ReservationEventTypesGetRequest): Observable<DataResource<ReservationEventTypeResource[]>> {
    return this.httpClient.get<DataResource<ReservationEventTypeResource[]>>(ENDPOINT + '/event-types', {
      params,
    });
  }

  noteFactory(id: Id): NoteService {
    return new NoteService(this.httpClient, ENDPOINT, id);
  }
}
