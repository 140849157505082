import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal-buttons-group',
  templateUrl: './modal-buttons-group.component.html',
  styleUrls: ['./modal-buttons-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalButtonsGroupComponent {
  @Input() offset = true;
}
