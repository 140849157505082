import { SelectData } from '../select.interfaces';

export class SelectItem<T> {
  constructor(public value: T, public label: string) {}

  getLabel(): string {
    if (this.value === null || this.value === undefined) {
      return '';
    }
    return this.label || this.value.toString();
  }

  is(value: unknown): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'object' && typeof this.value === 'object') {
      return JSON.stringify(value) === JSON.stringify(this.value); // TODO: implement compare function
    }

    return this.value == value;
  }

  getObject(): SelectData<T> {
    return {
      value: this.value,
      label: this.label,
    };
  }

  getValue(): T {
    return this.value;
  }

  match(keyword: string): boolean {
    if (!keyword) {
      return true;
    }
    // implement object case
    return this.getLabel().toLowerCase().includes(keyword.toLowerCase());
  }
}
