import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  HostBinding,
  inject,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Color } from '../shared/colors/colors.service';
import { SvgIconRegistry } from './svg-icon-registry';

class WchfsIconBase {
  constructor(public _elementRef: ElementRef) {}
}

export const CBS_ICON_LOCATION = new InjectionToken<WchfsIconLocation>('wchfs-icon-location', {
  providedIn: 'root',
  factory: CBS_ICON_LOCATION_FACTORY,
});

export interface WchfsIconLocation {
  getPathname: () => string;
}

export function CBS_ICON_LOCATION_FACTORY(): WchfsIconLocation {
  const document = inject(DOCUMENT);
  const location = document ? document.location : null;

  return {
    // Note that this needs to be a function, rather than a property, because Angular
    // will only resolve it once, but we want the current path on each call.
    getPathname: () => (location ? location.pathname + location.search : ''),
  };
}

/** SVG attributes that accept a FuncIRI (e.g. `url(<something>)`). */
const funcIriAttributes = [
  'clip-path',
  'color-profile',
  'src',
  'cursor',
  'fill',
  'filter',
  'marker',
  'marker-start',
  'marker-mid',
  'marker-end',
  'mask',
  'stroke',
];

/** Selector that can be used to find all elements that are using a `FuncIRI`. */
const funcIriAttributeSelector = funcIriAttributes.map((attr) => `[${attr}]`).join(', ');

/** Regex that can be used to extract the id out of a FuncIRI. */
const funcIriPattern = /^url\(['"]?#(.*?)['"]?\)$/;

const ICON_DEFAULT_FILL_CLASS = 'wchfs-icon-default-fill';
const ICON_DEFAULT_STROKE_CLASS = 'wchfs-icon-default-stroke';
const ICON_DEFAULT_SIZE_CLASS = 'wchfs-icon-default-size';

type SizeAlias = 'x-small' | 'small' | 'budicon' | 'regular' | 'big' | 'large' | 'x-large';

@Component({
  selector: 'wchfs-icon, [wchfsSvgIcon]',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None, // TODO check
  changeDetection: ChangeDetectionStrategy.OnPush, // TODO check
})
export class SvgIconComponent extends WchfsIconBase implements OnInit, AfterViewChecked, OnDestroy {
  _svgName: string | null;
  _svgNamespace: string | null;
  @Input() color: Color | undefined;
  @Input() fill: Color | undefined;
  @Input() stroke: Color | undefined;
  @Input() size: SizeAlias | number | undefined;
  @HostBinding('attr.role') role = 'img';
  private previousFontSetClass: string;
  private previousFontIconClass: string;
  private previousPath?: string;
  private elementsWithExternalReferences?: Map<Element, { name: string; value: string }[]>;
  private currentIconFetch = Subscription.EMPTY;
  private isInline = false;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private iconRegistry: SvgIconRegistry,
    @Attribute('aria-hidden') ariaHidden: string,
    @Inject(CBS_ICON_LOCATION) private location: WchfsIconLocation,
    private readonly errorHandler: ErrorHandler
  ) {
    super(elementRef);

    // If the user has not explicitly set aria-hidden, mark the icon as hidden, as this is
    // the right thing to do for the majority of icon use-cases.
    if (!ariaHidden) {
      elementRef.nativeElement.setAttribute('aria-hidden', 'true');
    }
  }

  @HostBinding('class') get classes() {
    const classes = [];
    let fill = '';

    if (this.fill) {
      fill = this.fill;
    }

    if (this.color) {
      if (fill) {
        console.warn(`[SvgIconComponent][${this._svgName}] Pass "color" or "fill" instead of both.`); // TODO how to warn user
      }
      fill = this.color;
    }

    classes.push(fill ? `wchfs-icon-fill-${fill}` : ICON_DEFAULT_FILL_CLASS);
    classes.push(this.stroke ? `wchfs-icon-fill-${this.stroke}` : ICON_DEFAULT_STROKE_CLASS);
    classes.push(this.size ? `wchfs-icon-size-${this.size}` : ICON_DEFAULT_SIZE_CLASS);
    classes.push('wchfs-icon');
    classes.push('notranslate');
    classes.push(this.inline ? 'wchfs-icon-inline' : '');

    return classes.join(' ');
  }

  /**
   * Whether the icon should be inlined, automatically sizing the icon to match the font size of
   * the element the icon is contained in.
   */
  @Input()
  get inline(): boolean {
    return this.isInline;
  }

  set inline(inline: boolean) {
    this.isInline = coerceBooleanProperty(inline);
  }

  /** Name of the icon in the SVG icon set. */
  private _svgIcon: string;

  get svgIcon(): string {
    return this._svgIcon;
  }

  @Input()
  set svgIcon(value: string) {
    if (value !== this._svgIcon) {
      if (value) {
        this.updateSvgIcon(value);
      } else if (this._svgIcon) {
        this.clearSvgElement();
      }
      this._svgIcon = value;
    }
  }
  private _fontSet: string;

  /** Font set that the icon is a part of. */
  @Input()
  get fontSet(): string {
    return this._fontSet;
  }

  set fontSet(value: string) {
    const newValue = this.cleanupFontValue(value);

    if (newValue !== this._fontSet) {
      this._fontSet = newValue;
      this.updateFontIconClasses();
    }
  }

  private _fontIcon: string;

  /** Name of an icon within a font set. */
  @Input()
  get fontIcon(): string {
    return this._fontIcon;
  }

  set fontIcon(value: string) {
    const newValue = this.cleanupFontValue(value);

    if (newValue !== this._fontIcon) {
      this._fontIcon = newValue;
      this.updateFontIconClasses();
    }
  }

  @HostBinding('attr.data-wchfs-icon-type') get dataWchfsIconType() {
    return this.usingFontIcon() ? 'font' : 'svg';
  }

  @HostBinding('attr.data-wchfs-icon-name') get dataIconName() {
    return this._svgName || this.fontIcon;
  }

  @HostBinding('attr.data-wchfs-icon-namespace') get dataWchfsIconNamespace() {
    return this._svgNamespace || this.fontSet;
  }

  ngOnInit() {
    // Update font classes because ngOnChanges won't be called if none of the inputs are present,
    // e.g. <mat-icon>arrow</mat-icon> In this case we need to add a CSS class for the default font.

    this.updateFontIconClasses();
  }

  ngAfterViewChecked() {
    const cachedElements = this.elementsWithExternalReferences;

    if (cachedElements && cachedElements.size) {
      const newPath = this.location.getPathname();

      // We need to check whether the URL has changed on each change detection since
      // the browser doesn't have an API that will let us react on link clicks and
      // we can't depend on the Angular router. The references need to be updated,
      // because while most browsers don't care whether the URL is correct after
      // the first render, Safari will break if the user navigates to a different
      // page and the SVG isn't re-rendered.
      if (newPath !== this.previousPath) {
        this.previousPath = newPath;
        this.prependPathToReferences(newPath);
      }
    }
  }

  ngOnDestroy() {
    this.currentIconFetch.unsubscribe();

    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }
  }

  usingFontIcon(): boolean {
    return !this.svgIcon;
  }

  /**
   * Splits an svgIcon binding value into its icon set and icon name components.
   * Returns a 2-element array of [(icon set), (icon name)].
   * The separator for the two fields is ':'. If there is no separator, an empty
   * string is returned for the icon set and the entire value is returned for
   * the icon name. If the argument is falsy, returns an array of two empty strings.
   * Throws an error if the name contains two or more ':' separators.
   * Examples:
   *   `'social:cake' -> ['social', 'cake']
   *   'penguin' -> ['', 'penguin']
   *   null -> ['', '']
   *   'a:b:c' -> (throws Error)`
   */
  private splitIconName(iconName: string): [string, string] {
    if (!iconName) {
      return ['', ''];
    }
    const parts = iconName.split(':');
    switch (parts.length) {
      case 1:
        return ['', parts[0]]; // Use default namespace.
      case 2:
        return parts as [string, string];
      default:
        throw Error(`Invalid icon name: "${iconName}"`);
    }
  }

  private setSvgElement(svg: SVGElement) {
    this.clearSvgElement();

    // Workaround for IE11 and Edge ignoring `style` tags inside dynamically-created SVGs.
    // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10898469/
    // Do this before inserting the element into the DOM, in order to avoid a style recalculation.
    const styleTags = svg.querySelectorAll('style') as NodeListOf<HTMLStyleElement>;

    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }

    // Note: we do this fix here, rather than the icon registry, because the
    // references have to point to the URL at the time that the icon was created.
    const path = this.location.getPathname();
    this.previousPath = path;
    this.cacheChildrenWithExternalReferences(svg);
    this.prependPathToReferences(path);
    this._elementRef.nativeElement.appendChild(svg);
  }

  private clearSvgElement() {
    const layoutElement: HTMLElement = this._elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }

  private updateFontIconClasses() {
    if (!this.usingFontIcon()) {
      return;
    }

    const elem: HTMLElement = this._elementRef.nativeElement;
    const fontSetClass = this.fontSet
      ? this.iconRegistry.classNameForFontAlias(this.fontSet)
      : this.iconRegistry.getDefaultFontSetClass();

    if (fontSetClass != this.previousFontSetClass) {
      if (this.previousFontSetClass) {
        elem.classList.remove(this.previousFontSetClass);
      }
      if (fontSetClass) {
        elem.classList.add(fontSetClass);
      }
      this.previousFontSetClass = fontSetClass;
    }

    if (this.fontIcon != this.previousFontIconClass) {
      if (this.previousFontIconClass) {
        elem.classList.remove(this.previousFontIconClass);
      }
      if (this.fontIcon) {
        elem.classList.add(this.fontIcon);
      }
      this.previousFontIconClass = this.fontIcon;
    }
  }

  /**
   * Cleans up a value to be used as a fontIcon or fontSet.
   * Since the value ends up being assigned as a CSS class, we
   * have to trim the value and omit space-separated values.
   */
  private cleanupFontValue(value: string) {
    return typeof value === 'string' ? value.trim().split(' ')[0] : value;
  }

  /**
   * Prepends the current path to all elements that have an attribute pointing to a `FuncIRI`
   * reference. This is required because WebKit browsers require references to be prefixed with
   * the current path, if the page has a `base` tag.
   */
  private prependPathToReferences(path: string) {
    const elements = this.elementsWithExternalReferences;

    if (elements) {
      elements.forEach((attrs, element) => {
        attrs.forEach((attr) => {
          element.setAttribute(attr.name, `url('${path}#${attr.value}')`);
        });
      });
    }
  }

  /**
   * Caches the children of an SVG element that have `url()`
   * references that we need to prefix with the current path.
   */
  private cacheChildrenWithExternalReferences(element: SVGElement) {
    const elementsWithFuncIri = element.querySelectorAll(funcIriAttributeSelector);
    const elements = (this.elementsWithExternalReferences = this.elementsWithExternalReferences || new Map());

    for (let i = 0; i < elementsWithFuncIri.length; i++) {
      funcIriAttributes.forEach((attr) => {
        const elementWithReference = elementsWithFuncIri[i];
        const value = elementWithReference.getAttribute(attr);
        const match = value ? value.match(funcIriPattern) : null;

        if (match) {
          let attributes = elements.get(elementWithReference);

          if (!attributes) {
            attributes = [];
            elements.set(elementWithReference, attributes);
          }

          attributes!.push({ name: attr, value: match[1] });
        }
      });
    }
  }

  private updateSvgIcon(rawName: string | undefined) {
    this._svgNamespace = null;
    this._svgName = null;
    this.currentIconFetch.unsubscribe();

    if (rawName) {
      const [namespace, iconName] = this.splitIconName(rawName);

      if (namespace) {
        this._svgNamespace = namespace;
      }

      if (iconName) {
        this._svgName = iconName;
      }

      this.currentIconFetch = this.iconRegistry
        .getNamedSvgIcon(iconName, namespace)
        .pipe(take(1))
        .subscribe(
          (svg) => this.setSvgElement(svg),
          (err: Error) => {
            const errorMessage = `Error retrieving icon ${namespace}:${iconName}! ${err.message}`;
            this.errorHandler.handleError(new Error(errorMessage));
          }
        );
    }
  }
}

@Component({
  selector: 'wchfs-icon-dp',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None, // TODO check
  changeDetection: ChangeDetectionStrategy.OnPush, // TODO check
})
export class SvgIconDatepickerComponent extends SvgIconComponent {}

@Component({
  selector: 'wchfs-icon-it',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  encapsulation: ViewEncapsulation.None, // TODO check
  changeDetection: ChangeDetectionStrategy.OnPush, // TODO check
})
export class SvgIconInfoTooltipComponent extends SvgIconComponent {}
