import { Injectable, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

import { takeUntil } from 'rxjs/operators';

@Injectable()
export class SelectDropdownService implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject();
  overlayRef: OverlayRef;
  overlayConfig: OverlayConfig;
  state$ = new BehaviorSubject<'open' | 'close'>('close');

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
    this.close();
  }

  open(input: HTMLElement, options: TemplateRef<unknown>): void {
    this.close();
    this.createModalPositionConfig(input);
    this.overlayRef = this.overlay.create(this.overlayConfig);

    this.overlayRef.attach(new TemplatePortal(options, this.viewContainerRef));
    this.state$.next('open');

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(() => {
        this.overlayRef.dispose();
        this.state$.next('close');
      });
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = undefined;
      this.state$.next('close');
    }
  }

  private createModalPositionConfig(element: HTMLElement): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(element)
      .withLockedPosition()
      .withGrowAfterOpen()
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: -1,
        },

        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetY: 1,
        },
      ]);

    this.overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'select-dropdown-container-backdrop',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        scrollThrottle: 0,
      }),
      width: element.getBoundingClientRect().width,
    });
  }
}
