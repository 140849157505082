import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';

export abstract class FormFieldControl {
  // tslint:disable-next-line:no-any
  value: any;
  readonly stateChanges: Observable<void>;
  readonly id: string;
  readonly placeholder: string;
  readonly ngControl: NgControl | null;
  readonly focused: boolean;
  readonly empty: boolean;
  readonly required: boolean;
  readonly disabled: boolean;
  readonly errorState: boolean;
  readonly controlType?: string;
  readonly autofilled?: boolean;
}
