export interface Channel {
  type: ChannelType;
  name: string;
}

export interface EventToListen {
  channel: Channel;
  event: string;
  callback?: (element: any) => void;
}

export enum ChannelType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  PRESENCE = 'presence',
}

export interface OnlineUser {
  id: number;
  status: UserAvailabilityStatus;
}

export enum UserAvailabilityStatus {
  // extra
  BUSY = 'busy',

  // base
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export interface HandlesWebsocketsEvent {
  websocketEvent: EventToListen;
  setWebsocketEvent(): void;
}
