import { BehaviorSubject } from 'rxjs';
import { OptionGroupHeaderComponent } from '../option-group/option-group-header.component';
import { OptionComponent } from '../option/option.component';
import { SelectComponent } from './select.component';

export class ChipListService {
  public options: OptionComponent[] = [];
  public groups: GroupData[] = [];
  options$ = new BehaviorSubject<OptionComponent[]>([]);
  groups$ = new BehaviorSubject<GroupData[]>([]);

  constructor(private selectRef: SelectComponent) {}

  public addOptions(...options: OptionComponent[]) {
    options.forEach((o) => {
      if (!this.optionExists(o)) {
        this.options.push(o);
      }
    });
    this.options$.next(this.options);
  }

  public removeOptions(...options: OptionComponent[]) {
    options.forEach((option) => {
      const chips = this.options.filter((o) => this.selectRef.compareWith(option.value, o.value));
      chips.forEach((c) => {
        this.options.splice(this.options.indexOf(c), 1);
      });
    });
  }

  public addGroup(group: GroupData) {
    if (!this.groupExists(group)) {
      this.groups.push(group);
      group.options.forEach((o) => this.removeOptions(o));
      this.groups$.next(this.groups);
      this.options$.next(this.options);
    }
  }

  public removeGroup(group: GroupData) {
    this.groups
      .filter((g) => g.header.getLabel().toString() === group.header.getLabel().toString())
      .forEach((v) => {
        this.groups.splice(this.groups.indexOf(v), 1);
      });
  }

  public optionExists(option: OptionComponent) {
    return !!this.options.find((o: OptionComponent) => this.selectRef.compareWith(o.value, option.value));
  }

  public groupExists(group: GroupData) {
    return !!this.groups.find((g: GroupData) => g.header.getLabel().toString() === group.header.getLabel().toString());
  }

  public resetGroups() {
    /*
     INFO: Group after refreshing results may have fewer items.
     Missing items should be shown outside the group chips as separate chip after refreshing the results.
    */
    const options = this.groups.map((g) => g.options);
    if (options && options.length > 0) {
      options.forEach((optionsArray) => {
        this.addOptions(...optionsArray);
      });
    }
    this.groups = [];
    this.groups$.next(this.groups);
  }
}

export interface GroupData {
  header: OptionGroupHeaderComponent;
  options: OptionComponent[];
}

export enum ChipType {
  ITEM,
  HEADER,
}
