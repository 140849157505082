import { InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export const defaultErrors = {
  required: () => `Field is required`,
  email: () => `Wrong email format`,
  min: ({ min }: ValidationErrors) => `The field must have at least value ${min}`,
  max: ({ max }: ValidationErrors) => `The field must have a maximum value ${max}`,
  minlength: ({ requiredLength }: ValidationErrors) => `The field must have at least ${requiredLength} chars`,
  maxlength: ({ requiredLength }: ValidationErrors) => `The field must have a maximum of ${requiredLength} chars`,
  pattern: () => `Wrong data format`,
  url: ({ message }: ValidationErrors) => `${message}`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
