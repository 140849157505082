import { FormControl, FormGroup } from '@angular/forms';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { AddressResource } from '../address/address.model';
import { Id } from '@app/@core/http/crud-model';
import { WorkshopResource } from '@data/workshop/workshop.model';

import { FleetResource } from '@data/fleets/fleets.model';
import { DateTimeString } from '@app/@shared/interfaces/date-string';
import { RoleResource } from '@data/roles-group-resource/roles-group-resource.model';

export type UsersFilterParams = {
  paginate: LaravelBoolean;
  name: string;
} & { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> };

export type UserTypeValue = 'pso' | 'fleet' | 'workshop';

export class UserType {
  static PSO: UserTypeValue = 'pso';
  static FLEET: UserTypeValue = 'fleet';
  static WORKSHOP: UserTypeValue = 'workshop';
}
export interface UserResource {
  address: AddressResource;
  avatar: Avatar;
  blocked_at: DateTimeString;
  created_at: DateTimeString;
  email: string;
  fax_number: string;
  first_name: string;
  fleets: FleetResource[];
  id?: number;
  last_name: string;
  login: string;
  mobile_phone_number: string;
  name: string;
  phone_number: string;
  roles?: RoleResource[];
  updated_at: DateTimeString;
  workshops: WorkshopResource[];
}

export interface newUserResource {
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  email: string;
  street: string;
  building_number: string;
  zip_code: string;
  city: string;
  fax_number: string;
  phone_number: string;
  mobile_phone_number: string;
}

export interface UserRoleResource {
  id: number;
  name: string;
  description: string;
  display_name: string;
  roles_group: {
    id: number;
    name: string;
    description: string;
    roles: [null];
    created_at: DateTimeString;
    updated_at: DateTimeString;
  };
}

export type UsersFilterParamsFormGroup = FormGroup<{
  name: FormControl<string>;
  email: FormControl<string>;
  roles_group: FormControl<string>;
  roles: FormControl<string>;
  status: FormControl<string>;
}>;

export type UserStatusSlug = 'active' | 'blocked';

export class UserStatus {
  public static ACTIVE: UserStatusSlug = 'active';
  public static BLOCKED: UserStatusSlug = 'blocked';
}

export interface UserPostRequest {
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  street: string;
  state: string;
  zip: string;
  fax_number: string;
  phone_number: string;
  mobile_phone_number: string;
  roles: RoleResource[];
}

export interface PasswordTargetChangePatchRequest {
  current_user_password: string;
  target_user_new_password: string;
  target_user_new_password_confirmation: string;
}

export interface UserIds {
  users_ids: Id[];
}

export interface RolesIds {
  roles_ids: Id[];
}

export interface Avatar {
  original: string;
  medium: string;
  thumbnail: string;
}
