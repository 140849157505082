import { VehicleResource } from '@data/vehicles/vehicles.model';
import { StockResource } from '@data/stock/stock.model';
import { OrderStatusValue } from '@data/orders/orders.model';
import { FleetResource } from '@data/fleets/fleets.model';
import { UserResource } from '@data/users/users.model';
import { WorkshopResource } from '@data/workshop/workshop.model';

export interface OrderWithReservationSuborderResource {
  id: number;
  number: string;
  user: UserResource;
  workshop: WorkshopResource;
  ordered_item: SuborderItemResource;
  substitutes: SuborderItemResource[];
  items: SuborderItemResource[];
  is_with_reservation: boolean;
  vehicle: VehicleResource;
  vehicle_registration_number: string;
  vehicle_vin: string;
  fleet: FleetResource;
  fleet_name: string;
  status: OrderStatusValue;
  status_changed_at: string;
  response_time_until: string;
  created_at: string;
  updated_at: string;
}

export type SuborderItemTypeSlug =
  | 'ordered'
  | 'substitute'
  | 'confirmed-substitute'
  | 'disposition-order-variant'
  | 'confirmed-variant';
export class SuborderItemType {
  static ORDERED: SuborderItemTypeSlug = 'ordered';
  static SUBSTITUTE: SuborderItemTypeSlug = 'substitute';
  static CONFIRMED_SUBSTITUTE = 'confirmed-substitute';
  static DISPOSITION_ORDER_VARIANT = 'disposition-order-variant';
  static CONFIRMED_VARIANT = 'confirmed-variant';
}

export interface SuborderItemResource {
  id: number;
  type: SuborderItemTypeSlug;
  quantity: number;
  stock: StockResource;
  price: string;
  fleet_discounted_price: string;
  fleet_discount: string;
  total_fleet_discounted_price: string;
  total_wholesaler_price: string;
  created_at: string;
  updated_at: string;
  workshop_discount: string;
  workshop_discounted_price: string;
  total_workshop_discounted_price: string;
  pricing_source: string;
}
