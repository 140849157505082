import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ucfirst } from '@shared/utils/ucfirst';
import { PsoUiModule } from '@pso-ui/pso-ui.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-grey-box-with-big-text',
  standalone: true,
  imports: [CommonModule, PsoUiModule, TranslateModule],
  templateUrl: './grey-box-with-big-text.component.html',
  styleUrls: ['./grey-box-with-big-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreyBoxWithBigTextComponent {
  @Input() textLine1Small: string;
  @Input() textLine1Big: string;
  @Input() textLine2Small: string;
  @Input() textLine2Big: string;
  @Input() buttonLabel: string;
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
  protected readonly ucfirst = ucfirst;
}
