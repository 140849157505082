import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  clearLocalStorage(): void {
    const keysToKeep = ['auth'];

    for (const key in localStorage) {
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        if (!keysToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      }
    }
  }
}
