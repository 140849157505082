import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CustomErrors } from '../form-error-messages/directives/control-errors.interfaces';

@Component({
  selector: 'wchfs-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true,
    },
  ],
})
export class SearchInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input()
  public placeholder: string;
  @ViewChild('input')
  public input: ElementRef;
  @Input()
  public selected: any;
  @Input()
  public disabled = false;
  @Input() debounce = 0;
  @Input() customErrors: CustomErrors;
  @Output() enterKeyPressed: EventEmitter<void> = new EventEmitter<void>();
  private onDestroy$ = new Subject<void>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public onChangeFn = (_: any) => {};

  public onTouchedFn = () => {};

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.selected = value;
    this.onChangeFn(this.selected);
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'input')
      .pipe(debounceTime(this.debounce), takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.writeValue(this.input.nativeElement.value);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearSelected() {
    this.writeValue(null);
  }

  clearInput(): void {
    this.input.nativeElement.innerHTML = '';
  }

  focus(): void {
    this.input.nativeElement.focus({
      preventScroll: true,
    });
  }
}
