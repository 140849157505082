import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { PsoColor } from '@app/@wchfs-ui/lib/colors/colors.model';
import { toCssVariable } from '@shared/utils/to-css-variable';
import { LinkComponentSize } from '@pso-ui/link/link.component.model';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() text: string;
  @Input() color: PsoColor = 'primary-b-blue';
  @Input() size: LinkComponentSize = 'm';
  @Output() linkClicked = new EventEmitter();

  @HostBinding('style.color') get styleColor(): string {
    return toCssVariable(this.color);
  }

  @HostBinding('style.fontSize.px') get styleFontSize(): number {
    return new Map<LinkComponentSize, number>([
      ['s', 9],
      ['m', 12],
    ]).get(this.size);
  }
}
