import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { startOfDay } from '../date-utils';
import { RangeTime } from '../calendar.interface';

@Component({
  selector: 'wchfs-range-buttons',
  templateUrl: './range-buttons.component.html',
  styleUrls: ['./range-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeButtonsComponent implements OnInit {
  @Output() selectLastDaysChange = new EventEmitter<RangeTime>();

  private readonly today = startOfDay(new Date());

  constructor() {}

  ngOnInit(): void {}

  selectLastDays(numberOfLastDays: number) {
    const today = startOfDay(new Date());
    const startDate = new Date(today.setDate(today.getDate() - numberOfLastDays));

    this.selectLastDaysChange.emit({
      start: startDate,
      end: this.today,
    });
  }

  selectLastMonth(numberOfLastMonths: number) {
    const today = startOfDay(new Date());
    const startDate = today.setMonth(today.getMonth() - numberOfLastMonths);

    this.selectLastDaysChange.emit({
      start: new Date(startDate),
      end: this.today,
    });
  }
}
