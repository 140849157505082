import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { getYearsFromDate, setYear, startOfDay } from '../date-utils';
import { CalendarTypes, NUMBER_OF_YEARS_ON_PAGE } from '../calendar.constants';

@Component({
  selector: 'wchfs-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
})
export class YearPickerComponent {
  years!: readonly number[];
  currentDate = startOfDay(new Date());
  @Input() selectedDate: Date;
  @Output() selectedYearChange = new EventEmitter<Date>();
  @Output() changeCalendarType = new EventEmitter<CalendarTypes>();
  private currentLocale?: string;
  private currentDateInStepper: Date;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  @Input()
  get dateInStepper() {
    return this.currentDateInStepper;
  }

  set dateInStepper(date: Date) {
    if (!!date) {
      this.years = this.getYears(date);
    }
    this.currentDateInStepper = date;
  }

  @Input()
  get locale() {
    return this.currentLocale;
  }

  set locale(locale: string | undefined) {
    this.currentLocale = locale || this.localeId;
    this.years = this.getYears(this.selectedDate);
  }

  selectYear(year: number, event: Event) {
    event.stopPropagation();
    const newDate = setYear(this.selectedDate || this.currentDate, year);

    this.selectedYearChange.emit(newDate);
    this.changeCalendarType.emit(CalendarTypes.days);
  }

  isSelectedYear(year: number): boolean {
    return !!this.selectedDate && this.selectedDate.getFullYear() === year;
  }

  isCurrentYear(year: number): boolean {
    return !!this.currentDate && this.currentDate.getFullYear() === year;
  }

  private getYears(initialYear: Date) {
    if (!initialYear) {
      initialYear = new Date();
      initialYear.setFullYear(initialYear.getFullYear());
    }

    return getYearsFromDate(initialYear.getFullYear(), NUMBER_OF_YEARS_ON_PAGE);
  }
}
