import { HttpRequest } from '@angular/common/http';

export const addOauthHeader = (
  request: HttpRequest<any>,
  token_type: string,
  access_token: string
): HttpRequest<any> => {
  return request.clone({
    setHeaders: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
};
