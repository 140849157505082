export enum TooltipPosition {
  TOP = 'TOP',
  LEFT = 'LEFT',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
}

export enum TooltipStyle {
  WHITE = 'WHITE',
  BLUE = 'BLUE',
}
