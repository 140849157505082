import { FilteringModelValue } from './filtering.interface';

export class SetPerPage {
  static readonly type = '[Filtering] Set Per Page';

  constructor(public route: string, public perPage: number) {}
}

export class SetPage {
  static readonly type = '[Filtering] Set Page';

  constructor(public route: string, public page: number) {}
}

export class SetSort {
  static readonly type = '[Filtering] Set Sort';

  constructor(public route: string, public sort: string) {}
}

export class SetControlValues {
  static readonly type = '[Filtering] Set Control Values';

  constructor(public route: string, public values: Record<string, FilteringModelValue>) {}
}

export class ResetValues {
  static readonly type = '[Filtering] Reset Values';

  constructor(public route: string) {}
}

export class SetTab {
  static readonly type = '[Filtering] Set Tab';

  constructor(public route: string, public tab: string) {}
}

export class ResetFilteringState {
  static readonly type = '[Filtering] reset';
}
