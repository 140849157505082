import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../form/form.module';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { DraggableChipSuffixComponent } from './draggable-chip/draggable-chip-suffix.component';
import { DraggableChipComponent } from './draggable-chip/draggable-chip.component';
import { DraggableTableComponent } from './draggable-table/draggable-table.component';
import { ChipLabelPipe } from './draggable-table/pipes/chip-label.pipe';
import { ColumnSearchPipe } from './draggable-table/pipes/column-search.pipe';

@NgModule({
  declarations: [
    DraggableTableComponent,
    DraggableChipComponent,
    DraggableChipSuffixComponent,
    ChipLabelPipe,
    ColumnSearchPipe,
  ],
  imports: [CommonModule, DragDropModule, SvgIconModule, FormModule, ReactiveFormsModule],
  exports: [
    DraggableTableComponent,
    DraggableChipComponent,
    DraggableChipSuffixComponent,
    ChipLabelPipe,
    ColumnSearchPipe,
  ],
})
export class DraggableModule {}
