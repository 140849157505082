import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ProductTyresStateModel,
  ResetProductTyreState,
  SetActiveProductTyre,
  SetTabSlug,
  SetVisibleColumns,
  UnsetActiveProductTyre,
} from '@data/product-tyres/product-tyres.actions';
import { ProductTyresService } from '@data/product-tyres/product-tyres.service';
import { ProductTyreResource } from '@data/product-tyres/product-tyres.model';

const DEFAULTS: ProductTyresStateModel = {
  activeProductTyre: null,
  visibleColumns: [],
  tab_slug: '',
};

@State<ProductTyresStateModel>({
  name: 'product_tyres',
  defaults: DEFAULTS,
})
@Injectable()
export class ProductTyresState {
  @Selector()
  static activeProductTyre(state: ProductTyresStateModel): ProductTyreResource {
    return state.activeProductTyre;
  }

  @Selector()
  static visibleColumns(state: ProductTyresStateModel): string[] {
    return state.visibleColumns;
  }

  @Action(SetTabSlug)
  setTabSlug(ctx: StateContext<ProductTyresStateModel>, action: SetTabSlug): void {
    ctx.patchState({
      tab_slug: action.slug,
    });
  }

  @Action(SetActiveProductTyre)
  setActiveProductTyre(ctx: StateContext<ProductTyresStateModel>, action: SetActiveProductTyre): void {
    this.productTyresService.getById(action.id).subscribe((res) => {
      ctx.patchState({
        activeProductTyre: res.data,
      });
    });
  }

  @Action(UnsetActiveProductTyre)
  unsetActiveProductTyre(ctx: StateContext<ProductTyresStateModel>): void {
    ctx.patchState({
      activeProductTyre: null,
    });
  }

  @Action(SetVisibleColumns)
  setVisibleColumns(ctx: StateContext<ProductTyresStateModel>, action: SetVisibleColumns): void {
    ctx.patchState({
      visibleColumns: action.columns,
    });
  }

  @Action(ResetProductTyreState)
  resetDepositState(ctx: StateContext<ProductTyresStateModel>, action: ResetProductTyreState): void {
    ctx.setState(DEFAULTS);
  }

  constructor(private productTyresService: ProductTyresService) {}
}
