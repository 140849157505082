import { Platform } from '@angular/cdk/platform';
import {
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { coerceBoolean } from '../../../@wchfs-ui/decorators/coerce-property/coercions';
import { RippleAnimationConfig, RippleConfig, RippleRef } from './ripple-ref';
import { RippleRenderer, RippleTarget } from './ripple-renderer';

export interface RippleGlobalOptions {
  disabled?: boolean;
  animation?: RippleAnimationConfig;
  terminateOnPointerUp?: boolean;
}

export const RIPPLE_GLOBAL_OPTIONS = new InjectionToken<RippleGlobalOptions>('ripple-global-options');

@Directive({
  selector: '[button-ripple], [buttonRipple]',
  exportAs: 'buttonRipple',
})
export class ButtonRippleDirective implements OnInit, OnDestroy, RippleTarget {
  @Input('buttonRippleColor') color: string;
  @HostBinding('class.wchfs-ripple-unbounded')
  @coerceBoolean
  @Input('buttonRippleUnbounded')
  unbounded: boolean;
  @Input('buttonRippleCentered') @coerceBoolean centered: boolean;
  @Input('buttonRippleRadius') radius = 0;
  @Input('buttonRippleAnimation') animation: RippleAnimationConfig;

  private rippleRenderer: RippleRenderer;
  private globalOptions: RippleGlobalOptions;
  private isInitialized = false;
  private isDisabled = false;
  private triggerElement: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    ngZone: NgZone,
    platform: Platform,
    @Optional()
    @Inject(RIPPLE_GLOBAL_OPTIONS)
    globalOptions?: RippleGlobalOptions,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) private animationMode?: string
  ) {
    this.globalOptions = globalOptions || {};
    this.rippleRenderer = new RippleRenderer(this, ngZone, elementRef, platform);
  }

  @Input('buttonRippleDisabled')
  get disabled(): boolean {
    return this.isDisabled;
  }

  set disabled(value: boolean) {
    this.isDisabled = value;
    this.setupTriggerEventsIfEnabled();
  }

  @Input('buttonRippleTrigger')
  get trigger(): HTMLElement {
    return this.triggerElement || this.elementRef.nativeElement;
  }

  set trigger(trigger: HTMLElement) {
    this.triggerElement = trigger;
    this.setupTriggerEventsIfEnabled();
  }

  get rippleConfig(): RippleConfig {
    return {
      centered: this.centered,
      radius: this.radius,
      color: this.color,
      animation: {
        ...this.globalOptions.animation,
        ...(this.animationMode === 'NoopAnimations' ? { enterDuration: 0, exitDuration: 0 } : {}),
        ...this.animation,
      },
      terminateOnPointerUp: this.globalOptions.terminateOnPointerUp,
    };
  }

  get rippleDisabled(): boolean {
    return this.disabled || !!this.globalOptions.disabled;
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.setupTriggerEventsIfEnabled();
  }

  ngOnDestroy(): void {
    this.rippleRenderer.removeTriggerEvents();
  }

  fadeOutAll(): void {
    this.rippleRenderer.fadeOutAll();
  }

  /**
   * Launches a manual ripple using the specified ripple configuration.
   * @param config Configuration for the manual ripple.
   */
  launch(config: RippleConfig): RippleRef;

  /**
   * Launches a manual ripple at the specified coordinates within the element.
   * @param x Coordinate within the element, along the X axis at which to fade-in the ripple.
   * @param y Coordinate within the element, along the Y axis at which to fade-in the ripple.
   * @param config Optional ripple configuration for the manual ripple.
   */
  launch(x: number, y: number, config?: RippleConfig): RippleRef;

  /** Launches a manual ripple at the specified coordinated or just by the ripple config. */
  launch(configOrX: number | RippleConfig, y = 0, config?: RippleConfig): RippleRef {
    if (typeof configOrX === 'number') {
      return this.rippleRenderer.fadeInRipple(configOrX, y, {
        ...this.rippleConfig,
        ...config,
      });
    } else {
      return this.rippleRenderer.fadeInRipple(0, 0, {
        ...this.rippleConfig,
        ...configOrX,
      });
    }
  }

  private setupTriggerEventsIfEnabled(): void {
    if (!this.disabled && this.isInitialized) {
      this.rippleRenderer.setupTriggerEvents(this.trigger);
    }
  }
}
